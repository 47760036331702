import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    Grid,
    TextField,
    Tooltip,
    Button,
    CardActions,
    Collapse,
    IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import differenceInYears from 'date-fns/differenceInYears';
import useBreakpoints from '../../../styles/useBreakpoints';
import { KeyboardDatePicker } from "@material-ui/pickers";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';

import municipios from '../../../data/municipios'

function DatosPersonales(props) {
    const point = useBreakpoints();
    const { theme, setMessage, setSnack, setSeverity, setDatosPersonales } = props;
    const [errores, setErrores] = useState(Array(17).fill(false));
    const [completado, setCompletado] = props.datosCompletos;
    const [primerApellido, setPrimerApellido] = useState('');
    const [segundoApellido, setSegundoApellido] = useState('');
    const [primerNombre, setPrimerNombre] = useState('');
    const [segundoNombre, setSegundoNombre] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState(null);
    const [documento, setDocumento] = props.documento;
    const [documento2, setDocumento2] = useState('');
    const [fechaExpedicion, setFechaExpedicion] = useState(null);
    const [lugarExpedicion, setLugarExpedicion] = useState(null);
    const [fechaNacimiento, setFechaNacimiento] = useState(null);
    const [celular, setCelular] = useState('');
    const [celular2, setCelular2] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [correoConfirmacion, setCorreoConfirmacion] = useState('');
    const [ciudad, setCiudad] = useState(null);
    const [direccion, setDireccion] = useState('');
    const [complementoDireccion, setComplementoDireccion] = useState('');
    const [barrio, setBarrio] = useState('');
    const [localidad, setLocalidad] = useState('');

    const validar = () => {
        let errorDatos = false;
        // completado[0] = true
        // setCompletado([...completado])
        if (primerApellido === '') {
            errorDatos = true;
            errores[0] = true;
        }
        if (primerNombre === '') {
            errorDatos = true;
            errores[1] = true;
        }
        if (tipoDocumento === null) {
            errorDatos = true;
            errores[2] = true;
        }
        if (documento === '' || parseInt(documento) === 0 || documento !== documento2) {
            errorDatos = true;
            errores[3] = true;
        }
        if (documento2 === '' || parseInt(documento2) === 0 || documento2 !== documento) {
            errorDatos = true;
            errores[4] = true;
        }
        if (fechaExpedicion === null || differenceInYears(fechaExpedicion, fechaNacimiento) < 18) {
            errorDatos = true;
            errores[5] = true;
        }
        if (lugarExpedicion === null) {
            errorDatos = true;
            errores[6] = true;
        }
        if (fechaNacimiento === null || differenceInYears(new Date(), fechaNacimiento) < 18) {
            errorDatos = true;
            errores[7] = true;
        }
        if (ciudad === null) {
            errorDatos = true;
            errores[8] = true;
        }
        if (celular === '' || parseInt(celular) === 0 || celular.length !== 10) {
            errorDatos = true;
            errores[9] = true;
        }
        if (celular2 === '' || parseInt(celular2) === 0 || celular2.length !== 10) {
            errorDatos = true;
            errores[10] = true;
        }
        if (/^\S+@\S+\.\S+$/.test(correo) === false || correo === '' || correo !== correoConfirmacion) {
            errorDatos = true;
            errores[11] = true;
        }
        if (/^\S+@\S+\.\S+$/.test(correoConfirmacion) === false || correoConfirmacion === '' || correo !== correoConfirmacion) {
            errorDatos = true;
            errores[12] = true;
        }

        if (direccion === '') {
            errorDatos = true;
            errores[13] = true;
        }
        if (barrio === '') {
            errorDatos = true;
            errores[14] = true;
        }

        if (complementoDireccion === '') {
            errorDatos = true;
            errores[15] = true;
        }

        if (localidad === '') {
            errorDatos = true;
            errores[16] = true;
        }

        if (errorDatos) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            let municipio_exp = lugarExpedicion.split(' - ')[0];
            let departamento_exp = lugarExpedicion.split(' - ')[1];
            let municipio_res = lugarExpedicion.split(' - ')[0];
            let departamento_res = lugarExpedicion.split(' - ')[1];
            setDatosPersonales(
                {
                    'p_apellido': primerApellido,
                    's_apellido': segundoApellido,
                    'p_nombre': primerNombre,
                    's_nombre': segundoNombre,
                    'tipo_documento': tipoDocumento === 'Cédula de Ciudadanía' ? 0 : 1,
                    'documento': parseInt(documento),
                    'fecha_expedicion': fechaExpedicion.toISOString().split('T')[0],
                    'lugar_expedicion': municipios.filter(m => m.municipio === municipio_exp && m.departamento === departamento_exp)[0].daneMuni,
                    'fecha_nacimiento': fechaNacimiento.toISOString().split('T')[0],
                    'celular': celular,
                    'celular_alternativo': celular2,
                    'telefono': telefono === '' ? 0 : telefono,
                    'correo': correo,
                    'ciudad_residencia': municipios.filter(m => m.municipio === municipio_res && m.departamento === departamento_res)[0].daneMuni,
                    'direccion': {
                        'descripcion': direccion,
                    },
                    'complemento_direccion': complementoDireccion,
                    'barrio': barrio,
                    'localidad': localidad,
                    'rol': 5
                }
            )
            completado[0] = true
            setCompletado([...completado])
            setMessage('Los datos personales han sido diligenciados correctamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    const limpiar = () => {
        setPrimerApellido('');
        setSegundoApellido('');
        setPrimerNombre('');
        setSegundoNombre('');
        setTipoDocumento(null);
        setDocumento('');
        setDocumento2('');
        setFechaExpedicion(null);
        setLugarExpedicion(null);
        setFechaNacimiento(null);
        setCelular('');
        setCelular2('');
        setTelefono('');
        setCorreo('');
        setCorreoConfirmacion('');
        setCiudad(null);
        setDireccion('');
        setComplementoDireccion('');
        setBarrio('');
        setLocalidad('');
        setErrores(Array(15).fill(false));
    }

    return (
        <Card variant='outlined' style={{ marginBottom: theme.spacing(1) }}>
            <CardHeader
                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                title={
                    <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500 }}>1. Datos Personales:</Typography>
                }
                action={
                    <IconButton
                        disabled={completado[0] === false}
                        onClick={() => { completado[0] = false; setCompletado([...completado]) }}
                        color='secondary'
                        aria-label='add'
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            />
            <Collapse in={completado[0] === false} mountOnEnter unmountOnExit>
                <CardContent>
                    <Typography variant='body2' style={{ marginBottom: theme.spacing(1), marginLeft: theme.spacing(2) }}><span style={{ color: '#e53935' }}>*</span> Estos campos son obligatorios</Typography>
                    <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1'>Primer apellido: <span style={{ color: '#e53935' }}>*</span></Typography>
                            <TextField
                                value={primerApellido}
                                onChange={(e) => { let value = e.target.value; if ((value.length > 0)) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) { setPrimerApellido(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')); errores[0] = false; setErrores([...errores]) } }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                autoFocus
                                error={errores[0]}
                                helperText={errores[0] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[1]].includes(true) && !errores[0] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1'> Segundo apellido: </Typography>

                            <TextField
                                value={segundoApellido}
                                onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) setSegundoApellido(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                style={{ marginBottom: [errores[0], errores[1]].includes(true) && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1'>Primer nombre: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                value={primerNombre}
                                onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) setPrimerNombre(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')); errores[1] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[1]}
                                helperText={errores[1] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[0]].includes(true) && !errores[1] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1'> Segundo nombre: </Typography>

                            <TextField
                                value={segundoNombre}
                                onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) setSegundoNombre(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                style={{ marginBottom: [errores[0], errores[1]].includes(true) && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1'> Tipo de documento: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <Autocomplete
                                value={tipoDocumento}
                                onChange={(e, v) => { setTipoDocumento(v); errores[2] = false; setErrores([...errores]) }}
                                options={
                                    ['Cédula de Ciudadanía', 'Cédula de Extranjería'].map(option => option)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[2]}
                                        helperText={errores[2] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[3], errores[4], errores[5]].includes(true) && !errores[2] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1'>Número de documento: <span style={{ color: '#e53935' }}>*</span> </Typography>
                            <TextField
                                onCut={(e) => e.preventDefault()}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                value={documento}
                                onChange={(e) => { if (e.target.value.length <= 10) setDocumento(e.target.value.replace(/[^0-9]/g, '')); errores[3] = false; errores[4] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[3]}
                                helperText={errores[3] ? (documento === '' && documento2 === '' ? 'Su documento no puede quedar vacío' : 'Los documentos no coinciden') : null}
                                style={{ marginBottom: [errores[2], errores[4], errores[5]].includes(true) && !errores[3] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1'>Confirme su número de documento: <span style={{ color: '#e53935' }}>*</span></Typography>
                            <TextField
                                onCut={(e) => e.preventDefault()}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                value={documento2}
                                onChange={(e) => { if (e.target.value.length <= 10) setDocumento2(e.target.value.replace(/[^0-9]/g, '')); errores[4] = false; errores[3] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[4]}
                                helperText={errores[4] ? (documento2 === '' && documento === '' ? 'Este campo no puede quedar vacío' : 'Los documentos no coinciden') : null}
                                style={{ marginBottom: [errores[2], errores[3], errores[5]].includes(true) && !errores[4] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1'>Fecha de expedición: <span style={{ color: '#e53935' }}>*</span></Typography>
                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaExpedicion}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaExpedicion(date); errores[5] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[5]}
                                helperText={errores[5] ? (differenceInYears(fechaExpedicion, fechaNacimiento) < 18 ? 'Fecha de expedición incorrecta' : 'Este campo no puede quedar vacío') : null}
                                style={{ marginBottom: [errores[2], errores[3], errores[4]].includes(true) && !errores[5] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1'>Lugar de expedición: <span style={{ color: '#e53935' }}>*</span></Typography>
                            <Tooltip title='Municipio - Departamento'>
                                <Autocomplete
                                    value={lugarExpedicion}
                                    onChange={(e, v) => { setLugarExpedicion(v); errores[6] = false; setErrores([...errores]) }}
                                    options={
                                        municipios.map(option => option.municipio + ' - ' + option.departamento)
                                    }
                                    noOptionsText='No hay coincidencias'
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            error={errores[6]}
                                            helperText={errores[6] ? 'Este campo no puede quedar vacío' : null}
                                            style={{ marginBottom: [errores[7], errores[8]].includes(true) && !errores[6] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />
                                    )}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1'>Fecha de nacimiento: <span style={{ color: '#e53935' }}>*</span></Typography>
                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaNacimiento}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaNacimiento(date); errores[7] = false; errores[5] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[7]}
                                helperText={errores[7] ? ((differenceInYears(new Date(), fechaNacimiento) < 18) ? 'Debe ser mayor de edad para aplicar' : 'Este campo no puede quedar vacío') : null}
                                style={{ marginBottom: [errores[6], errores[8]].includes(true) && !errores[7] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>Ciudad de residencia: <span style={{ color: '#e53935' }}>*</span></Typography>
                            <Tooltip title='Ciudad - Departamento'>
                                <Autocomplete
                                    value={ciudad}
                                    onChange={(e, v) => { setCiudad(v); errores[8] = false; setErrores([...errores]) }}
                                    options={
                                        municipios.map(option => option.municipio + ' - ' + option.departamento)
                                    }
                                    noOptionsText='No hay coincidencias'
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            error={errores[8]}
                                            helperText={errores[8] ? 'Este campo no puede quedar vacío' : null}
                                            style={{ marginBottom: [errores[6], errores[7]].includes(true) && !errores[8] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                                        />
                                    )}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1'>Celular: <span style={{ color: '#e53935' }}>*</span> </Typography>

                            <TextField
                                value={celular}
                                onChange={(e) => { if (e.target.value.length <= 10) setCelular(e.target.value.replace(/[^0-9]/g, '')); errores[9] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[9]}
                                helperText={errores[9] ? (celular.length !== 0 && celular.length !== 10) ? 'El celular tiene menos de 10 dígitos' : 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: errores[10] === true && !errores[9] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1'>Celular alternativo: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                value={celular2}
                                onChange={(e) => { if (e.target.value.length <= 10) setCelular2(e.target.value.replace(/[^0-9]/g, '')); errores[10] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[10]}
                                helperText={errores[10] ? (celular2.length !== 0 && celular2.length !== 10) ? 'El celular tiene menos de 10 dígitos' : 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: errores[9] === true && !errores[10] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1'>Teléfono:</Typography>

                            <TextField
                                value={telefono}
                                onChange={(e) => { if (e.target.value.length <= 10) setTelefono(e.target.value.replace(/[^0-9]/g, '')); }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                style={{ marginBottom: [errores[9], errores[10]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>Correo electrónico: <span style={{ color: '#e53935' }}>* </span>
                                <Tooltip title='El correo ingresado será usado para enviar la información de inicio de sesión. Por favor, ingrese un correo válido y asegúrese que tenga acceso a este.'>
                                    <HelpIcon color='primary' style={{ fontSize: '15px' }} />
                                </Tooltip>
                            </Typography>

                            <TextField
                                value={correo}
                                onChange={(e) => { setCorreo(e.target.value.toLowerCase().replace(' ', '')); errores[11] = false; errores[12] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[11]}
                                helperText={errores[11] ? (correo !== '' ? (correo !== correoConfirmacion ? 'Los correos no coinciden' : 'Ingrese un correo válido') : 'Este campo no puede quedar vacío') : null}
                                style={{ marginBottom: errores[12] && !errores[11] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>Confirme su correo electrónico: <span style={{ color: '#e53935' }}>* </span>

                            </Typography>

                            <TextField
                                onCut={(e) => e.preventDefault()}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                value={correoConfirmacion}
                                onChange={(e) => { setCorreoConfirmacion(e.target.value.toLowerCase().replace(' ', '')); errores[11] = false; errores[12] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[12]}
                                helperText={errores[12] ? (correoConfirmacion !== '' ? (correo !== correoConfirmacion ? 'Los correos no coinciden' : 'Ingrese un correo válido') : 'Este campo no puede quedar vacío') : null}
                                style={{ marginBottom: errores[11] && !errores[12] && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1'>Dirección: <span style={{ color: '#e53935' }}>*</span></Typography>
                            <TextField
                                value={direccion}
                                onChange={(e) => { setDireccion(e.target.value); errores[13] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                style={{ marginBottom: [errores[13]].includes(false) && [errores[15]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                                error={errores[13]}
                                helperText={errores[13] ? 'Este campo no puede quedar vacío' : null}
                            />

                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1'>Información adicional:<span style={{ color: '#e53935' }}>*</span></Typography>
                            <TextField
                                value={complementoDireccion}
                                onChange={(e) => { let value = e.target.value; if (value.length < 101) setComplementoDireccion(value); errores[15] = false; setErrores([...errores]) }}
                                size='small'
                                label={complementoDireccion === '' ? 'Casa, apartamento, torre, edificio' : ''}
                                variant='outlined'
                                fullWidth
                                style={{ marginBottom: [errores[13]].includes(true) && [errores[15]].includes(false) && 'xs' !== point ? theme.spacing(2.8) : null }}
                                error={errores[15]}
                                helperText={errores[15] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1'>Barrio: <span style={{ color: '#e53935' }}>*</span></Typography>
                            <TextField
                                value={barrio}
                                onChange={(e) => { if (e.target.value.length < 101) setBarrio(e.target.value.replace(/[^a-zA-ZÀ-ú\u00f1\u00d1\d\s]+$/g, '')); errores[14] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[14]}
                                helperText={errores[14] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[14]].includes(false) && [errores[16]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1'>Localidad o comuna: <span style={{ color: '#e53935' }}>*</span></Typography>
                            <TextField
                                value={localidad}
                                onChange={(e) => { if (e.target.value.length < 101) setLocalidad(e.target.value.replace(/[^a-zA-ZÀ-ú\u00f1\u00d1\d\s]+$/g, '')); errores[16] = false; setErrores([...errores])}}
                                size='small'
                                variant='outlined'
                                fullWidth
                                style={{ marginBottom: [errores[14]].includes(true) && [errores[16]].includes(false) && 'xs' !== point ? theme.spacing(2.8) : null }}
                                error={errores[16]}
                                helperText={errores[16] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                    </Grid>


                </CardContent>
                <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />

                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                    <Button
                        onClick={() => limpiar()}
                        variant='contained'
                        color='secondary'
                    >
                        Limpiar
                    </Button>
                    <Button
                        onClick={() => { validar() }}
                        variant='contained'
                        color='primary'
                    >
                        Continuar
                    </Button>
                </CardActions>
            </Collapse>
        </Card >
    );
}

export default DatosPersonales;