const actividades = [
    { codigo: 'PP', tipo: 'Encuesta puerta a puerta', grupo: 1, color: '#c62828', color2: '#ef9a9a' },
    { codigo: 'PF', tipo: 'Encuesta en sitio localizado o punto fijo', grupo: 1, color: '#2E7D32', color2: '#A5D6A7' },
    { codigo: 'EAU', tipo: 'Encuesta autodiligenciable', grupo: 1, color: '#558B2F', color2: '#C5E1A5' },
    { codigo: 'AMC', tipo: 'Auditoría de material en campo', grupo: 2, color: '#4B9A1B', color2: '#9DD893' },
    { codigo: 'AMPF', tipo: 'Auditoría de material en sitio localizado', grupo: 2, color: '#9E9D24', color2: '#E6EE9C' },
    { codigo: 'AMO', tipo: 'Auditoría de material en sede (requiere autorización especial)', grupo: 2, color: '#4527A0', color2: '#B39DDB' },
    { codigo: 'AMT', tipo: 'Auditoría de material telefónico', grupo: 2, color: '#F9A825', color2: '#FFF59D' },
    { codigo: 'AM', tipo: 'Arreglo de material (requiere autorización especial)', grupo: 3, color: '#283593', color2: '#9FA8DA' },
    { codigo: 'HD', tipo: 'Desplazamiento (4 horas en adelante)', grupo: 3, color: '#1565C0', color2: '#90CAF9' },
    { codigo: 'RC', tipo: 'Reclutamiento / café, sesiones y/o talleres', grupo: 3, color: '#EF6C00', color2: '#FFCC80' },
    { codigo: 'CIP', tipo: 'Introducción a proyecto (cuando aplique)', grupo: 3, color: '#0277BD', color2: '#81D4FA' },
    { codigo: 'DIG', tipo: 'Digitación', grupo: 4, color: '#D84315', color2: '#FFAB91' },
    { codigo: 'COD', tipo: 'Codificación', grupo: 4, color: '#00838F', color2: '#80DEEA' },
    { codigo: 'CT', tipo: 'Citación telefónica', cargo: 'Encuestador', color: '#FF8F00', color2: '#FFE082' },
    { codigo: 'ST', tipo: 'Supervisión telefónica', cargo: 'Supervisor', color: '#FFCC00', color2: '#FFFF66' },
    { codigo: 'RV', tipo: 'Revisión digital', cargo: 'Encuestador', color: '#7D2E79', color2: '#D6A5D4' },
    { codigo: 'MS', tipo: 'Mensajería', grupo: 4, color: '#4E342E', color2: '#BCAAA4' },
    { codigo: 'CCI', tipo: 'Cita cliente incógnito', grupo: 4, color: '#43464b', color2: '#a4acba' },
    { codigo: 'ECP', tipo: 'Encuesta campo presencial (encuestas que no están en INTEGRA)', grupo: 5, color: '#00695C', color2: '#80CBC4' },
    { codigo: 'ECP2', tipo: 'Encuesta campo presencial (encuestas que no están en INTEGRA, sin tarifa)', grupo: 5, color: '#695c00', color2: '#cbc480' },
    { codigo: 'TRP', tipo: 'Transporte', grupo: 5, color: '#455A64', color2: '#90A4AE' },
    { codigo: 'COM', tipo: 'Compensatorio', cargo: 'Supervisor y Encuestador', color: '#6A1B9A', color2: '#CE93D8' },
    { codigo: 'NOV', tipo: 'Novedad', grupo: 5, color: '#ff0000', color2: '#f34141' },
    { codigo: 'AC', tipo: 'Asistencia de campo', grupo: 5, color: '#CDDB16', color2: '#F2F991' },
    { codigo: 'SLC', tipo: 'Selección', grupo: 5, color: '#db1668', color2: '#f991c0' },
    { codigo: 'XX', tipo: 'Otro', grupo: 5, color: '#00695C', color2: '#80CBC4' }
];

export default actividades;