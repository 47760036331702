import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import TodayIcon from '@material-ui/icons/Today';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';

const paths = [
    {nombre: 'Iniciar sesión', path: '/inicio'},
    {nombre: 'Formato registro', path: '/registro'},
    {nombre: 'Inicio', path: '/seleccion', icon: <HomeIcon/>},
    {nombre: 'Postulaciones', path: '/seleccion/postulaciones', icon: <WorkIcon/>},
    {nombre: 'Documentos', path: '/seleccion/documentos', icon: <DescriptionIcon/>},
    {nombre: 'Contrato disponible', path: '/seleccion/contrato_disponible', icon: <CreateOutlinedIcon/>},
    {nombre: 'Contratos', path: '/seleccion/contratos', icon: <CreateOutlinedIcon/>},
    {nombre: 'Calendario', path: '/seleccion/calendario', icon: <TodayIcon/>},
    {nombre: 'Tus datos', path: '/seleccion/datos', icon: <PersonIcon/>},
    {nombre: 'Recuperar contraseña', path: '/seleccion/recovery_password'},
    {nombre: 'Restaurar contraseña', path: '/seleccion/restore_password'},
    {nombre: 'Curso básico', path: 'https://forms.office.com/Pages/ResponsePage.aspx?id=JuXOTNmcFE28pqt51wr5-bNkszo6lQlHoe-WWW8mpW9UQjhJU0VGSVkzM0w1U0U5NzVKWTIzMzFPTS4u'},
    {nombre: 'Formatos responsabilidad', path: '/seleccion/formatos_responsabilidad'},
    {nombre: 'Formato responsabilidad', path: '/formato_responsabilidad'},
    {nombre: 'Formato registro laboral', path: '/registro_laboral'},   
]

export default paths