import React from 'react';
import { IconButton, 
    Drawer,
    Typography, 
    AppBar, 
    Toolbar, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText 
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Link
} from "react-router-dom";

function TemporaryDrawer(props) {
  const {theme, username, paths }= props;
  const [open, setOpen] = props.open;


  return (
    <React.Fragment>
      <Drawer anchor='right' open={open} onClose={() => setOpen(false)} style={{ zIndex: 2000 }}>
        <AppBar position='static'>
          <Toolbar style={{ width: 300 }}>
            <IconButton
              style={{ marginRight: theme.spacing(2) }}
              color='inherit'
              edge='start'
              onClick={() => {setOpen(false)}}
            >
              <MenuIcon />
            </IconButton>
            <Typography style={{fontWeight: 500, marginRight: theme.spacing(2) }} variant='body1'>{username}</Typography>
          </Toolbar>
        </AppBar>
        <List>
          {(paths.filter(item => !(item.nombre.includes("Contrato") || item.nombre.includes("Contratos")))).map((option, index) =>
            <ListItem key={index} component={Link} to={option.path} button onClick={() => { setOpen(false) }}>
              <ListItemIcon>
                {option.icon}
              </ListItemIcon>
              <ListItemText primary={option.nombre} />
            </ListItem>
          )}
        </List>
      </Drawer>
    </React.Fragment>
  );
}

export default TemporaryDrawer;