import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Typography,
    Button,
    Grid,
    TextField,
    Card,
    CardContent,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import { useHistory } from "react-router-dom"
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#B71C1C',
    }
}));

function RecoveryPassword(props) {
    const { theme, setShowBackdrop, API_DEFAULT, setMessage, setSeverity, setSnack, paths } = props;
    const classes = useStyles();
    let history = useHistory();
    const [documento, setDocumento] = useState('');
    const [error, setError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const recovery = async () => {
        setShowBackdrop(true);
        if (error) {
            setShowBackdrop(false);
            setMessage('El documento no puede ser vacio.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        } else {
            const res = await fetch(`${API_DEFAULT}/usuarios/recuperar_contrasenia/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'documento': documento
                })
            });

            if (res.ok) {
                setShowBackdrop(false);
                setOpenDialog(true);
            }
            else {
                setShowBackdrop(false);
                setMessage('Ocurrio un error, intente de nuevo.');
                setSeverity('error');
                setTimeout(() => { setSnack(true) }, 0);

            };
        }
    }

    const handleClose = () => {
        setOpenDialog(false);
        setDocumento('');
        history.push(paths[0].path);
    }

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                        style={{ marginBottom: theme.spacing(4) }}
                    >
                        <Grid item>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant='h5'>Recuperar Contraseña</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                    >
                        <Grid item xs style={{ marginBottom: theme.spacing(2) }}>
                            <Typography variant='body'>
                                Para recuperar su contraseña, por favor digite su número de documento:
                            </Typography>
                        </Grid>
                        <Divider style={{ marginBottom: theme.spacing(2) }} />
                        <Grid item xs style={{ marginBottom: theme.spacing(2) }}>
                            <TextField
                                onCut={(e) => e.preventDefault()}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                value={documento}
                                label='Documento de identidad'
                                onChange={(e) => { if (e.target.value.length <= 10) setDocumento(e.target.value.replace(/[^0-9]/g, '')); setError(e.target.value.replace(/[^0-9]/g, '').length === 0) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={error}
                                helperText={error ? 'Su documento no puede quedar vacío' : null}
                                style={{ marginBottom: error ? theme.spacing(2.8) : null }}
                            />
                        </Grid>

                        <Grid item xs>
                            <Button fullWidth={true} variant='contained' color='primary' disabled={error || documento.length === 0} onClick={() => { recovery() }}>Enviar</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Dialog
                open={openDialog}
                onClose={handleClose}
            >
                <DialogTitle>Correo Enviado</DialogTitle>
                <DialogContent>
                    Hemos recibido la solicitud exitosamente.
                    A su correo le serán enviadas las instrucciones para restablecer su contraseña. Si no encuentra el correo en su bandeja de entrada, por favor revisar la bandeja de spam.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default RecoveryPassword;