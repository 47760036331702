import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';

import estadosContratacion from '../../../data/estadosContratacion';

function DialogoRechazarContrato(props) {
    const {
        API_DEFAULT,
        getAccessTokenWithRefresh,
        setMessage,
        setSeverity,
        setSnack,
        setShowBackdrop,
        estadoContratacion
        // paths,
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [openRechazar, setOpenRechazar] = props.openRechazar;

    async function rechazarContratacion(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/basicos_persona/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'estado_contratacion': 12,
                'perfil': estadosContratacion.filter(e => e.estado === estadoContratacion)[0].cargo_proceso
            })
        })
        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Has rechazado este contrato.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            alert('Has rechazado el contrato.');
            window.location.reload()
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        rechazarContratacion(newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    return (
            <Dialog
                open={openRechazar}
            >
                <DialogTitle>{"¿Rechazar contrato?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de que quieres rechazar este contrato?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setOpenRechazar(false)}} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => {rechazarContratacion()}} color="primary" autoFocus>
                        Rechazar contrato
                    </Button>
                </DialogActions>
            </Dialog>
    );
}

export default DialogoRechazarContrato;