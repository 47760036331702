import React, { useState, useRef, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Grid,
    Divider,
    Collapse,
    CardActions
} from '@material-ui/core';
import { Route, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RecolectorTelefonicoCasa from './Cargos/RecolectorTelefonicoCasa';
import RecolectorPresencial from './Cargos/RecolectorPresencial'
import AuditorPresencial from './Cargos/AuditorPresencial'
import CoordinadorCampo from './Cargos/CoordinadorCampo'
import SupervisorTelefonicoPresencial from './Cargos/SupervisorTelefonicoPresencial'

import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles({
    table: {
        minWidth: 350,
    },
});


function Postulaciones(props) {
    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        paths,
        setSnack,
        setMessage,
        setSeverity,
        setShowBackdrop,
        archivos
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const classes = useStyles();
    const hiddenInputs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [colapso, setColapso] = useState(false);
    const [postulaciones, setPostulaciones] = useState([]);
    const [openAuditor, setOpenAuditor] = useState(false);
    const [openCoordinador, setOpenCoordinador] = useState(false);
    const [openSupervisor, setOpenSupervisor] = useState(false);
    const [openRecolector, setOpenRecolector] = useState(false);
    const [openRecolectorTel, setOpenRecolectorTel] = useState(false);

    async function fetchPostulaciones(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/postulaciones/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchPostulaciones(newAccess);
                }
            }
            else {
                setShowBackdrop(false);
                for (let i = 0; i < res.length; i++) {
                    postulaciones[i] = res[i].perfil_postulacion
                }
                setPostulaciones([...postulaciones])
            }
        });
    }

    useEffect(() => { fetchPostulaciones() }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='Cargos seleccion'>
            <Route
                exact
                path={paths[1].path}
                render={() => (
                    <Card>
                        <CardHeader
                            style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1), marginLeft: theme.spacing(3) }}
                            title={
                                <Typography variant='h6' align='center'>Postulaciones y perfiles</Typography>
                            }
                        />
                        <CardContent>
                            {
                                postulaciones.length > 0 ?
                                    <React.Fragment>
                                        {
                                            archivos.filter(a => a === null).length > 4 ?
                                                <React.Fragment>
                                                    <Grid
                                                        container
                                                        alignItems='center'
                                                        direction='column'
                                                    >
                                                        <Card
                                                            style={{ maxWidth: 475, marginBottom: theme.spacing(4) }}
                                                            variant='outlined'
                                                            align='center'
                                                        >
                                                            <CardContent>
                                                                <Grid
                                                                    container
                                                                    direction='row'
                                                                    spacing={2}
                                                                    alignItems='center'
                                                                >
                                                                    <Grid item xs={6} md={6}>
                                                                        <WarningIcon fontSize='large' />
                                                                    </Grid>
                                                                    <Grid item xs={6} md={6}>
                                                                        <Typography>
                                                                            Recuerda que tus postulaciones no son válidas si no has subido tus documentos personales.
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                            <Divider />
                                                            <CardActions style={{ justifyContent: 'flex-end' }}>
                                                                <Button component={Link} to={paths[2].path} >
                                                                    Subir documentos
                                                                </Button>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                </React.Fragment>
                                                :
                                                null
                                        }
                                        <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
                                        <Grid container
                                            direction='row'
                                            alignItems='center'
                                            spacing={2}
                                            style={{ marginBottom: theme.spacing(4), paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }}
                                        >
                                            <Grid item xs={12} md={12}>
                                                <Typography variant="body1" >
                                                    Ya te has postulado a los siguientes perfiles:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <TableContainer component={Paper} style={{ width: 400, margin: 'auto' }} align='center' variant='outlined'>
                                            <Table className={classes.table} size="medium">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>Perfiles </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {Object.keys(postulaciones).map((row) => (
                                                        <TableRow key={postulaciones[row]}>
                                                            <TableCell component="th" scope="row" align='center'> {postulaciones[row]} </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <Grid container
                                            direction='row'
                                            alignItems='center'
                                            spacing={2}
                                            style={{ marginBottom: theme.spacing(4), paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }}
                                        >
                                            <Grid item xs={12} md={12}>
                                                <Typography variant="body1">
                                                    No te has postulado a ningún cargo.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                            }
                            <Grid container
                                direction='row'
                                alignItems='center'
                                spacing={2}
                                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                            >
                                <Grid item xs={12} md={12} align='center'>
                                    <Button
                                        disabled={postulaciones.length === 5}
                                        variant='contained'
                                        color='primary'
                                        onClick={() => { setColapso(!colapso) }}
                                    >
                                        {colapso ? 'Contraer' : 'Añadir postulación'}
                                    </Button>
                                </Grid>
                            </Grid>

                        </CardContent>
                        <Collapse in={colapso}>
                            <Divider style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }} />
                            <CardHeader
                                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                                title={
                                    <Typography variant='h6' align='center'>Selecciona un perfil para postularte</Typography>
                                }
                            />
                            <Typography style={{ marginLeft: theme.spacing(5) }}>  <span style={{ color: '#e53935' }}>* </span>La información que suministres en los formatos de postulación no podrá ser modificada. </Typography>
                            <CardContent>
                                <Grid container
                                    direction='row'
                                    alignItems='center'
                                    spacing={2}
                                    style={{ marginLeft: theme.spacing(1.5) }}
                                >
                                    <Grid item md={6} xs={12}>
                                        <Button
                                            disabled={postulaciones.includes('Recolector de información presencial')}
                                            variant='contained'
                                            color='primary'
                                            style={{ width: "90%" }}
                                            onClick={() => { setOpenRecolector(true) }}
                                        >
                                            Recolector de información presencial
                                        </Button>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Button
                                            disabled={postulaciones.includes('Recolector de información telefónico')}
                                            variant='contained'
                                            color='primary'
                                            style={{ width: "90%" }}
                                            onClick={() => { setOpenRecolectorTel(true) }}
                                        >
                                            Recolector de información telefónico
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container
                                    direction='row'
                                    alignItems='center'
                                    spacing={2}
                                    style={{ marginTop: theme.spacing(1), marginLeft: theme.spacing(1.5) }}
                                >
                                    <Grid item md={6} xs={12}>
                                        <Button
                                            disabled={postulaciones.includes('Auditor presencial')}
                                            variant='contained'
                                            color='primary'
                                            style={{ width: "90%" }}
                                            onClick={() => { setOpenAuditor(true) }}
                                        >
                                            Auditor campo presencial
                                        </Button>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Button
                                            disabled={postulaciones.includes('Supervisor telefónico')}
                                            variant='contained'
                                            color='primary'
                                            style={{ width: "90%" }}
                                            onClick={() => { setOpenSupervisor(true) }}
                                        >
                                            Supervisor telefónico
                                        </Button>
                                    </Grid>

                                </Grid>

                                <Grid container
                                    direction='row'
                                    alignItems='center'
                                    spacing={2}
                                    style={{ marginTop: theme.spacing(1), marginLeft: theme.spacing(1.5) }}
                                >
                                    <Grid item md={6} xs={12}>
                                        <Button
                                            disabled={postulaciones.includes('Coordinador de campo')}
                                            variant='contained'
                                            color='primary'
                                            style={{ width: "90%" }}
                                            onClick={() => { setOpenCoordinador(true) }}
                                        >
                                            Coordinador de campo
                                        </Button>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Collapse>
                    </Card>
                )}
            />

            <RecolectorPresencial
                theme={theme}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                path={paths[1]}
                setShowBackdrop={setShowBackdrop}
                setSnack={setSnack}
                setMessage={setMessage}
                setSeverity={setSeverity}
                hiddenInputs={hiddenInputs}
                open={[openRecolector, setOpenRecolector]}
            />
            <RecolectorTelefonicoCasa
                theme={theme}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                path={paths[1]}
                setShowBackdrop={setShowBackdrop}
                setSnack={setSnack}
                setMessage={setMessage}
                setSeverity={setSeverity}
                hiddenInputs={hiddenInputs}
                open={[openRecolectorTel, setOpenRecolectorTel]}
            />
            <AuditorPresencial
                theme={theme}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                path={paths[1]}
                setShowBackdrop={setShowBackdrop}
                setSnack={setSnack}
                setMessage={setMessage}
                setSeverity={setSeverity}
                hiddenInputs={hiddenInputs}
                open={[openAuditor, setOpenAuditor]}

            />
            <SupervisorTelefonicoPresencial
                theme={theme}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                path={paths[1]}
                setShowBackdrop={setShowBackdrop}
                setSnack={setSnack}
                setMessage={setMessage}
                setSeverity={setSeverity}
                hiddenInputs={hiddenInputs}
                open={[openSupervisor, setOpenSupervisor]}

            />
            <CoordinadorCampo
                theme={theme}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                path={paths[1]}
                setShowBackdrop={setShowBackdrop}
                setSnack={setSnack}
                setMessage={setMessage}
                setSeverity={setSeverity}
                hiddenInputs={hiddenInputs}
                open={[openCoordinador, setOpenCoordinador]}

            />
        </div >

    );
}
export default Postulaciones;
