const niveles = [
    {nivel: "Preescolar", cod: 0},
    {nivel: "Básica Primaria (1° - 5°)", cod: 1},
    {nivel: "Básica Secundaria (6° - 9°)", cod: 2},
    {nivel: "Media  (10° - 13°)", cod: 3},
    {nivel: "Técnico Laboral", cod: 4},
    {nivel: "Formación Técnica Profesional", cod: 5},
    {nivel: "Tecnológica", cod: 6},
    {nivel: "Universitaria", cod: 7},
    {nivel: "Especialización", cod: 8},
    {nivel: "Maestría", cod: 9},
    {nivel: "Doctorado", cod: 10}
];

export default niveles;