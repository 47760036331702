const estadosContratacion = [
  { estado: "Todos" },
  { estado: "Registrado", cod: 0 },
  {
    estado: "Apto contratación (Recolector presencial)",
    cod: 1,
    cargo_proceso: 0,
  },
  {
    estado: "Apto contratación (Telefonico nacional - integra)",
    cod: 2,
    cargo_proceso: 1,
  },
  { estado: "Apto contratación (Auditor de campo)", cod: 3, cargo_proceso: 2 },
  {
    estado: "Apto contratación (RECLUTADOR FREELANCE)",
    cod: 4,
    cargo_proceso: 3,
  },
  {
    estado: "Apto contratación (Técnico de Instalación)",
    cod: 5,
    cargo_proceso: 4,
  },
  {
    estado: "Apto contratación (Citación telefónica)",
    cod: 17,
    cargo_proceso: 5,
  },
  {
    estado: "Apto contratación (Digitación Camacol)",
    cod: 18,
    cargo_proceso: 6,
  },
  { estado: "Apto contratación (ECAR)", cod: 19, cargo_proceso: 7 },
  { estado: "Contratado (Recolector presencial)", cod: 6, cargo_contratado: 0 },
  {
    estado: "Contratado (Telefonico nacional - integra)",
    cod: 7,
    cargo_contratado: 1,
  },
  { estado: "Contratado (Auditor de campo)", cod: 8, cargo_contratado: 2 },
  { estado: "Contratado (RECLUTADOR FREELANCE)", cod: 9, cargo_contratado: 3 },
  {
    estado: "Contratado (Técnico de Instalación)",
    cod: 10,
    cargo_contratado: 4,
  },
  { estado: "Contratado (Citación telefónica)", cod: 21, cargo_contratado: 5 },
  { estado: "Contratado (Digitación Camacol)", cod: 22, cargo_contratado: 6 },
  { estado: "Contratado (ECAR)", cod: 20, cargo_contratado: 7 },
  { estado: "No apto", cod: 11 },
  { estado: "Rechazó contrato", cod: 12 },
  { estado: "Finalizó contrato", cod: 13 },
  { estado: "Contrato anulado", cod: 14 },
];

export default estadosContratacion;
