import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Grid,
    TextField,
    Divider,
    CardActions,
    Button,
    Collapse,
    IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useBreakpoints from '../../../styles/useBreakpoints';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from "@material-ui/pickers";
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import niveles from '../../../data/nivelEstudios'
import areas from '../../../data/areaEstudios'
import horarios from '../../../data/horarioEstudios'

const useStyles = makeStyles((theme) => ({
    backgroundAutocomplete: {
        backgroundColor: '#E0E0E0'
    }
}));


function EstudiosRealizados(props) {
    const classes = useStyles();
    const point = useBreakpoints();
    const { theme, setMessage, setSnack, setSeverity, setEstudiosRealizados } = props;
    const [errores, setErrores] = useState(Array(9).fill(false));
    const [completado, setCompletado] = props.datosCompletos;
    const [nivel, setNivel] = useState(null);
    const [area, setArea] = useState(null);
    const [estado, setEstado] = useState(null);
    const [fechainicio, setFechaInicio] = useState(null);
    const [fechafin, setFechaFin] = useState(null);
    const [centroeducativo, setCentroEducativo] = useState('');
    const [estudioactual, setEstudioActual] = useState(null);
    const [modalidad, setModalidad] = useState(null);
    const [horario, setHorario] = useState(null);

    const validar = () => {
        let errorDatos = false;
        // completado[1] = true
        // setCompletado([...completado])
        if (nivel === null) {
            errorDatos = true;
            errores[0] = true;
        }
        if (area === null) {
            errorDatos = true;
            errores[1] = true;
        }
        if (estado === null) {
            errorDatos = true;
            errores[2] = true;
        }
        if (fechainicio === null) {
            errorDatos = true;
            errores[3] = true;
        }
        if (estado === 'Culminado' && (fechafin === null || differenceInCalendarDays(fechafin, fechainicio) < 0)) {
            errorDatos = true;
            errores[4] = true;

        }

        if (centroeducativo === '') {
            errorDatos = true;
            errores[5] = true;
        }
        if (estudioactual === null) {
            errorDatos = true;
            errores[6] = true;
        }
        if (estudioactual === 'Sí' && modalidad === null) {
            errorDatos = true;
            errores[7] = true;
        }
        if (estudioactual === 'Sí' && horario === null) {
            errorDatos = true;
            errores[8] = true;
        }


        if (errorDatos) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            setEstudiosRealizados(
                {
                    'ultimo_nivel_estudios': niveles.filter(n => n.nivel === nivel)[0].cod,
                    'area_estudios': areas.filter(a => a.area === area)[0].cod,
                    'estado_estudios': estado === 'Culminado' ? 0 : (estado === 'En curso' ? 1 : (estado === 'Abandonado' ? 2 : 3)),
                    'fecha_inicio_estudios': fechainicio.toISOString().split('T')[0],
                    'fecha_finalizacion_estudios': estado !== 'Culminado' ? '1999-01-01' : fechafin.toISOString().split('T')[0],
                    'centro_educativo': centroeducativo,
                    'estudia_actualmente': estudioactual === 'Sí',
                    'modalidad': estudioactual === 'No' ? 3 : (modalidad === 'Presencial' ? 0 : (modalidad === 'Virtual' ? 1 : 2)),
                    'horario': estudioactual === 'No' ? 8 : horarios.filter(h => h.horario === horario)[0].cod
                }
            )

            completado[1] = true
            setCompletado([...completado])
            setMessage('Los estudios realizados han sido diligenciados correctamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    const limpiar = () => {
        setNivel(null);
        setArea(null);
        setEstado(null);
        setFechaInicio(null);
        setFechaFin(null);
        setCentroEducativo('');
        setEstudioActual(null);
        setModalidad(null);
        setHorario(null);
        setErrores(Array(9).fill(false));

    }

    return (
        <Card variant='outlined' style={{ marginBottom: theme.spacing(1) }}>
            <CardHeader
                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                title={
                    <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500 }}>2. Estudios Realizados: </Typography>
                }
                action={
                    <IconButton
                        disabled={completado[1] === false}
                        onClick={() => { completado[1] = false; setCompletado([...completado]) }}
                        color='secondary'
                        aria-label='add'
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            />
            <Collapse in={completado[1] === false && completado[0] === true} mountOnEnter unmountOnExit>
                <CardContent>
                    <Typography variant='body2' style={{ marginBottom: theme.spacing(1), marginLeft: theme.spacing(2) }}><span style={{ color: '#e53935' }}>*</span> Estos campos son obligatorios</Typography>
                    <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Último nivel de estudios: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                value={nivel}
                                autoFocus
                                onChange={(e, v) => { setNivel(v); setArea(null); errores[0] = false; setErrores([...errores]) }}
                                options={
                                    niveles.map(option => option.nivel)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[0]}
                                        helperText={errores[0] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[1], errores[2]].includes(true) && !errores[0] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Área: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                disabled={nivel === null}
                                value={area}
                                onChange={(e, v) => { setArea(v); errores[1] = false; setErrores([...errores]) }}
                                options={
                                    (["Preescolar", "Básica Primaria (1° - 5°)", "Básica Secundaria (6° - 9°)", "Media  (10° - 13°)"].includes(nivel) ? areas.filter(m => m.nivel === nivel) : areas.filter(m => m.nivel === "Superior")).map(option => option.area)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            className: nivel === null ? classes.backgroundAutocomplete : null
                                        }}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[1]}
                                        helperText={errores[1] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[0], errores[2]].includes(true) && !errores[1] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Estado: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                value={estado}
                                onChange={(e, v) => { setEstado(v); setFechaFin(null); errores[2] = false; errores[4] = false; setErrores([...errores]) }}
                                options={
                                    [
                                        "Culminado", "En curso", "Abandonado",
                                        "Aplazado"
                                    ].map(option => option)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[2]}
                                        helperText={errores[2] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[0], errores[1]].includes(true) && !errores[2] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1'>Fecha de inicio: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechainicio}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaInicio(date); errores[3] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[3]}
                                helperText={errores[3] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[4], errores[5]].includes(true) && !errores[3] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1'>Fecha de finalización: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <KeyboardDatePicker
                                disabled={estado === null || estado !== 'Culminado'}
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechafin}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaFin(date); errores[4] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[4]}
                                helperText={errores[4] ? ((differenceInCalendarDays(fechafin, fechainicio) < 0) ? 'Fecha de finalización es anterior a la fecha de inicio' : 'Este campo no puede quedar vacío') : null}
                                style={{ marginBottom: [errores[3], errores[5]].includes(true) && !errores[4] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Centro Educativo: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                value={centroeducativo}
                                onChange={(e) => { if (e.target.value.length < 101) setCentroEducativo(e.target.value); errores[5] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[5]}
                                helperText={errores[5] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[3], errores[4]].includes(true) && !errores[5] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>¿Estudia actualmente?: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                value={estudioactual}
                                onChange={(e, v) => { setEstudioActual(v); setModalidad(null); setHorario(null); errores[6] = false; errores[7] = false; errores[8] = false; setErrores([...errores]) }}
                                options={
                                    [
                                        "Sí", "No"
                                    ].map(option => option)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[6]}
                                        helperText={errores[6] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[7], errores[8]].includes(true) && !errores[6] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Modalidad: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                disabled={estudioactual === null || estudioactual === 'No'}
                                value={modalidad}
                                onChange={(e, v) => { setModalidad(v); errores[7] = false; setErrores([...errores]) }}
                                options={
                                    [
                                        "Presencial", "Virtual", "Semipresencial"
                                    ].map(option => option)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[7]}
                                        helperText={errores[7] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[6], errores[8]].includes(true) && !errores[7] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Horario: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                disabled={estudioactual === null || estudioactual === 'No'}
                                value={horario}
                                onChange={(e, v) => { setHorario(v); errores[8] = false; setErrores([...errores]) }}
                                options={
                                    horarios.map(option => option.horario)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[8]}
                                        helperText={errores[8] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[6], errores[7]].includes(true) && !errores[8] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                    </Grid>

                </CardContent>
                <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                    <Button
                        onClick={() => limpiar()}
                        variant='contained'
                        color='secondary'
                    >
                        Limpiar
                </Button>
                    <Button
                        onClick={() => { validar() }}
                        variant='contained'
                        color='primary'
                    >
                        Continuar
                </Button>
                </CardActions>
            </Collapse>
        </Card >
    );
};


export default EstudiosRealizados