import React from "react";
import { Card, Typography, CardContent } from "@material-ui/core";

function CaracteristicasContrato(props) {
  const {
    theme,
    // nombre,
    // ciudadResidencia
  } = props;
  return (
    <Card variant="outlined" style={{ marginBottom: theme.spacing(1) }}>
      <CardContent>
        <Typography variant="body1" align="center">
          <span style={{ fontWeight: 500 }}>
            {" "}
            PARA TRABAJAR CON NOSOTROS COMO PRESTADOR DE SERVICIOS, NO OLVIDES:
          </span>
        </Typography>

        <Typography
          component={"span"}
          style={{
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
            display: "inline-block",
          }}
          variant="body1"
          align="justify"
        >
          <ol>
            <li>Para nosotros es importante tu actitud y compromiso.</li>
            <li>
              Firmarás un contrato por prestación de servicios para que se
              conozcan las condiciones contractuales.
            </li>
            <li>
              Como para nosotros es importante que disfrutes de tu pago en las
              fechas acordadas, no olvides que debes subir tu Rut código 49 a
              este aplicativo antes del día 20 de cada mes.
            </li>
            <li>
              Como prestador de servicios ten presente que la afiliación como
              independiente a SALUD y PENSIÓN es tu responsabilidad. Si aún no
              la tienes o no te aparece como independiente, acércate a una
              oficina para que te hagan el correspondiente cambio o afiliación,
              No olvides que debes comunicarnos cuál es tu EPS y fondo de
              pensiones y en el contrato debes adjuntar el certificado de cada
              entidad. Es importante porque haciendo parte de nuestra compañía
              queremos garantizar tu seguridad y tranquilidad. Una vez finalice
              el contrato debes reportar la novedad de retiro al operador de
              servicio (aportes en línea, mi planilla, nuevosoi), para que no se
              genere mora por el no pago.
            </li>
            <li>
              <strong>ARL</strong>: Para tu beneficio, esta afiliación la
              realizamos como contratantes, por tanto, no debes realizar ningún
              trámite para vincularte. Nuestra entidad es ARL SURA y desde aquí
              te generamos la afiliación como independiente.
            </li>
            <li>
              <u>El contratista tiene la obligación</u> de pagar seguridad
              social a salud, pensión y ARL, (Artículo 15 de la Ley 100 de 1993,
              modificado por la Ley 797 de 2003, artículo 3, &ldquo;1. En forma
              obligatoria (&hellip;){" "}
              <u>
                las personas naturales que presten directamente servicios al
                Estado o a las entidades o empresas del sector privado, bajo la
                modalidad de contratos de prestación de servicios
              </u>
              ). El pago se debe hacer por medio de un operador de servicio 7.
              8. (ejemplo: aportes en línea, mi planilla, nuevo soi), sin
              embargo, para que no tengas que desplazarte a realizar el pago, la
              empresa <strong>podrá descontar de los honorarios</strong> el
              porcentaje para seguridad social (Salud, Pensión y ARL), por esta
              razón en el contrato de prestación de servicios se establece lo
              siguiente:{" "}
              <strong>
                <em>
                  cláusula tercera &ldquo;(&hellip;) el CONTRATISTA autoriza al
                  CONTRATANTE a descontar de sus honorarios mensuales y efectuar
                  el pago a seguridad social en calidad de independiente&rdquo;.
                </em>
              </strong>{" "}
              Pero si, por el contrario, deseas tú mismo hacer el pago, debes
              enviar un correo electrónico a{" "}
              <a href="mailto:gestor2@conocimientodinamico.co">
                gestor2@conocimientodinamico.co
              </a>{" "}
              indicando lo siguiente:{" "}
              <strong>
                No autorizo al Centro Nacional de Consultoría para hacer el
                descuento de mis honorarios por concepto de seguridad social. Me
                comprometo a adjuntar la planilla de seguridad social dentro de
                los 3 primeros días del mes siguiente al correo{" "}
                <a href="mailto:gestor1@conocimientodinamico.co">
                  gestor1@conocimientodinamico.co
                </a>
                .
              </strong>{" "}
              Recuerda que el pago a seguridad social que se hace es por los
              días en que efectivamente se prestó el servicio. (ejemplo: 8 días
              en campo para la recolección de información), conforme lo
              establecido en el artículo 3.2.7.1 del Decreto 1273 de 2018.
              Recuerda, si no hay soporte de pago de seguridad social no se
              podrá efectuar el pago de Honorarios.
            </li>
            <li>
              Recuerda que la cuenta de cobro tiene establecido el valor a pagar
              por los días de prestación de servicio, pero una vez se recibe en
              el área contable se procederá a realizarwh los siguientes
              descuentos:
              <ul>
                <li> Descuentos a seguridad social</li>
                <li>
                  El Reteica que equivale al 0.966% (artículo 383 del Estatuto
                  Tributario)
                </li>
              </ul>
            </li>
            <li>
              Recuerda que la forma de pago es Daviplata y debes contar con una
              línea telefónica a tu nombre. La App la debes descargar en tu
              celular. Si aseguras esto, en cada pago, podrás disfrutar de tu
              ingreso sin dificultad.
            </li>

            <center>
              <strong>¡PARA NOSOTROS TU TRANQUILIDAD ES IMPORTANTE!</strong>
            </center>
          </ol>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CaracteristicasContrato;
