import React, { useState, useRef } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    Grid,
    Button,
    CardActions,
    Link,
    TextField,
    InputAdornment,
    IconButton,
    Collapse,
    Tooltip
} from '@material-ui/core';

import { Route } from "react-router-dom";
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { saveAs } from 'file-saver';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';

function Documentos(props) {
    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        setMessage,
        setSnack,
        setSeverity,
        setShowBackdrop,
        fetchArchivos,
        paths,
        estadoAprobacion,
        carnet,
        estadoContratacion,
        hiddenInputs,
        revisionDocs
    } = props;
    const inputRef = useRef();

    const [accessToken, setAccessToken] = props.accessToken;
    const [archivos, setArchivos] = props.archivos;
    const [contrasenaRUT, setContrasenaRUT] = props.contrasenaRUT;
    const [showPassword, setShowPassword] = useState(false);
    const fecha = props.fechaRevisionDocs;
    const fechaCreado = props.fechaCreadoDocs;
    const fechaModificado = props.fechaModDocs;
    const [collapse, setCollapse] = useState(Array(25).fill(false));

    const estadosAprobacion = {
        0: "Sin documentos",
        1: "Sin revisar",
        2: "Documentos aprobados",
        3: "Documentos pendientes",
        4: "Documentos aprobados + RUT aprobado"
    }

    const [open, setOpen] = React.useState(false);
    const actions = [
        { icon: <EmailIcon />, name: 'E-mail', onclick: () => { window.location = 'mailto:documentosseleccion@cnccol.com' } },
        { icon: <WhatsAppIcon />, name: 'WhatsApp', onclick: () => { window.open('https://wa.me/573226619108') } },
    ];

    let now = new Date().toISOString();


    function cargarArchivo(file, index) {
        if (file !== undefined) {
            if (file.size < 27000000) { //~25Mb
                if (file.type.split('/')[0] === 'image' || file.type.split('/')[1] === 'pdf' || file.type.split('/')[1] === 'msword' || file.type.split('/')[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type.split('/')[1] === 'wps-office.docx' || file.type.split('/')[1] === 'wps-office.doc') {
                    archivos[index] = file;
                    setArchivos([...archivos]);
                }
                else {
                    setMessage('El tipo de archivo seleccionado no es soportado. Archivos soportados: imagen, pdf o documento de Word');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            } else {
                setShowBackdrop(false);
                setMessage('El tamaño del archivo excede el limite permitido. 25Mb Máximo.');
                setSeverity('warning');
                setTimeout(() => { setSnack(true) }, 0);
            }
        }
    }

    function cargarRUT(file, index) {
        if (file !== undefined) {
            if (file.size < 27000000) { //~25Mb
                if (file.type.split('/')[1] === 'pdf') {
                    archivos[index] = file;
                    setArchivos([...archivos]);
                }
                else {
                    setMessage('El tipo de archivo seleccionado no es soportado. Solo se permiten archivos PDF.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            } else {
                setShowBackdrop(false);
                setMessage('El tamaño del archivo excede el limite permitido. 25Mb Máximo.');
                setSeverity('warning');
                setTimeout(() => { setSnack(true) }, 0);
            }
        }
    }

    function limpiarArchivos(index) {
        if (estadoAprobacion == 4) {
            setMessage('Usted ya completó sus documentos. Si desea modificarlos, por favor contáctese con el personal de selección');
            setSeverity('warning');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else if (index.length !== undefined) {
            for (let i = 0; i < index.length; i++) {
                archivos[index[i]] = null;
            }
        }
        else {
            archivos[index] = null;
        }
        setArchivos([...archivos]);
    }

    async function postArchivos() {
        setShowBackdrop(true)
        let responses = [];
        for (let i = 0; i < archivos.length; i++) {
            if (archivos[i] === null || archivos[i].url !== undefined) {
                continue
            } else {
                let resArchivo = formArchivos(archivos[i], i);
                responses.push((await resArchivo).status);
            }
        }
        let uniqueResponses = [...new Set(responses)];
        setShowBackdrop(false);
        if ((uniqueResponses.length === 1 && uniqueResponses[0] === 200) || uniqueResponses.length === 0) {
            setMessage('Hemos recibido tus documentos correctamente');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            fetchArchivos();

        }
        else {
            setMessage('Ocurrió un error, intente de nuevo.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    async function formArchivos(archivo, tipo, access = accessToken) {
        let formData = new FormData();
        formData.append('tipo_documento', tipo);
        formData.append('archivo', archivo);
        formData.append('password', contrasenaRUT);
        formData.append('fecha_mod_file', now);

        const res = await fetch(`${API_DEFAULT}/seleccion/crear_doc_soporte/`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${access}` },
            body: formData
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    formArchivos(archivo, tipo, newAccess);
                }
            }
        });
        return res
    }

    async function putEstadoDocumentos(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/editar_basicos_persona/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'aprobado_documentos': 1
            })
        });
        if (!res.ok) {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putEstadoDocumentos(newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const inputs = (name, index) => {
        return (
            <>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                    style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                >
                    <Grid item md={4} xs={12}>
                        <Typography variant='body1'> {name}:
                            <Tooltip title='Ver información'>
                                <IconButton disabled={fechaCreado[index] === ''} color='primary' onClick={() => { collapse[index] = !collapse[index]; setCollapse([...collapse]) }}> {collapse[index] ? <ExpandLess /> : <ExpandMore />}</IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} style={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={archivos[index] ? <DeleteIcon /> : <CloudUploadIcon />}
                            color={archivos[index] ? 'secondary' : 'inherit'}
                            onClick={() => archivos[index] ? limpiarArchivos(index) : hiddenInputs[index].current.click()}
                        >
                            <Typography variant='button'>{archivos[index] ? 'Limpiar' : 'Cargar'}</Typography>
                        </Button>
                        <input
                            type="file"
                            ref={hiddenInputs[index]}
                            style={{ display: 'none' }}
                            onChange={(e) => { cargarArchivo(e.target.files[0], index); e.target.value = null }}
                        />
                    </Grid>
                    <Grid item md={4} xs={4}>
                        {archivos[index] !== null && archivos[index] !== undefined ?
                            <Link component="button" variant="body2" color='initial' onClick={() => {
                                if (archivos[index].url !== undefined) {
                                    saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[index].name}`, archivos[index].name.split('/').slice(-1)[0]);
                                } else {
                                    saveAs(archivos[index], archivos[index].name);
                                }
                            }}>
                                {archivos[index].name && archivos[index].name.split('/').slice(-1)[0].length > 33 
                                    ? archivos[index].name.split('/').slice(-1)[0].substring(0, 15) + "..." + archivos[index].name.split('/').slice(-1)[0].substring(archivos[index].name.split('/').slice(-1)[0].length - 15, archivos[index].name.split('/').slice(-1)[0].length)
                                    : archivos[index].name ? archivos[index].name.split('/').slice(-1)[0] : 'Nombre no disponible'}
                            </Link>
                            :
                            null}
                    </Grid>
                </Grid>
                <Collapse in={collapse[index]}>
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginLeft: theme.spacing(2) }}
                    >
                        <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                            <Typography variant='caption' style={{ fontWeight: 400 }}>
                                Última revisión: {revisionDocs[index] === 0 ? 'No se ha revisado.' : (revisionDocs[index] === 2 ? 'Aprobado el ' + (new Date(fecha[index]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[index]).toLocaleTimeString('en-US')) : 'No aprobado el ' + (new Date(fecha[index]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[index]).toLocaleTimeString('en-US')))}
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                            <Typography variant='caption'>
                                Fecha de carga: {new Date(fechaCreado[index]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[index]).toLocaleTimeString('en-US')}
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} align='left'>
                            <Typography variant='caption'>
                                Fecha de modificación: {fechaModificado[index] === null || fechaModificado[index] === '' ? 'No subido' : (new Date(fechaModificado[index]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[index]).toLocaleTimeString('en-US')) + '.\n'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Collapse>
            </>
        )
    }

    return (
        <div>
            <Route
                exact
                path={paths[2].path}
                render={() => (
                    <Card style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(4) }}>
                        <CardHeader
                            style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                            title={
                                <Typography variant='h6' style={{ fontWeight: 500, textAlign: 'center' }}>Tus documentos </Typography>
                            }
                        />

                        <CardContent>
                            <Typography variant='subtitle2' style={{ marginBottom: theme.spacing(2), fontSize: 18, fontWeight: 500, textAlign: 'center' }}>El estado de revisión actual de tus documentos es: <span style={{ color: '#C70039' }}>{estadosAprobacion[estadoAprobacion]}</span> </Typography>
                            <Divider style={{ marginBottom: theme.spacing(2) }} />
                            <Typography variant='body1' style={{ marginBottom: theme.spacing(2), marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}> Por favor carga los siguientes documentos. Recuerda que puedes entrar y modificarlos en cualquier momento cargándolos nuevamente y oprimiendo el botón enviar. </Typography>
                            {
                                localStorage.getItem("roles").includes("Persona seleccion - Laboral") ? (
                                // localStorage.getItem("roles").includes("Persona seleccion - Obra labor") ? (
                                   <>
                                   {inputs("Documentos de identidad", 0)}
                                   {inputs("Certificado académico", 1)}
                                   {inputs("Certificado laboral", 2)}
                                   {inputs("Hoja de vida", 4)}
                                   {inputs("Foto 3x4", 5)}
                                   {inputs("Constancia de estudios", 6)}
                                   {inputs("Tarjeta profesional", 7)}
                                   {inputs("Certificado de EPS", 8)}
                                   {inputs("Certificado de afiliación pensiones", 9)}
                                   {inputs("Certificado de afiliación cesantias", 10)}
                                   {inputs("Documento de beneficiarios", 11)}
                                   </>
                                ) : (
                                    <>
                                        <Grid
                                            container
                                            direction='row'
                                            alignItems='center'
                                            spacing={4}
                                            style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                                        >
                                            <Grid item md={4} xs={12}>
                                                <Typography variant='body1'> Documento de identidad: <span style={{ color: '#FF0000' }}>*</span>
                                                    <Tooltip title='Ver información'>
                                                        <IconButton disabled={fechaCreado[0] === ''} color='primary' onClick={() => { collapse[0] = !collapse[0]; setCollapse([...collapse]) }}> {collapse[0] ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} xs={4} style={{ textAlign: 'center' }}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    startIcon={archivos[0] ? <DeleteIcon /> : <CloudUploadIcon />}
                                                    color={archivos[0] ? 'secondary' : 'inherit'}
                                                    onClick={() => archivos[0] ? limpiarArchivos(0) : hiddenInputs[0].current.click()}
                                                >
                                                    <Typography variant='button'>{archivos[0] ? 'Limpiar' : 'Cargar'}</Typography>
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={hiddenInputs[0]}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => { cargarArchivo(e.target.files[0], 0); e.target.value = null }}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={4}>
                                                {archivos[0] !== null ?
                                                    <Link component="button" variant="body2" color='initial' onClick={() => {
                                                        if (archivos[0].url !== undefined) {
                                                            saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[0].name}`, archivos[0].name.split('/').slice(-1)[0])
                                                        }
                                                        else {
                                                            saveAs(archivos[0], archivos[0].name.split('/').slice(-1)[0])
                                                        }
                                                    }}>
                                                        {archivos[0].name.split('/').slice(-1)[0].length > 33 ? archivos[0].name.split('/').slice(-1)[0].substring(0, 15) + "..." + archivos[0].name.split('/').slice(-1)[0].substring(archivos[0].name.split('/').slice(-1)[0].length - 15, archivos[0].name.split('/').slice(-1)[0].length) : archivos[0].name.split('/').slice(-1)[0]}
                                                    </Link>
                                                    :
                                                    null}
                                            </Grid>
                                        </Grid>
                                        <Collapse in={collapse[0]}>
                                            <Grid
                                                container
                                                direction='row'
                                                alignItems='flex-start'
                                                spacing={4}
                                                style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                                            >
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                                        Última revisión: {revisionDocs[0] === 0 ? 'No se ha revisado.' : (revisionDocs[0] === 2 ? 'Aprobado el ' + (new Date(fecha[0]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[0]).toLocaleTimeString('en-US')) : 'No aprobado el ' + (new Date(fecha[0]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[0]).toLocaleTimeString('en-US')))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption'>
                                                        Fecha de carga: {new Date(fechaCreado[0]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[0]).toLocaleTimeString('en-US')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left'>
                                                    <Typography variant='caption'>
                                                        Fecha de modificación: {fechaModificado[0] === null || fechaModificado[0] === '' ? 'No subido' : (new Date(fechaModificado[0]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[0]).toLocaleTimeString('en-US')) + '.\n'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                        <Grid
                                            container
                                            direction='row'
                                            alignItems='center'
                                            spacing={4}
                                            style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                                        >
                                            <Grid item md={4} xs={12}>
                                                <Typography variant='body1'> Certificado académico: <span style={{ color: '#FF0000' }}>*</span>
                                                    <Tooltip title='Ver información'>
                                                        <IconButton disabled={fechaCreado[1] === ''} color='primary' onClick={() => { collapse[1] = !collapse[1]; setCollapse([...collapse]) }}> {collapse[1] ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} xs={4} style={{ textAlign: 'center' }}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    startIcon={archivos[1] ? <DeleteIcon /> : <CloudUploadIcon />}
                                                    color={archivos[1] ? 'secondary' : 'inherit'}
                                                    onClick={() => archivos[1] ? limpiarArchivos(1) : hiddenInputs[1].current.click()}
                                                >
                                                    <Typography variant='button'>{archivos[1] ? 'Limpiar' : 'Cargar'}</Typography>
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={hiddenInputs[1]}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => { cargarArchivo(e.target.files[0], 1); e.target.value = null }}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={4}>
                                                {archivos[1] !== null ?
                                                    <Link component="button" variant="body2" color='initial' onClick={() => {
                                                        if (archivos[2].url !== undefined) {
                                                            saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[1].name}`, archivos[1].name.split('/').slice(-1)[0])
                                                        }
                                                        else {
                                                            saveAs(archivos[1], archivos[1].name)
                                                        }
                                                    }}>
                                                        {archivos[1].name.split('/').slice(-1)[0].length > 33 ? archivos[1].name.split('/').slice(-1)[0].substring(0, 15) + "..." + archivos[1].name.split('/').slice(-1)[0].substring(archivos[1].name.split('/').slice(-1)[0].length - 15, archivos[1].name.split('/').slice(-1)[0].length) : archivos[1].name.split('/').slice(-1)[0]}
                                                    </Link>
                                                    :
                                                    null}
                                            </Grid>
                                        </Grid>
                                        <Collapse in={collapse[1]}>
                                            <Grid
                                                container
                                                direction='row'
                                                alignItems='flex-start'
                                                spacing={4}
                                                style={{ marginLeft: theme.spacing(2) }}
                                            >
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                                        Última revisión: {revisionDocs[1] === 0 ? 'No se ha revisado.' : (revisionDocs[1] === 2 ? 'Aprobado el ' + (new Date(fecha[1]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[1]).toLocaleTimeString('en-US')) : 'No aprobado el ' + (new Date(fecha[1]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[1]).toLocaleTimeString('en-US')))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption'>
                                                        Fecha de carga: {new Date(fechaCreado[1]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[1]).toLocaleTimeString('en-US')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left'>
                                                    <Typography variant='caption'>
                                                        Fecha de modificación: {fechaModificado[1] === null || fechaModificado[1] === '' ? 'No subido' : (new Date(fechaModificado[1]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[1]).toLocaleTimeString('en-US')) + '.\n'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                        <Grid
                                            container
                                            direction='row'
                                            alignItems='center'
                                            spacing={4}
                                            style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                                        >
                                            <Grid item md={4} xs={12}>
                                                <Typography variant='body1'> Certificación laboral:
                                                    <Tooltip title='Ver información'>
                                                        <IconButton disabled={fechaCreado[2] === ''} color='primary' onClick={() => { collapse[2] = !collapse[2]; setCollapse([...collapse]) }}> {collapse[2] ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} xs={4} style={{ textAlign: 'center' }}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    startIcon={archivos[2] ? <DeleteIcon /> : <CloudUploadIcon />}
                                                    color={archivos[2] ? 'secondary' : 'inherit'}
                                                    onClick={() => archivos[2] ? limpiarArchivos(2) : hiddenInputs[2].current.click()}
                                                >
                                                    <Typography variant='button'>{archivos[2] ? 'Limpiar' : 'Cargar'}</Typography>
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={hiddenInputs[2]}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => { cargarArchivo(e.target.files[0], 2); e.target.value = null }}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={4}>
                                                {archivos[2] !== null ?
                                                    <Link component="button" variant="body2" color='initial' onClick={() => {
                                                        if (archivos[2].url !== undefined) {
                                                            saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[2].name}`, archivos[2].name.split('/').slice(-1)[0])
                                                        }
                                                        else {
                                                            saveAs(archivos[2], archivos[2].name)
                                                        }
                                                    }}>
                                                        {archivos[2].name.split('/').slice(-1)[0].length > 33 ? archivos[2].name.split('/').slice(-1)[0].substring(0, 15) + "..." + archivos[2].name.split('/').slice(-1)[0].substring(archivos[2].name.split('/').slice(-1)[0].length - 15, archivos[2].name.split('/').slice(-1)[0].length) : archivos[2].name.split('/').slice(-1)[0]}
                                                    </Link>
                                                    :
                                                    null}
                                            </Grid>
                                        </Grid>
                                        <Collapse in={collapse[2]}>
                                            <Grid
                                                container
                                                direction='row'
                                                alignItems='flex-start'
                                                spacing={4}
                                                style={{ marginLeft: theme.spacing(2) }}
                                            >
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                                        Última revisión: {revisionDocs[2] === 0 ? 'No se ha revisado.' : (revisionDocs[2] === 2 ? 'Aprobado el ' + (new Date(fecha[2]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[2]).toLocaleTimeString('en-US')) : 'No aprobado el ' + (new Date(fecha[2]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[2]).toLocaleTimeString('en-US')))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption'>
                                                        Fecha de carga: {new Date(fechaCreado[2]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[2]).toLocaleTimeString('en-US')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left'>
                                                    <Typography variant='caption'>
                                                        Fecha de modificación: {fechaModificado[2] === null || fechaModificado[2] === '' ? 'No subido' : (new Date(fechaModificado[2]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[2]).toLocaleTimeString('en-US')) + '.\n'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                        <Grid
                                            container
                                            direction='row'
                                            alignItems='center'
                                            spacing={4}
                                            style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                                        >
                                            <Grid item md={4} xs={12}>
                                                <Typography variant='body1'> Hoja de vida:
                                                    <Tooltip title='Ver información'>
                                                        <IconButton disabled={fechaCreado[4] === ''} color='primary' onClick={() => { collapse[4] = !collapse[4]; setCollapse([...collapse]) }}> {collapse[4] ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} xs={4} style={{ textAlign: 'center' }}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    startIcon={archivos[4] ? <DeleteIcon /> : <CloudUploadIcon />}
                                                    color={archivos[4] ? 'secondary' : 'inherit'}
                                                    onClick={() => archivos[4] ? limpiarArchivos(4) : hiddenInputs[4].current.click()}
                                                >
                                                    <Typography variant='button'>{archivos[4] ? 'Limpiar' : 'Cargar'}</Typography>
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={hiddenInputs[4]}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => { cargarArchivo(e.target.files[0], 4); e.target.value = null }}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={4}>
                                                {archivos[4] !== null ?
                                                    <Link component="button" variant="body2" color='initial' onClick={() => {
                                                        if (archivos[4].url !== undefined) {
                                                            saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[4].name}`, archivos[4].name.split('/').slice(-1)[0])
                                                        }
                                                        else {
                                                            saveAs(archivos[4], archivos[4].name)
                                                        }
                                                    }}>
                                                        {archivos[4].name.split('/').slice(-1)[0].length > 33 ? archivos[4].name.split('/').slice(-1)[0].substring(0, 15) + "..." + archivos[4].name.split('/').slice(-1)[0].substring(archivos[4].name.split('/').slice(-1)[0].length - 15, archivos[4].name.split('/').slice(-1)[0].length) : archivos[4].name.split('/').slice(-1)[0]}
                                                    </Link>
                                                    :
                                                    null}
                                            </Grid>
                                        </Grid>
                                        <Collapse in={collapse[4]}>
                                            <Grid
                                                container
                                                direction='row'
                                                alignItems='flex-start'
                                                spacing={4}
                                                style={{ marginLeft: theme.spacing(2) }}
                                            >
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                                        Última revisión: {revisionDocs[4] === 0 ? 'No se ha revisado.' : (revisionDocs[4] === 2 ? 'Aprobado el ' + (new Date(fecha[4]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[4]).toLocaleTimeString('en-US')) : 'No aprobado el ' + (new Date(fecha[4]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[4]).toLocaleTimeString('en-US')))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption'>
                                                        Fecha de carga: {new Date(fechaCreado[4]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[4]).toLocaleTimeString('en-US')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left'>
                                                    <Typography variant='caption'>
                                                        Fecha de modificación: {fechaModificado[4] === null || fechaModificado[4] === '' ? 'No subido' : (new Date(fechaModificado[4]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[4]).toLocaleTimeString('en-US')) + '.\n'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                        <Grid
                                            container
                                            direction='row'
                                            alignItems='center'
                                            spacing={4}
                                            style={{ marginLeft: theme.spacing(2) }}
                                        >
                                            <Grid item md={4} xs={12}>
                                                <Typography variant='body1'> RUT COD. 49: <span style={{ color: '#764304' }}>*</span>
                                                    <Tooltip title='Ver información'>
                                                        <IconButton disabled={fechaCreado[3] === ''} color='primary' onClick={() => { collapse[3] = !collapse[3]; setCollapse([...collapse]) }}> {collapse[3] ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} xs={4} style={{ textAlign: 'center' }}>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    startIcon={archivos[3] ? <DeleteIcon /> : <CloudUploadIcon />}
                                                    color={archivos[3] ? 'secondary' : 'inherit'}
                                                    onClick={() => archivos[3] ? limpiarArchivos(3) : hiddenInputs[3].current.click()}
                                                >
                                                    <Typography variant='button'>{archivos[3] ? 'Limpiar' : 'Cargar'}</Typography>
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={hiddenInputs[3]}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => { cargarRUT(e.target.files[0], 3); e.target.value = null }}
                                                />
                                            </Grid>
                                            <Grid item md={4} xs={4}>
                                                {archivos[3] !== null ?
                                                    <Link component="button" variant="body2" color='initial' onClick={() => {
                                                        if (archivos[3].url !== undefined) {
                                                            saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[3].name}`, archivos[3].name.split('/').slice(-1)[0])
                                                        }
                                                        else {
                                                            saveAs(archivos[3], archivos[3].name)
                                                        }
                                                    }}>
                                                        {archivos[3].name.split('/').slice(-1)[0].length > 33 ? archivos[3].name.split('/').slice(-1)[0].substring(0, 15) + "..." + archivos[3].name.split('/').slice(-1)[0].substring(archivos[3].name.split('/').slice(-1)[0].length - 15, archivos[3].name.split('/').slice(-1)[0].length) : archivos[3].name.split('/').slice(-1)[0]}
                                                    </Link>
                                                    :
                                                    null}
                                            </Grid>
                                        </Grid>
                                        <Collapse in={collapse[3]}>
                                            <Grid
                                                container
                                                direction='row'
                                                alignItems='flex-start'
                                                spacing={4}
                                                style={{ marginLeft: theme.spacing(2) }}
                                            >
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                                        Última revisión: {revisionDocs[3] === 0 ? 'No se ha revisado.' : (revisionDocs[3] === 2 ? 'Aprobado el ' + (new Date(fecha[3]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[3]).toLocaleTimeString('en-US')) : 'No aprobado el ' + (new Date(fecha[3]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[3]).toLocaleTimeString('en-US')))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left' style={{ marginBottom: theme.spacing(-4) }}>
                                                    <Typography variant='caption'>
                                                        Fecha de carga: {new Date(fechaCreado[3]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[3]).toLocaleTimeString('en-US')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} align='left'>
                                                    <Typography variant='caption'>
                                                        Fecha de modificación: {fechaModificado[3] === null || fechaModificado[3] === '' ? 'No subido' : (new Date(fechaModificado[3]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[3]).toLocaleTimeString('en-US')) + '.\n'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </>
                                )
                            }
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={4}
                                style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), marginTop: theme.spacing(2) }}
                            >
                                <Grid item md={6} xs={6}>
                                    <Typography style={{ fontSize: 12 }} variant='body1'><span style={{ color: '#764304' }}>*</span> El RUT debe subirse en formato PDF, preferiblemente original y no escaneado. Para instrucciones de cómo sacar el RUT, puede dirigirse al siguiente video: <a style={{ fontSize: 12 }} href="https://www.youtube.com/watch?v=y1ctSjsJryg" target="_blank" rel="noopener noreferrer">Cómo sacar el RUT por primera vez por internet en línea desde la pagina de la DIAN</a>
                                        , o directamente desde la aplicación: <a href="https://play.google.com/store/apps/details?id=co.gov.dian" target="_blank" rel="noopener noreferrer" style={{ fontSize: 12 }}>App DIAN - PlayStore</a>
                                        .</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} >
                                    <Typography variant='body1' style={{ marginBottom: theme.spacing(2), fontSize: 12 }}> De lo posible, adjunta tu RUT sin contraseña. En caso de que el archivo PDF tenga contraseña, por favor, escríbela aquí:  </Typography>

                                    <TextField
                                        inputRef={inputRef}
                                        variant='outlined'
                                        label='Contraseña RUT'
                                        type={showPassword ? 'text' : 'password'}
                                        size='small'
                                        value={contrasenaRUT}
                                        onChange={(e) => { setContrasenaRUT(e.target.value) }}
                                        InputProps={{
                                            endAdornment: (
                                                < InputAdornment position='end' >
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                                <Button
                                    onClick={() => {
                                        postArchivos();
                                        if ((archivos.some((v) => v !== null)) && estadoAprobacion === 0) {
                                            putEstadoDocumentos();
                                        }
                                    }}
                                    variant='contained'
                                    color='primary'
                                >
                                    Enviar
                                </Button>
                            </CardActions>

                            {
                                (carnet !== null && estadoContratacion.includes('Contratado')) ?
                                    <React.Fragment>
                                        <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                                        <Typography variant='h6' style={{ marginLeft: theme.spacing(4), marginTop: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: 'center' }}>Este es tu carnet:</Typography>
                                        <div align="center">
                                            <iframe
                                                title="Carnet"
                                                width="700"
                                                height="600"
                                                src={`${carnet}`}>
                                            </iframe>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }

                        </CardContent>
                    </Card>

                )}

            />
            <div>
                <Tooltip title="¿Tienes preguntas? ¿Necesitas ayuda? Comunícate con nosotros." >
                    <SpeedDial
                        ariaLabel="SpeedDial"
                        style={{ position: 'fixed', bottom: theme.spacing(2), right: theme.spacing(2) }}
                        icon={<HelpOutlineIcon />}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipOpen
                                onClick={action.onclick}
                            />
                        ))}
                    </SpeedDial>
                </Tooltip>
            </div>
        </div>


    );
}
export default Documentos;