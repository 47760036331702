import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    Grid,
    TextField,
    Tooltip,
    Button,
    CardActions,
    Container,
} from '@material-ui/core';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { Autocomplete } from '@material-ui/lab';
import useBreakpoints from '../../../styles/useBreakpoints';
import { KeyboardDatePicker } from "@material-ui/pickers";

import municipios from '../../../data/municipios'
import niveles from '../../../data/nivelEstudios';
import areas from '../../../data/areaEstudios';
import horarios from '../../../data/horarioEstudios';
import epss from '../../../data/epss';
import fondosPension from '../../../data/fondosPension';
import tipoAfiliaciones from '../../../data/tipoAfiliaciones';
import horarioEstudios from '../../../data/horarioEstudios';

function TusDatos(props) {
    const point = useBreakpoints();
    const { theme,
        classes,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        validRole,
        setMessage,
        setSnack,
        setSeverity,
        setShowBackdrop,
        cedula
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;

    const [nombre, setNombre] = useState('');
    const [primerApellido, setPrimerApellido] = useState('');
    const [segundoApellido, setSegundoApellido] = useState('');
    const [primerNombre, setPrimerNombre] = useState('');
    const [segundoNombre, setSegundoNombre] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [documento, setDocumento] = useState('');
    const [fechaExpedicion, setFechaExpedicion] = useState('');
    const [lugarExpedicion, setLugarExpedicion] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState('');
    const [ciudad, setCiudad] =useState('');
    const [celular, setCelular] = useState('');
    const [celular2, setCelular2] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [direccion, setDireccion] = useState('');
    const [complementoDireccion, setComplementoDireccion] = useState('');
    const [barrio, setBarrio] = useState('');
    const [localidad, setLocalidad] = useState('');

    // Estudios realizados
    const [nivel, setNivel] = useState('');
    const [area, setArea] = useState('');
    const [estado, setEstado] = useState('');
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [centroEducativo, setCentroEducativo] = useState('');
    const [estudioActual, setEstudioActual] = useState('');
    const [modalidad, setModalidad] = useState('');
    const [horario, setHorario] = useState('');

    // Información personal 
    const [cirugia, setCirugia] = useState('');
    const [tipoCirugia, setTipoCirugia] = useState('');
    const [medicamento, setMedicamento] = useState('');
    const [tipoMedicamento, setTipoMedicamento] = useState('');
    const [nombreFamiliar, setNombreFamiliar] = useState(Array(1).fill(''));
    const [parentesco, setParentesco] = useState(Array(1).fill(''));
    const [telefonoFamiliar, setTelefonoFamiliar] = useState(Array(1).fill(''));
    const [nombreReferencia, setNombreReferencia] = useState('');
    const [relacionReferencia, setRelacionReferencia] = useState('');
    const [telefonoReferencia, setTelefonoReferencia] = useState('');
    const [nombreReferencia2, setNombreReferencia2] = useState('');
    const [relacionReferencia2, setRelacionReferencia2] = useState('');
    const [telefonoReferencia2, setTelefonoReferencia2] = useState('');
    const [eps, setEps] = useState('');
    const [fondoPension, setFondoPension] = useState('');
    const [estadoAfiliacion, setEstadoAfiliacion] = useState('');
    const [tipoAfiliacion, setTipoAfiliacion] = useState('');

    const [readOnly, setReadOnly] = useState(true);
    const [errores, setErrores] = useState(Array(22).fill(false));

    async function fetchDatosPersonales(cc, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/persona_seleccion=${cc}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchDatosPersonales(cc, newAccess);
                }
            }
            else {
                setNombre(res.nombre);
                setPrimerApellido(res.p_apellido);
                setSegundoApellido(res.s_apellido);
                setPrimerNombre(res.p_nombre);
                setSegundoNombre(res.s_nombre);
                setTipoDocumento(res.tipo_documento);
                setDocumento(res.documento);
                setFechaExpedicion(new Date(res.fecha_expedicion.split('-')[0], res.fecha_expedicion.split('-')[1] - 1, res.fecha_expedicion.split('-')[2]))
                setLugarExpedicion(municipios.filter(m => m.daneMuni === res.lugar_expedicion)[0].municipio + ' - ' + municipios.filter(m => m.daneMuni === res.lugar_expedicion)[0].departamento);
                setFechaNacimiento(new Date(res.fecha_nacimiento.split('-')[0], res.fecha_nacimiento.split('-')[1] - 1, res.fecha_nacimiento.split('-')[2]));
                setCiudad(municipios.filter(m => m.daneMuni === res.ciudad_residencia)[0].municipio + ' - ' + municipios.filter(m => m.daneMuni === res.ciudad_residencia)[0].departamento);
                setCelular(String(res.celular));
                setCelular2(String(res.celular_alternativo));
                setTelefono(res.telefono);
                setCorreo(res.correo);
                setDireccion(res.direccion);
                setComplementoDireccion(res.complemento_direccion);
                setBarrio(res.barrio);
                setLocalidad(res.localidad);

                setNivel(res.ultimo_nivel_estudios);
                setArea(res.area_estudios);
                setEstado(res.estado_estudios);
                setFechaInicio(new Date(res.fecha_inicio_estudios.split('-')[0], res.fecha_inicio_estudios.split('-')[1] - 1, res.fecha_inicio_estudios.split('-')[2]));
                if (res.fecha_finalizacion_estudios !== "1999-01-01") { setFechaFin(new Date(res.fecha_finalizacion_estudios.split('-')[0], res.fecha_finalizacion_estudios.split('-')[1] - 1, res.fecha_finalizacion_estudios.split('-')[2])); }
                setCentroEducativo(res.centro_educativo);
                setEstudioActual(res.estudia_actualmente);
                setModalidad(res.modalidad);
                setHorario(res.horario);

                setCirugia(res.tuvo_cirugia);
                setTipoCirugia(res.que_cirugia);
                setMedicamento(res.toma_medicamento);
                setTipoMedicamento(res.que_medicamento);
                for (let i = 0; i < res.nucleo_familiar.length; i++) {
                    nombreFamiliar[i] = res.nucleo_familiar[i].nombre_familiar; setNombreFamiliar([...nombreFamiliar]);
                    parentesco[i] = res.nucleo_familiar[i].parentesco; setParentesco([...parentesco]);
                    telefonoFamiliar[i] = res.nucleo_familiar[i].telefono_familiar; setTelefonoFamiliar([...telefonoFamiliar]);
                }
                setNombreReferencia(res.nombre_referencia_1);
                setRelacionReferencia(res.relacion_referencia_1);
                setTelefonoReferencia(res.telefono_referencia_1);
                setNombreReferencia2(res.nombre_referencia_2);
                setRelacionReferencia2(res.relacion_referencia_2);
                setTelefonoReferencia2(res.telefono_referencia_2);
                setEps(res.eps);
                setFondoPension(res.pension);
                setEstadoAfiliacion(res.esta_afiliado);
                setTipoAfiliacion(res.tipo_afiliacion);

                setShowBackdrop(false);
            }
        });
    }


    const validar = () => {
        let errorDatos = false;
        // Errores de datos personales:
        if (primerApellido === '') {
            errorDatos = true;
            errores[0] = true;
        }
        if (primerNombre === '') {
            errorDatos = true;
            errores[1] = true;
        }
        if (differenceInYears(fechaExpedicion, fechaNacimiento) < 18) {
            errorDatos = true;
            errores[2] = true;
        }
        if (differenceInYears(new Date(), fechaNacimiento) < 18) {
            errorDatos = true;
            errores[3] = true;
        }
        if (celular === '' || parseInt(celular) === 0 || celular.length !== 10) {
            errorDatos = true;
            errores[4] = true;
        }
        if (celular2 === '' || parseInt(celular2) === 0 || celular2.length !== 10) {
            errorDatos = true;
            errores[5] = true;
        }
        if (/^\S+@\S+\.\S+$/.test(correo) === false || correo === '') {
            errorDatos = true;
            errores[6] = true;
        }
        if (direccion === '') {
            errorDatos = true;
            errores[7] = true;
        }
        if (barrio === '') {
            errorDatos = true;
            errores[8] = true;
        }
        // Errores de estudios realizados:
        if (area === null) {
            errorDatos = true;
            errores[9] = true;
        }
        if (estado === 'Culminado' && (fechaFin === null || differenceInCalendarDays(fechaFin, fechaInicio) < 0)) {
            errorDatos = true;
            errores[10] = true;

        }
        if (centroEducativo === '') {
            errorDatos = true;
            errores[11] = true;
        }
        if (estudioActual === 'Sí' && modalidad === null) {
            errorDatos = true;
            errores[12] = true;
        }
        if (estudioActual === 'Sí' && horario === null) {
            errorDatos = true;
            errores[13] = true;
        }
        // Errores Información Personal
        if (cirugia === 'Sí' && tipoCirugia === '') {
            errorDatos = true;
            errores[14] = true;
        }
        if (medicamento === 'Sí' && tipoMedicamento === '') {
            errorDatos = true;
            errores[15] = true;
        }
        if (nombreReferencia === '') {
            errorDatos = true;
            errores[16] = true;
        }
        if (relacionReferencia === '') {
            errorDatos = true;
            errores[17] = true;
        }
        if (telefonoReferencia === '' || parseInt(telefonoReferencia) === 0) {
            errorDatos = true;
            errores[18] = true;
        }
        if (nombreReferencia2 === '') {
            errorDatos = true;
            errores[19] = true;
        }
        if (relacionReferencia2 === '') {
            errorDatos = true;
            errores[20] = true;
        }
        if (telefonoReferencia2 === '' || parseInt(telefonoReferencia2) === 0) {
            errorDatos = true;
            errores[21] = true;
        }
        if (errorDatos) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
            setReadOnly(false);
        }
        else {
            putInformation(cedula)
        }
    }

    async function putInformation(cc, access = accessToken) {
        let municipio_exp = lugarExpedicion.split(' - ')[0];
        let departamento_exp = lugarExpedicion.split(' - ')[1];
        let municipio_res = ciudad.split(' - ')[0];
        let departamento_res = ciudad.split(' - ')[1];
        let res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/persona_seleccion=${cedula}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify(
                {
                    'p_apellido': primerApellido,
                    's_apellido': segundoApellido,
                    'p_nombre': primerNombre,
                    's_nombre': segundoNombre,
                    'tipo_documento': tipoDocumento === 'Cédula de Ciudadanía' ? 0 : 1,
                    'documento': parseInt(documento),
                    'fecha_expedicion': fechaExpedicion.toISOString().split('T')[0],
                    'lugar_expedicion': municipios.filter(m => m.municipio === municipio_exp && m.departamento === departamento_exp)[0].daneMuni,
                    'fecha_nacimiento': fechaNacimiento.toISOString().split('T')[0],
                    'celular': celular,
                    'celular_alternativo': celular2,
                    'telefono': telefono === '' ? 0 : telefono,
                    'correo': correo,
                    'ciudad_residencia': municipios.filter(m => m.municipio === municipio_res && m.departamento === departamento_res)[0].daneMuni,
                    'direccion': {
                        'descripcion': direccion,
                    },
                    'complemento_direccion': complementoDireccion,
                    'barrio': barrio,
                    'localidad': localidad,
                    'ultimo_nivel_estudios': niveles.filter(n => n.nivel === nivel)[0].cod,
                    'area_estudios': areas.filter(a => a.area === area)[0].cod,
                    'estado_estudios': estado === 'Culminado' ? 0 : (estado === 'En curso' ? 1 : (estado === 'Abandonado' ? 2 : 3)),
                    'fecha_inicio_estudios': fechaInicio.toISOString().split('T')[0],
                    'fecha_finalizacion_estudios': estado !== 'Culminado' ? '1999-01-01' : fechaFin.toISOString().split('T')[0],
                    'centro_educativo': centroEducativo,
                    'estudia_actualmente': estudioActual === 'Sí',
                    'modalidad': estudioActual === 'No' ? 3 : (modalidad === 'Presencial' ? 0 : (modalidad === 'Virtual' ? 1 : 2)),
                    'horario': estudioActual === 'No' ? 8 : horarios.filter(h => h.horario === horario)[0].cod,
                    'tuvo_cirugia': cirugia === 'Sí',
                    'que_cirugia': tipoCirugia,
                    'toma_medicamento': medicamento === 'Sí',
                    'que_medicamento': tipoMedicamento,
                    'nucleo_familiar': nombreFamiliar.map((v, i) => (
                        {
                            'nombre_familiar': nombreFamiliar[i],
                            'parentesco': parentesco[i],
                            'telefono_familiar': telefonoFamiliar[i]
                        }
                    )),
                    'nombre_referencia_1': nombreReferencia,
                    'relacion_referencia_1': relacionReferencia,
                    'telefono_referencia_1': telefonoReferencia,
                    'nombre_referencia_2': nombreReferencia2,
                    'relacion_referencia_2': relacionReferencia2,
                    'telefono_referencia_2': telefonoReferencia2,
                    'eps': epss.filter(e => e.eps === eps)[0].cod,
                    'pension': fondosPension.filter(p => p.pension === fondoPension)[0].cod,
                    'esta_afiliado': estadoAfiliacion,
                    'tipo_afiliacion': tipoAfiliaciones.filter(t => t.tipo === tipoAfiliacion)[0].cod
                }
            )
        })
        if (res.ok) {
            setShowBackdrop(false);
            setMessage(`Se han actualizado los datos personales de ${nombre}.`);
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            fetchDatosPersonales(cedula);
        } else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putInformation(cc, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }

    }

    useEffect(() => { if (validRole) { if (cedula === '') { fetchDatosPersonales(localStorage.getItem('cedula')) } else { fetchDatosPersonales(cedula) } } }, [validRole]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container maxWidth="lg">
            <Card variant='outlined' style={{ marginBottom: theme.spacing(1) }}>
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                    title={
                        <Typography variant='h6' align='center'>Tus datos</Typography>
                    }
                />
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                    title={
                        <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500 }}>1. Datos Personales:</Typography>
                    }
                />
                <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Primer apellido: </Typography>
                            <TextField
                                value={primerApellido}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if ((value.length > 0)) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) { setPrimerApellido(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')); errores[0] = false; setErrores([...errores]) } }}
                                style={{ marginBottom: [errores[1]].includes(true) && !errores[0] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                error={errores[0]}
                                helperText={errores[0] ? 'Este campo no puede quedar vacío' : null}
                            />

                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}> Segundo apellido: </Typography>

                            <TextField
                                value={segundoApellido}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) setSegundoApellido(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')) }}
                                style={{ marginBottom: [errores[0], errores[1]].includes(true) && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Primer nombre: </Typography>

                            <TextField
                                value={primerNombre}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) setPrimerNombre(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')); errores[1] = false; setErrores([...errores]) }}
                                style={{ marginBottom: [errores[0]].includes(true) && !errores[1] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                error={errores[1]}
                                helperText={errores[1] ? 'Este campo no puede quedar vacío' : null}
                            />

                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}> Segundo nombre: </Typography>
                            <TextField
                                value={segundoNombre}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) setSegundoNombre(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')) }}
                                style={{ marginBottom: [errores[0], errores[1]].includes(true) && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}

                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}> Tipo de documento: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={tipoDocumento}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        style={{ marginBottom: errores[2] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={tipoDocumento}
                                        onChange={(e, v) => { setTipoDocumento(v) }}
                                        options={
                                            ['Cédula de Ciudadanía', 'Cédula de Extranjería'].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[2] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de expedición: </Typography>
                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaExpedicion}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaExpedicion(date); errores[2] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[2]}
                                helperText={errores[2] ? (differenceInYears(fechaExpedicion, fechaNacimiento) < 18 ? 'Fecha de expedición incorrecta' : 'Este campo no puede quedar vacío') : null}
                                disabled={readOnly}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Número de documento:  </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={documento}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[2] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Tooltip title='Este campo no puede ser modificado.' arrow>
                                        <TextField
                                            value={documento}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            style={{ marginBottom: errores[2] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />
                                    </Tooltip>
                                }
                            </React.Fragment>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Lugar de expedición: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?
                                        <TextField
                                            value={lugarExpedicion}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            style={{ marginBottom: errores[3] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />
                                        :
                                        <Autocomplete
                                            value={lugarExpedicion}
                                            onChange={(e, v) => { setLugarExpedicion(v) }}
                                            options={
                                                municipios.map(option => option.municipio + ' - ' + option.departamento)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{ marginBottom: errores[3] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                                />
                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de nacimiento: </Typography>
                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaNacimiento}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaNacimiento(date); errores[3] = false; errores[2] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[3]}
                                helperText={errores[3] ? (differenceInYears(fechaExpedicion, fechaNacimiento) < 18 ? 'Fecha de expedición incorrecta' : 'Este campo no puede quedar vacío') : null}
                                disabled={readOnly}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Ciudad de residencia: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?
                                        <TextField
                                            value={ciudad}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            style={{ marginBottom: errores[3] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />
                                        :
                                        <Autocomplete
                                            value={ciudad}
                                            onChange={(e, v) => { setCiudad(v) }}
                                            options={
                                                municipios.map(option => option.municipio + ' - ' + option.departamento)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{ marginBottom: errores[3] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                                />
                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Celular: </Typography>

                            <TextField
                                value={celular}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length <= 10) setCelular(e.target.value.replace(/[^0-9]/g, '')); errores[4] = false; setErrores([...errores]) }}
                                error={errores[4]}
                                helperText={errores[4] ? (celular.length !== 0 && celular.length !== 10) ? 'El celular tiene menos de 10 dígitos' : 'Este campo no puede quedar vacío' : null}

                            />

                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Celular alternativo: </Typography>

                            <TextField
                                value={celular2}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length <= 10) setCelular2(e.target.value.replace(/[^0-9]/g, '')); errores[5] = false; setErrores([...errores]) }}
                                error={errores[5]}
                                helperText={errores[5] ? (celular2.length !== 0 && celular2.length !== 10) ? 'El celular tiene menos de 10 dígitos' : 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono:</Typography>

                            <TextField
                                value={telefono}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length <= 10) setTelefono(e.target.value.replace(/[^0-9]/g, '')); }}
                                style={{ marginBottom: [errores[4], errores[5]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Correo electrónico: </Typography>
                            <TextField
                                value={correo}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { setCorreo(e.target.value.toLowerCase()); errores[6] = false; setErrores([...errores]) }}
                                errores={errores[6]}
                                helperText={errores[6] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Dirección: </Typography>
                            <TextField
                                value={direccion}
                                size='small'
                                variant='outlined'
                                fullWidth
                                multiline
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { setDireccion(e.target.value); errores[7] = false; setErrores([...errores]) }}
                                error={errores[7]}
                                helperText={errores[7] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Información adicional de dirección:</Typography>
                            <TextField
                                value={complementoDireccion}
                                size='small'
                                variant='outlined'
                                fullWidth
                                multiline
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if (value.length < 101) setComplementoDireccion(value) }}
                                style={{ marginBottom: errores[7] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Barrio: </Typography>

                            <TextField
                                value={barrio}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 101) setBarrio(e.target.value.replace(/[^a-zA-ZÀ-ú\u00f1\u00d1\d\s]+$/g, '')); errores[8] = false; setErrores([...errores]) }}
                                errores={errores[8]}
                                helperText={errores[8] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Localidad o comuna: </Typography>
                            <TextField
                                value={localidad}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 101) setLocalidad(e.target.value.replace(/[^a-zA-ZÀ-ú\u00f1\u00d1\d\s]+$/g, '')) }}
                                style={{ marginBottom: errores[8] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>
                </CardContent>
                <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                    title={
                        <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500 }}>2. Estudios Realizados</Typography>
                    }
                />
                <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Último nivel de estudios: </Typography>

                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={nivel}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: readOnly,
                                        }}
                                        style={{ marginBottom: errores[9] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={nivel}
                                        onChange={(e, v) => { setNivel(v); setArea(null); }}
                                        options={
                                            niveles.map(option => option.nivel)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[9] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Área: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={area}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: readOnly,
                                        }}
                                        error={errores[9]}
                                        helperText={errores[9] ? 'Este campo no puede quedar vacío' : null}
                                    />
                                    :
                                    <Autocomplete
                                        disabled={nivel === null}
                                        value={area}
                                        onChange={(e, v) => { setArea(v); errores[9] = false; setErrores([...errores]) }}
                                        options={
                                            (["Preescolar", "Básica Primaria (1° - 5°)", "Básica Secundaria (6° - 9°)", "Media  (10° - 13°)"].includes(nivel) ? areas.filter(m => m.nivel === nivel) : areas.filter(m => m.nivel === "Superior")).map(option => option.area)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    className: nivel === null ? classes.backgroundAutocomplete : null
                                                }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                error={errores[9]}
                                                helperText={errores[9] ? 'Este campo no puede quedar vacío' : null}
                                            />
                                        )}
                                    />
                                }
                            </React.Fragment>


                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Estado: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={estado}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: readOnly,
                                        }}
                                        style={{ marginBottom: errores[9] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={estado}
                                        onChange={(e, v) => { setEstado(v); setFechaFin(null); errores[10] = false; setErrores([...errores]) }}
                                        options={
                                            [
                                                "Culminado", "En curso", "Abandonado",
                                                "Aplazado"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[9] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de inicio: </Typography>

                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaInicio}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaInicio(date); }}
                                format="dd/MM/yyyy"
                                disabled={readOnly}
                                style={{ marginBottom: [errores[10], errores[11]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de finalización: </Typography>

                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaFin}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaFin(date); errores[10] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[10]}
                                helperText={errores[10] ? ((differenceInCalendarDays(fechaFin, fechaInicio) < 0) ? 'Fecha de finalización es anterior a la fecha de inicio' : 'Este campo no puede quedar vacío') : null}
                                disabled={readOnly || estado === null || estado !== 'Culminado'}
                                style={{ marginBottom: errores[11] === true && !errores[10] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Centro Educativo: </Typography>

                            <TextField
                                value={centroEducativo}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { setCentroEducativo(e.target.value); errores[11] = false; setErrores([...errores]) }}
                                error={errores[11]}
                                helperText={errores[11] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: errores[10] === true && !errores[11] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}>

                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Estudia actualmente?: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={estudioActual ? 'Sí' : 'No'}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: readOnly,
                                        }}
                                        style={{ marginBottom: [errores[12], errores[13]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={estudioActual ? 'Sí' : 'No'}
                                        onChange={(e, v) => { setEstudioActual(v); setModalidad(null); setHorario(null); errores[12] = false; errores[13] = false; setErrores([...errores]) }}
                                        options={
                                            [
                                                "Sí", "No"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: [errores[12], errores[13]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Modalidad: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={modalidad}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[13] === true && !errores[12] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        disabled={estudioActual === null || estudioActual === 'No'}
                                        value={modalidad}
                                        onChange={(e, v) => { setModalidad(v); errores[12] = false; setErrores([...errores]) }}
                                        options={
                                            [
                                                "Presencial", "Virtual", "Semipresencial"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                error={errores[12]}
                                                helperText={errores[12] ? 'Este campo no puede quedar vacío' : null}
                                                style={{ marginBottom: errores[13] === true && !errores[12] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                    />
                                }
                            </React.Fragment>

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Horario: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={horario}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[12] === true && !errores[13] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        disabled={estudioActual === null || estudioActual === 'No'}
                                        value={horario}
                                        onChange={(e, v) => { setHorario(v); errores[13] = false; setErrores([...errores]) }}
                                        options={
                                            horarioEstudios.map(option => option.horario)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                error={errores[13]}
                                                helperText={errores[13] ? 'Este campo no puede quedar vacío' : null}
                                                style={{ marginBottom: errores[12] === true && !errores[13] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                    />
                                }
                            </React.Fragment>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                    title={
                        <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500 }}>3. Información Personal</Typography>
                    }
                />
                <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                    <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginBottom: theme.spacing(2) }}>Historial médico:</Typography>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Le han practicado alguna cirugía?: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={cirugia ? 'Sí' : 'No'}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[14] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={cirugia ? 'Sí' : 'No'}
                                        onChange={(e, v) => { setCirugia(v); if (v === "No") { setTipoCirugia('') } }}
                                        options={
                                            [
                                                "Sí", "No"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[14] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Cuál?: </Typography>

                            <TextField
                                value={tipoCirugia}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly || cirugia === 'No' || cirugia === false,
                                }}
                                onChange={(e) => { if (e.target.value.length < 146) setTipoCirugia(e.target.value); errores[14] = false; setErrores([...errores]) }}
                                error={errores[14]}
                                helperText={errores[14] ? 'El campo no puede quedar vacío' : null}
                            />

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Actualmente toma algún medicamento?: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={medicamento ? 'Sí' : 'No'}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[15] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={medicamento ? 'Sí' : 'No'}
                                        onChange={(e, v) => { setMedicamento(v); if (v === "No") { setTipoMedicamento('') } }}
                                        options={
                                            [
                                                "Sí", "No"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[15] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                    />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Cuál?: </Typography>

                            <TextField
                                value={tipoMedicamento}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly || medicamento === 'No' || medicamento === false,
                                }}
                                error={errores[15]}
                                helperText={errores[15] ? 'Este campo no puede quedar vacío' : null}
                                onChange={(e) => { if (e.target.value.length < 146) setTipoMedicamento(e.target.value); errores[15] = false; setErrores([...errores]) }}
                            />
                        </Grid>
                    </Grid>

                    <div>
                        <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Nucleo Familiar:</Typography>
                    </div>

                    {
                        nombreFamiliar.map((v, i) => (
                            <React.Fragment key={"referencia-familiar"}>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    spacing={4}
                                >
                                    <Grid item md={4} xs={12}>
                                        <Typography variant='body1' style={{ fontWeight: 500 }}>Nombre completo: </Typography>

                                        <TextField
                                            value={nombreFamiliar[i]}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                            onChange={(e) => { nombreFamiliar[i] = e.target.value; setNombreFamiliar([...nombreFamiliar]) }}
                                        />

                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Typography variant='body1' style={{ fontWeight: 500 }}>Parentesco: </Typography>

                                        <TextField
                                            value={parentesco[i]}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                            onChange={(e) => { parentesco[i] = e.target.value; setParentesco([...parentesco]) }}
                                        />

                                    </Grid>
                                    <Grid item md={4} sm={6} xs={12}>
                                        <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono del familiar:</Typography>

                                        <TextField
                                            value={telefonoFamiliar[i]}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                            onChange={(e) => { telefonoFamiliar[i] = e.target.value; if (e.target.value.length < 11) setTelefonoFamiliar([...telefonoFamiliar]) }}
                                        />
                                    </Grid>
                                </Grid>

                            </React.Fragment>
                        ))
                    }


                    <div>
                        <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Referencias Personales:</Typography>
                    </div>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Nombre completo: </Typography>
                            <TextField
                                value={nombreReferencia}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 61) setNombreReferencia(e.target.value); errores[16] = false; setErrores([...errores]) }}
                                error={errores[16]}
                                helperText={errores[16] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[17], errores[18]].includes(true) && !errores[16] && 'xs' !== point ? theme.spacing(2.8) : null }}

                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Relación: </Typography>

                            <TextField
                                value={relacionReferencia}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 46) setRelacionReferencia(e.target.value); errores[17] = false; setErrores([...errores]) }}
                                error={errores[17]}
                                helperText={errores[17] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[16], errores[18]].includes(true) && !errores[17] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono:</Typography>

                            <TextField
                                value={telefonoReferencia}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 11) setTelefonoReferencia(e.target.value); errores[18] = false; setErrores([...errores]) }}
                                error={errores[18]}
                                helperText={errores[18] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[16], errores[17]].includes(true) && !errores[18] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Nombre completo: </Typography>

                            <TextField
                                value={nombreReferencia2}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 61) setNombreReferencia2(e.target.value); errores[19] = false; setErrores([...errores]) }}
                                error={errores[19]}
                                helperText={errores[19] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[20], errores[21]].includes(true) && !errores[19] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Relación: </Typography>

                            <TextField
                                value={relacionReferencia2}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 46) setRelacionReferencia2(e.target.value); errores[20] = false; setErrores([...errores]) }}
                                error={errores[20]}
                                helperText={errores[20] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[19], errores[21]].includes(true) && !errores[20] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono: </Typography>

                            <TextField
                                value={telefonoReferencia2}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 11) setTelefonoReferencia2(e.target.value); errores[21] = false; setErrores([...errores]) }}
                                error={errores[21]}
                                helperText={errores[21] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[19], errores[20]].includes(true) && !errores[21] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>

                    <div>
                        <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Información seguridad social:</Typography>
                    </div>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>EPS: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?
                                        <TextField
                                            value={eps}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                        />
                                        :
                                        <Autocomplete
                                            value={eps}
                                            onChange={(e, v) => { setEps(v) }}
                                            options={
                                                epss.map(option => option.eps)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                />
                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fondo de pensión: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?

                                        <TextField
                                            value={fondoPension}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                        />
                                        :
                                        <Autocomplete
                                            value={fondoPension}
                                            onChange={(e, v) => { setFondoPension(v) }}
                                            options={
                                                fondosPension.map(option => option.pension)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                />

                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Estado de afiliación: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?

                                        <TextField
                                            value={estadoAfiliacion ? 'Activo' : 'Inactivo'}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        :
                                        <Autocomplete
                                            value={estadoAfiliacion ? 'Activo' : 'Inactivo'}
                                            onChange={(e, v) => { setEstadoAfiliacion(v === 'Activo') }}
                                            options={
                                                ['Activo',
                                                    'Inactivo'].map(option => option)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                />
                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Tipo de afiliación: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?

                                        <TextField
                                            value={tipoAfiliacion}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                        />
                                        :
                                        <Autocomplete
                                            value={tipoAfiliacion}
                                            onChange={(e, v) => { setTipoAfiliacion(v) }}
                                            options={
                                                tipoAfiliaciones.map(option => option.tipo)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                />

                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>

                    </Grid>
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                    <Button
                        onClick={() => { readOnly ? setReadOnly(false) : validar() }}
                        variant='contained'
                        color={readOnly ? 'primary' : 'secondary'}
                    >
                        {readOnly ? "Editar datos" : "Guardar cambios"}
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
}

export default TusDatos;