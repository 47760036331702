import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Typography,
    Button,
    Grid,
    TextField,
    Card,
    CardContent,
    InputAdornment,
    IconButton,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import paths from '../data/paths';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#B71C1C',
    }
}));



function Login(props) {
    const { theme, validateLogin } = props;
    const inputRef = useRef();
    const classes = useStyles();
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = props.errors;
    // const recoveryPath = paths[9].path;
    const [isClick, setIsClick] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card>
            <CardContent>
                <Grid
                    container
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                    style={{ marginBottom: theme.spacing(4) }}
                >
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant='h5'>Inicio de sesión</Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='column'
                    justifyContent='center'
                >
                    <Grid item xs style={{ marginBottom: theme.spacing(2) }}>
                        <TextField
                            fullWidth
                            size='small'
                            variant='outlined'
                            label='Usuario'
                            value={user}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter')
                                    validateLogin(user, password);
                            }}
                            onChange={(e) => { setUser(e.target.value); errors[0] = false; setErrors([...errors]) }}
                            error={errors[0]}
                        />
                    </Grid>
                    <Grid item xs style={{ marginBottom: theme.spacing(4) }}>
                        <TextField
                            inputRef={inputRef}
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            size='small'
                            variant='outlined'
                            label='Contraseña'
                            value={password}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter')
                                    validateLogin(user, password);
                            }}
                            onSelect={() => { if (isClick) { inputRef.current.selectionStart = cursorPosition; setIsClick(false) } }}
                            onChange={(e) => { setPassword(e.target.value); errors[1] = false; setErrors([...errors]) }}
                            error={errors[1]}
                            InputProps={{
                                endAdornment: (
                                    < InputAdornment position='end' >
                                        <IconButton
                                            size='small'
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={(event) => { event.preventDefault(); setCursorPosition(inputRef.current.selectionStart); setIsClick(document.activeElement === inputRef.current) }}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs style={{ marginBottom: theme.spacing(2) }}>
                        <Button fullWidth={true} variant='contained' color='primary' onClick={() => validateLogin(user, password)}>Iniciar sesión</Button>
                    </Grid>
                    <Grid item xs>
                        <Link href="#" color="inherit" onClick={handleOpen}>
                        ¿Olvidaste la contraseña?
                        </Link>
                    </Grid>

                    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                        <DialogTitle>¿Olvidaste tus credenciales?</DialogTitle>
                        <DialogContent>
                        <Typography variant="body1" gutterBottom>
                            No te preocupes, aquí tienes una guía para recordarlas:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <strong>Usuario:</strong>
                            <br />
                            Se forma con:
                        </Typography>
                        <ul>
                            <li>Tu primer nombre en minúsculas.</li>
                            <li>Tu primer apellido, con la inicial en mayúscula.</li>
                            <li>Un guion bajo (_).</li>
                            <li>Los últimos 4 dígitos de tu cédula.</li>
                        </ul>
                        <Typography variant="body2" gutterBottom>
                            <strong>Contraseña:</strong>
                            <br />
                            Se forma con:
                        </Typography>
                        <ul>
                            <li>La primera letra de tu nombre en minúscula.</li>
                            <li>La primera letra de tu apellido en mayúscula.</li>
                            <li>Un guion bajo (_).</li>
                            <li>Tu número de cédula completo.</li>
                        </ul>
                        <Typography variant="body2" gutterBottom>
                            <strong>Ejemplo:</strong>
                            <br />
                            Si tu nombre es <em>Juan Antonio Perez Garcia</em>
                            <br />
                            y tu número de cédula es <em>123456789</em>, entonces:
                        </Typography>
                        <ul>
                            <li>
                            Tu <strong>usuario</strong> será: <em>juanPerez_6789</em>
                            </li>
                            <li>
                            Tu <strong>contraseña</strong> será: <em>jP_123456789</em>
                            </li>
                        </ul>
                        <Typography variant="body2">
                            Si aún tienes problemas para acceder, <strong>contáctanos</strong> para ayudarte.
                        </Typography>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cerrar
                        </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
            </CardContent>
        </Card>
    );
}

export default Login;