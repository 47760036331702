const epss = [
    // {'cod': 0, 'eps': 'ECOOPSOS -ENTIDAD COOPERATIVA SOLIDARIA DE SALUD # No presente en lista 2'} ,
    // {'cod': 1, 'eps': 'EMDISALUD ESS - EMPRESA MUTUAL DLLO INT. SALUD # No presente en lista 2'} ,
    // {'cod': 2, 'eps': 'EMSSANAR E.S.S. EMPRESA SOLIDARIA DE SALUD NARINO # No presente en lista 2'} ,
    // {'cod': 3, 'eps': 'ENTIDAD PROMOTORA DE SALUD MALLAMAS EPSI # No presente en lista 2'} ,
    // {'cod': 4, 'eps': 'EPS ALIANZA MEDELLIN ANTIOQUIA E.P.S. SAS # No presente en lista 2'} ,
    // {'cod': 5, 'eps': 'EPS COLPATRIA # No presente en lista 2'} ,
    // {'cod': 6, 'eps': 'EPS FAMISANAR LTDA # No presente en lista 2'} ,
    // {'cod': 7, 'eps': 'EPS PROGRAMA DE SALUD U DE A # No presente en lista 2'} ,
    // {'cod': 8, 'eps': 'FONDO DE PASIVO SOCIAL FERROCARRILES NACIONALES DE # No presente en lista 2'} ,
    // {'cod': 9, 'eps': 'GOLDEN GROUP S.A. ENTIDAD PROMOTORA DE SALUD # No presente en lista 2'} ,
    // {'cod': 10, 'eps': 'HUMANA VIVIR S A E P S # No presente en lista 2'} ,
    // {'cod': 11, 'eps': 'ISS E P S # No presente en lista 2'} ,
    // {'cod': 12, 'eps': 'MANEXKA - ASOC. CABILDOS INDÍG. RESGUARDO ZENÚ # No presente en lista 2'} ,
    // {'cod': 13, 'eps': 'MEDIMAS EPS S.A.S # No presente en lista 2'} ,
    // {'cod': 14, 'eps': 'MULTIMÉDICAS EPS # No presente en lista 2'} ,
    // {'cod': 15, 'eps': 'MUNICIPIO DE MEDELLIN # No presente en lista 2'} ,
    // {'cod': 16, 'eps': 'NINGUNA EPS # No presente en lista 2'} ,
    // {'cod': 17, 'eps': 'NUEVA EPS S.A. # No presente en lista 2'} ,
    // {'cod': 18, 'eps': 'PIJAOSALUD EPSI - ENTIDAD PROMOTORA DE SALUD # No presente en lista 2'} ,
    // {'cod': 19, 'eps': 'RED SALUD # No presente en lista 2'} ,
    // {'cod': 20, 'eps': 'RISARALDA E P S # No presente en lista 2'} ,
    // {'cod': 21, 'eps': 'SALUD COLOMBIA - REGIMEN CONTRIBUTIVO # No presente en lista 2'} ,
    // {'cod': 22, 'eps': 'SALUD COLPATRIA E P S # No presente en lista 2'} ,
    // {'cod': 23, 'eps': 'SALUD TOTAL S.A. ENTIDAD PROMOTORA DE SALUD # No presente en lista 2'} ,
    // {'cod': 24, 'eps': 'SALUD VIDA S.A. ENTIDAD PROMOTORA DE SALUD # No presente en lista 2'} ,
    // {'cod': 25, 'eps': 'SALUDCOOP EPS # No presente en lista 2'} ,
    // {'cod': 26, 'eps': 'SANIDAD FUERZAS MILITARES # No presente en lista 2'} ,
    // {'cod': 27, 'eps': 'SANITAS EPS - ENTIDAD PROMOTORA DE SALUD SANITAS # No presente en lista 2'} ,
    // {'cod': 28, 'eps': 'SAVIA SALUD EPS (COMFAMA) # No presente en lista 2'} ,
    // {'cod': 29, 'eps': 'SELVASALUD S A E P S # No presente en lista 2'} ,
    // {'cod': 30, 'eps': 'SERVICIO MEDICO Y ODONTOLOGICO EAAB-ESP # No presente en lista 2'} ,
    // {'cod': 31, 'eps': 'SISTEMA UNIVERSITARIO DE SALUD # No presente en lista 2'} ,
    // {'cod': 32, 'eps': 'SOLSALUD E P S # No presente en lista 2'} ,
    // {'cod': 33, 'eps': 'SOS EPS (OCCIDENTAL DE SALUD S.A) # No presente en lista 2'} ,
    // {'cod': 34, 'eps': 'SSS EMPRESAS PUBLICAS DE MED SERVICIOS MEDICOS # No presente en lista 2'} ,
    // {'cod': 35, 'eps': 'SURA EPS # No presente en lista 2'} ,
    // {'cod': 36, 'eps': 'UNIMEC EPS # No presente en lista 2'} ,
    // {'cod': 37, 'eps': 'UNISALUD # No presente en lista 2'} ,
    // {'cod': 38, 'eps': 'CAJACOPI ATLANTICO - CCF # No presente en lista 2'} ,
    // {'cod': 39, 'eps': 'A.I.C- ASOCIACIÓN INDÍGENA DEL CAUCA # No presente en lista 2'} ,
    // {'cod': 40, 'eps': 'ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A. # No presente en lista 2'} ,
    // {'cod': 41, 'eps': 'AMBUQ - ASOC. MUTUAL BARRIOS UNIDOS QUIBDÓ E.S.S. # No presente en lista 2'} ,
    // {'cod': 42, 'eps': 'ANAS WAYUU - ENTIDAD PROMOTORA DE SALUD # No presente en lista 2'} ,
    // {'cod': 43, 'eps': 'ASMET SALUD - ASOCIACIÓN MUTUAL LA ESPERANZA # No presente en lista 2'} ,
    // {'cod': 44, 'eps': 'ASOCIACIÓN MUTUAL SER EMPRESA SOLIDARIA SALUD ESS # No presente en lista 2'} ,
    // {'cod': 45, 'eps': 'BARRANQUILLA SANA EPS # No presente en lista 2'} ,
    // {'cod': 46, 'eps': 'CAFESALUD EPS # No presente en lista 2'} ,
    // {'cod': 47, 'eps': 'CAJA DE COMPENSACIÓN FAMILIAR CAFAM EPS # No presente en lista 2'} ,
    // {'cod': 48, 'eps': 'CAJANAL EPS # No presente en lista 2'} ,
    // {'cod': 49, 'eps': 'CALISALUD EPS # No presente en lista 2'} ,
    // {'cod': 50, 'eps': 'CAPITAL SALUD EPSS S.A.S. # No presente en lista 2'} ,
    // {'cod': 51, 'eps': 'CAPRECOM - CAJA DE PREVISIÓN SOCIAL DE COMUNIC EPS # No presente en lista 2'} ,
    // {'cod': 52, 'eps': 'CAPRESOCA EPS # No presente en lista 2'} ,
    // {'cod': 53, 'eps': 'COLSEGUROS EPS # No presente en lista 2'} ,
    // {'cod': 54, 'eps': 'COLSUBSIDIO EPS - CAJA CBIANA DE SUBSIDIO FAMILIAR # No presente en lista 2'} ,
    // {'cod': 55, 'eps': 'COMFABOY EPS-CCF DE BOYACÁ # No presente en lista 2'} ,
    // {'cod': 56, 'eps': 'COMFACHOCO-CCF DEL CHOCÓ # No presente en lista 2'} ,
    // {'cod': 57, 'eps': 'COMFACOR EPS-CCF DE CÓRDOBA # No presente en lista 2'} ,
    // {'cod': 58, 'eps': 'COMFACUNDI - CCF DE CUNDINAMARCA # No presente en lista 2'} ,
    // {'cod': 59, 'eps': 'COMFAMILIAR CARTAGENA # No presente en lista 2'} ,
    // {'cod': 60, 'eps': 'COMFAMILIAR DE LA GUAJIRA EPS-CCF # No presente en lista 2'} ,
    // {'cod': 61, 'eps': 'COMFAMILIAR HUILA EPS-CCF # No presente en lista 2'} ,
    // {'cod': 62, 'eps': 'COMFAMILIAR NARIÑO EPS-CCF # No presente en lista 2'} ,
    // {'cod': 63, 'eps': 'COMFASUCRE EPS-CCF DE SUCRE # No presente en lista 2'} ,
    // {'cod': 64, 'eps': 'COMFENALCO ANTIOQUIA EPS # No presente en lista 2'} ,
    // {'cod': 65, 'eps': 'COMFENALCO QUINDIO # No presente en lista 2'} ,
    // {'cod': 66, 'eps': 'COMFENALCO VALLE EPS # No presente en lista 2'} ,
    // {'cod': 67, 'eps': 'COMPARTA - COOPERATIVA DE SALUD COMUNITARIA # No presente en lista 2'} ,
    // {'cod': 68, 'eps': 'COMPENSAR ENTIDAD PROMOTORA DE SALUD # No presente en lista 2'} ,
    // {'cod': 69, 'eps': 'CONSORCIO FISALUD (FOSYGA) # No presente en lista 2'} ,
    // {'cod': 70, 'eps': 'CONVIDA - ENTIDAD ADM DE RÉGIMEN SUBSIDIADO # No presente en lista 2'} ,
    // {'cod': 71, 'eps': 'COOMEVA EPS. S.A. # No presente en lista 2'} ,
    // {'cod': 72, 'eps': 'COOSALUD E.S.S. COOP.SALUD Y DLLO INT. CARTAGENA # No presente en lista 2'} ,
    // {'cod': 73, 'eps': 'CRUZ BLANCA ENTIDAD PROMOTORA DE SALUD S.A. # No presente en lista 2'} ,
    // {'cod': 74, 'eps': 'DUSAKAWI- ASOCIACIÓN CABILDOS INDÍGENAS DEL CESAR # No presente en lista 2'} ,
    // {'cod': 75, 'eps': 'EPS CONDOR SA # No presente en lista 2'} ,
    // {'cod': 76, 'eps': 'EPS DE CALDAS SA # No presente en lista 2'} ,
    // {'cod': 77, 'eps': 'SALUD MIA # No presente en lista 2'} ,
    // {'cod': 78, 'eps': 'ECOPETROL # No presente en lista 2'} ,
    // {'cod': 79, 'eps': 'SERVISALUD # No presente en lista 2'} ,
    // {'cod': 80, 'eps': 'RED VITAL # No presente en lista 2'} ,
    // {'cod': 81, 'eps': 'COSMITET LTDA # No presente en lista 2'} ,
    // {'cod': 82, 'eps': 'DIRECCIÓN DE SANIDAD DE LA POLICÍA NACIONAL - DISAN # No presente en lista 2'} ,
    // {'cod': 83, 'eps': 'PROINSALUD S.A. # No presente en lista 2'} ,
    {'cod': 84, 'eps': 'COMFAORIENTE'} ,
    {'cod': 85, 'eps': 'EMPRESAS PÚBLICAS DE MEDELLÍN DEPARTAMENTO MÉDICO'} ,
    {'cod': 86, 'eps': 'FONDO DE FERROCARRILES NACIONALES DE COLOMBIA (EPS)'} ,
    {'cod': 87, 'eps': 'ALIANSALUD EPS (ANTES COLMÉDICA)'} ,
    {'cod': 88, 'eps': 'SALUD TOTAL'} ,
    {'cod': 89, 'eps': 'SANITAS'} ,
    {'cod': 90, 'eps': 'COMPENSAR'} ,
    {'cod': 91, 'eps': 'EPS SURA'} ,
    {'cod': 92, 'eps': 'COMFENALCO VALLE'} ,
    {'cod': 93, 'eps': 'FAMISANAR'} ,
    {'cod': 94, 'eps': 'S.O.S. SERVICIO OCCIDENTAL DE SALUD S.A.'} ,
    {'cod': 95, 'eps': 'NUEVA E.P.S.'} ,
    {'cod': 96, 'eps': 'SAVIA SALUD'} ,
    {'cod': 97, 'eps': 'NUEVA EPS MOVILIDAD'} ,
    {'cod': 98, 'eps': 'COOSALUD EPS'} ,
    {'cod': 99, 'eps': 'SALUD MIA EPS'} ,
    // {'cod': 100, 'eps': 'EPS MUTUAL SER'} ,
    {'cod': 101, 'eps': 'FOSYGA'} ,
    {'cod': 102, 'eps': 'FOSYGA RÉGIMEN DE EXCEPCIÓN'} ,
    {'cod': 103, 'eps': 'FOSYGA RESIDENTE EXTERIOR O RÉGIMEN SUBSIDIADO'} ,
    {'cod': 104, 'eps': 'CONVIDA'} ,
    {'cod': 105, 'eps': 'CAPRESOCA'} ,
    {'cod': 106, 'eps': 'CAPITAL SALUD'} ,
    {'cod': 107, 'eps': 'DUSAKAWI'} ,
    {'cod': 108, 'eps': 'MANEXKA'} ,
    {'cod': 109, 'eps': 'A.I.C.'} ,
    {'cod': 110, 'eps': 'ANAS WAYUU'} ,
    {'cod': 111, 'eps': 'MALLAMAS'} ,
    {'cod': 112, 'eps': 'PIJAOSALUD'} ,
    {'cod': 113, 'eps': 'MUTUAL SER'} ,
    {'cod': 114, 'eps': 'EMSSANAR'} ,
    {'cod': 115, 'eps': 'COOSALUD MOVILIDAD'} ,
    {'cod': 116, 'eps': 'COMPARTA'} ,
    {'cod': 117, 'eps': 'ASMET SALUD'} ,
    {'cod': 118, 'eps': 'SALUD BOLIVAR EPS'} ,
    {'cod': 119, 'eps': 'COMFACHOCO'} ,
    {'cod': 120, 'eps': 'FAMILIAR DE COLOMBIA'} ,
    {'cod': 121, 'eps': 'CAJACOPI EPS S.A.S.'} ,
    {'cod': 122, 'eps': 'UNIVERSIDAD DEL ATLÁNTICO'} ,
    {'cod': 123, 'eps': 'UNIVERSIDAD INDUSTRIAL DE SANTANDER'} ,
    {'cod': 124, 'eps': 'UNIVERSIDAD DEL VALLE'} ,
    {'cod': 125, 'eps': 'UNIVERSIDAD NACIONAL DE COLOMBIA'} ,
    {'cod': 126, 'eps': 'UNIVERSIDAD DEL CAUCA'} ,
    {'cod': 127, 'eps': 'UNIVERSIDAD DE CARTAGENA'} ,
    {'cod': 128, 'eps': 'UNIVERSIDAD DE ANTIOQUIA'} ,
    {'cod': 129, 'eps': 'UNIVERSIDAD DE CÓRDOBA'} ,
    {'cod': 130, 'eps': 'UNIVERSIDAD DE NARIÑO'} ,
    {'cod': 131, 'eps': 'UNIVERSIDAD PEDAGÓGICA - UPTC'} ,
];
export default epss;