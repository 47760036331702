import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    CardHeader,
    CardActions,
    CardMedia,
    Button,
    Typography,
    Grid,
    Divider,
    Container
} from '@material-ui/core';
import { Route, Link } from "react-router-dom";

import PeopleCard from '../../styles/images/people_card.jpg';
import ArchivoCard from '../../styles/images/archivo_card.jpg';
import ContractCard from '../../styles/images/contract_card.jpg';
import CourseCard from '../../styles/images/course_card.jpg';
import CalendarCard from '../../styles/images/calendar_card.jpg'
import FormatCard from '../../styles/images/format_card.jpg'
import PhotoCard from '../../styles/images/photo_card.jpg'
import Postulaciones from './components/Postulaciones'
import Documentos from './components/Documentos'
import ContratoAbierto from './components/ContratoAbierto'
import ContratoAceptado from './components/ContratoAceptado'
import Calendario from './components/Calendario';
import TusDatos from './components/TusDatos';
import DialogoFotoPerfil from './components/DialogoFotoPerfil';
import FormatosResponsabilidad from './components/FormatosResponsabilidad';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 345,
        margin: '8px'
    },
    media: {
        height: 140,
    },
    logo: {
        width: "auto",
        height: 140,
    }
}));

function GeneralSeleccion(props) {
    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        getRole,
        validRole,
        paths,
        setShowBackdrop,
        setMessage,
        setSnack,
        setSeverity,
    } = props;
    const [username, setUsername] = props.username;
    const [userImage, setUserImage] = props.userImage;

    const [accessToken, setAccessToken] = props.accessToken;
    const classes = useStyles();
    const [nombre, setNombre] = useState('');
    const [cedula, setCedula] = useState('');
    const [ciudadResidencia, setCiudadResidencia] = useState('');
    const [archivos, setArchivos] = useState(Array(5).fill(null));
    const [formatos, setFormatos] = useState([]);
    const [revisionDocs, setRevisionDocs] = useState(Array(5).fill(''));
    const [fechaRevisionDocs, setFechaRevisionDocs] = useState(Array(5).fill(''));
    const [fechaCreadoDocs, setFechaCreadoDocs] = useState(Array(5).fill(''));
    const [fechaModDocs, setFechaModDocs] = useState(Array(5).fill(''));
    const [minArchivos, setMinArchivos] = useState(false);
    const hiddenInputs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [hayContrato, setHayContrato] = useState(false);
    const [hayFormatos, setHayFormatos] = useState(false);
    const [estadoContratacion, setEstadoContratacion] = useState('');
    const [correo, setCorreo] = useState('');
    const [estadoDocumentos, setEstadoDocumentos] = useState('');
    const [carnet, setCarnet] = useState(null);
    const [contrasenaRUT, setContrasenaRUT] = useState('');
    const [openDialogoFotoPerfil, setOpenDialogoFotoPerfil] = useState(false);

    async function fetchArchivos(access = accessToken) {
        const res = await fetch(`${API_DEFAULT}/seleccion/docs_soporte/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` }
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchArchivos(newAccess);
                }
            }
            else {
                for (let i = 0; i < res.length; i++) {
                    archivos[res[i].tipo_documento] = { 'name': res[i].archivo.split('https://storage.googleapis.com/sistema-cnc/')[1], 'url': res[i].archivo };
                    setArchivos([...archivos]);
                    if (res[i].tipo_documento === 1) {
                        setMinArchivos(true);
                    }
                    revisionDocs[res[i].tipo_documento] = res[i].aprobado;
                    setRevisionDocs([...revisionDocs]);
                    fechaRevisionDocs[res[i].tipo_documento] = res[i].fecha_mod
                    setFechaRevisionDocs([...fechaRevisionDocs])
                    fechaCreadoDocs[res[i].tipo_documento] = res[i].fecha_creado
                    setFechaCreadoDocs([...fechaCreadoDocs])
                    fechaModDocs[res[i].tipo_documento] = res[i].fecha_mod_file
                    setFechaModDocs([...fechaModDocs])
                    if (res[i].tipo_documento === 3) {
                        setContrasenaRUT(res[i].password);
                    }
                }
            }
        })
    }

    async function fetchDatosBasicos(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/basicos_persona/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchDatosBasicos(newAccess);
                }
            }
            else {
                setShowBackdrop(false);
                setNombre(res.nombre);
                setCedula(res.documento);
                localStorage.setItem('cedula', res['documento']);
                setCiudadResidencia(res.ciudad_residencia);
                setEstadoContratacion(res.estado_contratacion);
                setCorreo(res.correo);
                setEstadoDocumentos(res.aprobado_documentos);
                setCarnet(res.carnet);
                fetchFormatosResponsabilidad(res.documento);
                if (res.estado_contratacion.includes('Apto')) {
                    setHayContrato(true);
                }
            }
        });
    }

    async function fetchFormatosResponsabilidad(cedula, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/formato_responsabilidad/?cedula=${cedula}`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` }
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);
                    fetchFormatosResponsabilidad(cedula, newAccess);
                }
            }
            else {
                setShowBackdrop(false);
                setFormatos(res);
                setHayFormatos(res.filter(r => r.firma === false).length > 0);
            }
        });
    }

    const CardContratacion = () => {
        return (
            <Card className={classes.root}>
                <CardMedia
                    className={classes.logo}
                    image={ContractCard}
                    title="Contratos"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        ¡Tienes un contrato disponible por firmar!
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Has sido seleccionado para uno de nuestros perfiles, firma el contrato para vincularte con nosotros.
                    </Typography>
                </CardContent>

                <CardActions>
                    <Button component={Link} to={paths[3].path} size="small" color="primary">
                        Ir a contratos
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const CardDocumentos = () => {
        return (
            <Card className={classes.root}>
                <CardMedia
                    className={classes.logo}
                    image={ArchivoCard}
                    title="Documentos"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Documentos
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Tus postulaciones no serán consideradas si no has subido los documentos requeridos. Entra aquí y administra tus documentos.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button component={Link} to={paths[2].path} size="small" color="primary">
                        Ir a mis documentos
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const CardPostulaciones = () => {
        return (
            <Card className={classes.root} >
                <CardMedia
                    className={classes.logo}
                    image={PeopleCard}
                    title="Postulaciones"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Postulaciones y perfiles
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Descubre los perfiles disponibles, llena los formatos correspondientes y postúlate. También puedes ver tus postulaciones anteriores.
                    </Typography>
                </CardContent>

                <CardActions>
                    <Button component={Link} to={paths[1].path} size="small" color="primary">
                        Ir a postulaciones
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const CardContratos = () => {
        return (
            <Card className={classes.root}>
                <CardMedia
                    className={classes.logo}
                    image={ContractCard}
                    title="Contratos"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Contratos
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Revisa los contratos que has firmado.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button component={Link} to={paths[4].path} size="small" color="primary">
                        Ir a contratos
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const CardPerfil = () => {
        return (
            <Card className={classes.root}>
                <CardMedia
                    className={classes.logo}
                    image={PhotoCard}
                    title="Fotografía"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Perfil
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Actualiza tu fotografía. Esta se usará para la generación de elementos que te identifiquen como parte del CNC.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" color="primary"
                        onClick={() => { setOpenDialogoFotoPerfil(true) }}
                    >
                        Actualizar Fotografía
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const CardCalendario = () => {
        return (
            <Card className={classes.root}>
                <CardMedia
                    className={classes.logo}
                    image={CalendarCard}
                    title="Calendario"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Mi calendario de actividades
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Visualiza el calendario de actividades en las que has participado.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button component={Link} to={paths[5].path} size="small" color="primary">
                        Ir a calendario
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const CardCursoBasico = () => {
        return (
            <Card className={classes.root}>
                <CardMedia
                    className={classes.logo}
                    image={CourseCard}
                    title="Curso básico"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Curso básico
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Ingresa al link del examen de curso básico.
                        <br />
                        <br />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={() => { window.open(paths[9].path, '_blank') }} size="small" color="primary">
                        Ir al cuestionario
                    </Button>
                </CardActions>
            </Card>
        );
    };

    const CardFormatosResponsabilidad = () => {
        return (
            <Card className={classes.root}>
                <CardMedia
                    className={classes.logo}
                    image={FormatCard}
                    title="Formatos de responsabilidad"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Formato de responsabilidad
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Firma el formato para poder recibir los elementos para realizar tus labores.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button component={Link} to={paths[10].path} size="small" color="primary">
                        Ir a Formatos de responsabilidad
                    </Button>
                </CardActions>
            </Card>
        );
    };

    useEffect(() => { if (validRole) { fetchDatosBasicos(); fetchArchivos(); } }, [validRole]);  // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <Route
                exact
                path={paths[0].path}
                render={() => (
                    <Container component='main' maxWidth='md'>
                        <Card>
                            <CardHeader
                                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                                title={
                                    <Typography variant='h6' align='center'>¡Hola {nombre}! Bienvenido al Aplicativo de Selección </Typography>
                                }
                            />
                            <Divider />
                            <CardContent>
                                {
                                    hayContrato ?
                                        <React.Fragment>
                                            <Grid
                                                container
                                                spacing={2}
                                                direction='row'
                                                justifyContent='center'
                                            >
                                                <Grid item sm={7} xs={12}>
                                                    <CardContratacion />
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                        :
                                        null
                                }
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    {
                                        localStorage.getItem("roles").includes("Persona seleccion - Laboral") ? (
                                        // localStorage.getItem("roles").includes("Persona seleccion - Laboral") ? (
                                            <Grid item sm={6} xs={12}>
                                                <CardDocumentos />
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid item sm={6} xs={12}>
                                                    <CardDocumentos />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <CardPostulaciones />
                                                </Grid>
                                                {estadoContratacion.includes('Contratado') &&
                                                    <Grid item sm={6} xs={12}>
                                                        <CardContratos />
                                                    </Grid>
                                                }
                                                <Grid item sm={6} xs={12}>
                                                    <CardPerfil />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <CardCalendario />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <CardCursoBasico />
                                                </Grid>
                                                {hayFormatos &&
                                                    <Grid item sm={6} xs={12}>
                                                        <CardFormatosResponsabilidad />
                                                    </Grid>
                                                }
                                            </>
                                        )
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Container>
                )}
            />
            <Route
                exact
                path={paths[1].path}
                render={() => (
                    <Container component='main' maxWidth='md'>
                        <Postulaciones
                            theme={theme}
                            API_DEFAULT={API_DEFAULT}
                            accessToken={[accessToken, setAccessToken]}
                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            setSnack={setSnack}
                            setMessage={setMessage}
                            setSeverity={setSeverity}
                            setShowBackdrop={setShowBackdrop}
                            paths={paths}
                            archivos={archivos}
                        />
                    </Container>
                )}
            />

            <Route
                exact
                path={paths[2].path}
                render={() => (
                    <Container component='main' maxWidth='md'>
                        <Documentos
                            theme={theme}
                            API_DEFAULT={API_DEFAULT}
                            accessToken={[accessToken, setAccessToken]}
                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            setSnack={setSnack}
                            setMessage={setMessage}
                            setSeverity={setSeverity}
                            setShowBackdrop={setShowBackdrop}
                            fetchArchivos={fetchArchivos}
                            paths={paths}
                            archivos={[archivos, setArchivos]}
                            revisionDocs={revisionDocs}
                            fechaRevisionDocs={fechaRevisionDocs}
                            fechaCreadoDocs={fechaCreadoDocs}
                            fechaModDocs={fechaModDocs}
                            estadoAprobacion={estadoDocumentos}
                            minArchivos={[minArchivos, setMinArchivos]}
                            contrasenaRUT={[contrasenaRUT, setContrasenaRUT]}
                            carnet={carnet}
                            estadoContratacion={estadoContratacion}
                            hiddenInputs={hiddenInputs}
                            cedula={cedula}
                        />
                    </Container>
                )}
            />
            <Route
                exact
                path={paths[3].path}
                render={() => (
                    <Container component='main' maxWidth='md'>
                        <ContratoAbierto
                            theme={theme}
                            API_DEFAULT={API_DEFAULT}
                            accessToken={[accessToken, setAccessToken]}
                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            setSnack={setSnack}
                            setMessage={setMessage}
                            setSeverity={setSeverity}
                            setShowBackdrop={setShowBackdrop}
                            paths={paths}
                            cedula={cedula}
                            nombre={nombre}
                            ciudadResidencia={ciudadResidencia}
                            correo={correo}
                            estadoContratacion={estadoContratacion}
                        />
                    </Container>
                )}
            />
            <Route
                exact
                path={paths[4].path}
                render={() => (
                    <Container component='main' maxWidth='md'>
                        <ContratoAceptado
                            theme={theme}
                            API_DEFAULT={API_DEFAULT}
                            accessToken={[accessToken, setAccessToken]}
                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            setSnack={setSnack}
                            setMessage={setMessage}
                            setSeverity={setSeverity}
                            setShowBackdrop={setShowBackdrop}
                            paths={paths}
                            cedula={cedula}
                            nombre={nombre}
                            ciudadResidencia={ciudadResidencia}
                            estadoContratacion={estadoContratacion}
                        />
                    </Container>
                )}
            />
            <Route
                exact
                path={paths[5].path}
                render={() => (
                    <Container component='main' maxWidth='md'>
                        <Calendario
                            theme={theme}
                            API_DEFAULT={API_DEFAULT}
                            accessToken={[accessToken, setAccessToken]}
                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            setSnack={setSnack}
                            setMessage={setMessage}
                            setSeverity={setSeverity}
                            setShowBackdrop={setShowBackdrop}
                            paths={paths}
                            cedula={cedula}
                            estado={estadoContratacion}
                        />
                    </Container>
                )}
            />
            <Route
                exact
                path={paths[6].path}
                render={() => (
                    <TusDatos
                        theme={theme}
                        classes={classes}
                        validRole={validRole}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                        setSnack={setSnack}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        setShowBackdrop={setShowBackdrop}
                        paths={paths}
                        cedula={cedula}
                    />
                )}
            />
            <Route
                exact
                path={paths[10].path}
                render={() => (
                    <FormatosResponsabilidad
                        theme={theme}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                        setSnack={setSnack}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        setShowBackdrop={setShowBackdrop}
                        paths={paths}
                        cedula={cedula}
                        nombre={nombre}
                        ciudadResidencia={ciudadResidencia}
                        correo={correo}
                        formatos={formatos}
                        fetchFormatosResponsabilidad={fetchFormatosResponsabilidad}
                    />
                )}
            />
            {openDialogoFotoPerfil &&
                <DialogoFotoPerfil
                    API_DEFAULT={API_DEFAULT}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getRole={getRole}
                    setSnack={setSnack}
                    setMessage={setMessage}
                    setSeverity={setSeverity}
                    setShowBackdrop={setShowBackdrop}
                    validRole={validRole}
                    accessToken={[accessToken, setAccessToken]}
                    openDialogoFotoPerfil={[openDialogoFotoPerfil, setOpenDialogoFotoPerfil]}
                    username={[username, setUsername]}
                    userImage={[userImage, setUserImage]}
                    theme={theme}
                />
            }
        </div>
    )
};

export default GeneralSeleccion


