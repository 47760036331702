import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Typography, Divider, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Sesion from './Sesion';

function Home(props) {
    const { theme,
        // setMessage, 
        // setSeverity, 
        // setSnack, 
        path,
        validateSession
    } = props;
    const [erroresSesion, setErroresSesion] = useState(Array(2).fill(false));

    return (
        <Card>
            <CardHeader
                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                title={
                    <Typography variant='h5' align='center' style={{ fontWeight: 500 }}>Bienvenido al aplicativo de selección</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={6} xs={12} >
                        <div style={{ margin: theme.spacing(2) }}>
                            <Sesion
                                theme={theme}
                                errors={[erroresSesion, setErroresSesion]}
                                validateLogin={validateSession}
                            />
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div style={{ margin: theme.spacing(2) }}>
                            <Typography variant='h5'>¿Aún no tienes cuenta?</Typography>
                            <Typography variant='body1' style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(4) }}>Dirígete al siguiente enlace y llena los datos para registrarte en nuestro aplicativo.</Typography>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button component={Link} variant='contained' color='primary' to={path.path}>Ingresa tus datos</Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default Home;