import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Divider,
    CardActions,
    Collapse,
    Button,
    FormControlLabel,
    Checkbox,
    Link
} from '@material-ui/core';

function Disclaimer(props) {
    const { theme, disclaimer } = props;
    const [acepta, setAcepta] = props.acepta;
    const [errores, setErrores] = props.errores;
    const completado = props.datosCompletos;


    return (
        <Card style={{ marginBottom: theme.spacing(1) }}>
            {/* Implementar esto https://css-tricks.com/text-fade-read-more/ */}
            <Collapse in={completado[3] === true} mountOnEnter unmountOnExit>
                <CardContent>
                    <Typography variant='body1' align='center'><span style={{ fontWeight: 500 }}>AUTORIZACIÓN DE TRATAMIENTO DE DATOS PERSONALES EN PROCESO DE SELECCIÓN</span></Typography>
                    <Typography style={{ marginTop: theme.spacing(1), marginLeft: theme.spacing(4), marginRight: theme.spacing(4), display: 'inline-block' }} variant='body2' align='justify'>
                        De acuerdo con la Ley Estatutaria 1581 de 2012 de Protección de Datos y con el Decreto 1377 de 2013 y las normas que lo modifique, derogue, o subrogue autorizo expresamente, como Titular de los datos, que éstos sean incorporados en una base de datos de responsabilidad del <Link color='inherit' href='https://www.centronacionaldeconsultoria.com/'>Centro Nacional de Consultoría S.A. (CNC)</Link> NIT 800.011.951-9, para que sean tratados con la finalidad de:
                        <br></br>
                        <br></br>
                        Evaluar mis competencias, capacidades, formación, experiencia, entre otros aspectos propios del proceso de selección en el que participo, o en cumplimiento de una obligación, legal, contractual o convencional. La información suministrada será incluida dentro de la base de datos del Centro Nacional de Consultoría con los siguientes datos: nombre y apellidos, lugar de residencia, cédula y teléfono, las cuales se garantizan la seriedad y confidencialidad de la información proporcionada, permaneciendo en ellas durante todo el proceso de selección o con posterioridad al mismo cuando así lo requiera la Empresa, sin perjuicio de mis derechos contenidos la Ley 1581 de 2012 y las normas que lo modifique, derogue, o subrogue.
                        <br></br>
                        <br></br>
                        Manifiesto que la información suministrada es veraz y también autorizo al Centro Nacional de Consultoría para que pueda confirmarla y verificarla, igualmente para que pueda contactar a mis referencias personales, comerciales, laborales y académicas con éste objetivo, así como autorizo a realizar consultas en centrales de información de riesgo, bancos de datos o las listas emitidas por las diferentes entidades privadas y/o gubernamentales, nacionales o extranjeras que considere necesarias para validar toda la información suministrada. Igualmente autorizo a la Empresa a realizar visita domiciliaria y estudio de seguridad, en el evento de considerarlas necesarias también para validar la información que suministro bajo mi exclusiva responsabilidad.
                        <br></br>
                        <br></br>
                        De igual modo, como Titular, quedo informado que, en caso de tratarse de datos de carácter sensible, no estoy obligado a autorizar dicho tratamiento; y además, puedo ejercer los derechos de acceso, corrección, supresión, revocación o reclamo por infracción sobre mis datos, mediante escrito dirigido a Centro Nacional de Consultoría S.A. NIT 800.011.951-9, a la dirección de correo electrónico <Link color='inherit' href="mailto:cnc@cnccol.com">cnc@cnccol.com</Link>, indicando en el asunto el derecho que desea ejercer, o mediante correo ordinario remitido a la Calle 82 # 6 -51-Bogotá DC.

                    </Typography>
                    <FormControlLabel
                        style={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(4) }}
                        control={<Checkbox color='primary' checked={acepta} onChange={() => { setAcepta(!acepta); errores[0] = false; setErrores([...errores]) }} />}
                        label={<Typography style={{ color: errores[0] ? '#e53935' : null }} variant='body1'>Autorizo el tratamiento de mis datos personales.</Typography>}
                    />
                </CardContent>
                <Divider style={{ marginBottom: theme.spacing(1) }} />
                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                    <Button
                        disabled={acepta === false}
                        onClick={() => disclaimer(acepta)}
                        variant='contained'
                        color='primary'
                    >
                        Registrar
                    </Button>
                </CardActions>
            </Collapse>
        </Card>
    );
}

export default Disclaimer;