const horarioEstudios = [
    {horario: "Medio día en la mañana", cod: 0},
    {horario: "Medio día en la tarde", cod: 1},
    {horario: "De 6:00 p.m. en adelante", cod: 2},
    {horario: "Todo el día (De lunes a viernes)", cod: 3},
    {horario:  "Solo sabado", cod: 4},
    {horario: "Solo domingo", cod: 5},
    {horario: "Fin de semana completo", cod: 6},
    {horario: "Modalidad virtual", cod: 7}
];

export default horarioEstudios;