import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  Button,
  CardActions,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Route } from "react-router-dom";
import CaracteristicasContrato from "./CaracteristicasContrato";
import DialogoRechazarContrato from "./DialogoRechazarContrato";
import Bioseguridad from "../../../data/Bioseguridad.pdf";
import estadosContratacion from "../../../data/estadosContratacion";

function ContratoAbierto(props) {
  const {
    theme,
    API_DEFAULT,
    getAccessTokenWithRefresh,
    setMessage,
    setSnack,
    setSeverity,
    setShowBackdrop,
    paths,
    cedula,
    nombre,
    ciudadResidencia,
    correo,
    estadoContratacion,
  } = props;
  const [accessToken, setAccessToken] = props.accessToken;
  const [errores, setErrores] = useState(Array(2).fill(false));
  const [archivoContrato, setArchivoContrato] = useState("");
  const [archivoComodato, setArchivoComodato] = useState("");
  const [aceptaContrato, setAceptaContrato] = useState(false);
  const [aceptaComodato, setAceptaComodato] = useState(false);
  const [openRechazar, setOpenRechazar] = useState(false);

  const validar = () => {
    let erroresAceptacion = false;
    if (aceptaContrato === false) {
      erroresAceptacion = true;
      errores[0] = true;
    }
    if (aceptaComodato === false) {
      erroresAceptacion = true;
      errores[1] = true;
    }

    if (erroresAceptacion) {
      setErrores([...errores]);
      setMessage("Debes aceptar la firma del contrato y el comodato.");
      setSeverity("error");
      setTimeout(() => {
        setSnack(true);
      }, 0);
    } else {
      firmarContrato();
    }
  };

  async function fetchContrato(access = accessToken) {
    setShowBackdrop(true);
    const res = await fetch(
      `${API_DEFAULT}/seleccion/contratos/persona_seleccion=${cedula}&perfil=${
        estadosContratacion.filter((e) => e.estado === estadoContratacion)[0]
          .cargo_proceso
      }/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      }
    );
    res.json().then(async (res) => {
      if (res["code"] === "token_not_valid") {
        let newAccess = await getAccessTokenWithRefresh();
        if (newAccess) {
          setAccessToken(newAccess);
          localStorage.setItem("access", newAccess);

          fetchContrato(newAccess);
        }
      } else {
        setShowBackdrop(false);
        setArchivoContrato(res.contrato);
        setArchivoComodato(res.comodato);
      }
    });
  }

  async function actualizarContratacion(access = accessToken) {
    setShowBackdrop(true);
    const res = await fetch(`${API_DEFAULT}/seleccion/basicos_persona/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({
        estado_contratacion: estadosContratacion.filter(
          (e) =>
            e.cargo_contratado ===
            estadosContratacion.filter(
              (e) => e.estado === estadoContratacion
            )[0].cargo_proceso
        )[0].cod,
      }),
    });
    if (res.ok) {
      setMessage("¡Has firmado contrato!");
      setSeverity("success");
      setTimeout(() => {
        setSnack(true);
      }, 0);
      alert("Has firmado contrato.");
      window.location.href = paths[0].path;
      setShowBackdrop(false);
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            localStorage.setItem("access", newAccess);

            actualizarContratacion(newAccess);
          }
        } else {
          setShowBackdrop(false);
          setMessage("Ocurrió un error, intente de nuevo.");
          setSeverity("error");
          setTimeout(() => {
            setSnack(true);
          }, 0);
        }
      });
    }
  }

  async function firmarContrato(access = accessToken) {
    setShowBackdrop(true);
    const res = await fetch(
      `${API_DEFAULT}/seleccion/contratos/persona_seleccion=${cedula}&perfil=${
        estadosContratacion.filter((e) => e.estado === estadoContratacion)[0]
          .cargo_proceso
      }/`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          nombre: nombre,
          correo: correo,
        }),
      }
    );
    if (res.ok) {
      actualizarContratacion();
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            localStorage.setItem("access", newAccess);

            firmarContrato(newAccess);
          }
        } else {
          // setShowBackdrop(false);
          setMessage("Ocurrió un error, intente de nuevo.");
          setSeverity("error");
          setTimeout(() => {
            setSnack(true);
          }, 0);
          setShowBackdrop(false);
        }
      });
    }
  }
  useEffect(() => {
    if (estadoContratacion.includes("Apto")) {
      fetchContrato();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Route
      exact
      path={paths[3].path}
      render={() => (
        <Card>
          <CardHeader
            style={{
              paddingTop: theme.spacing(1.5),
              paddingBottom: theme.spacing(1),
            }}
            title={
              <Typography
                variant="h6"
                style={{ fontWeight: 500, textAlign: "center" }}
              >
                Contratación{" "}
              </Typography>
            }
          />

          <CardContent>
            <CaracteristicasContrato
              theme={theme}
              nombre={nombre}
              ciudadResidencia={ciudadResidencia}
            />
            <Typography
              variant="h6"
              style={{
                marginLeft: theme.spacing(4),
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                textAlign: "center",
              }}
            >
              A continuación encontrarás tu contrato:
            </Typography>
            <div align="center">
              <iframe
                title="Contrato"
                width="700"
                height="600"
                src={`${archivoContrato}`}
              ></iframe>
            </div>
            <Grid container>
              <Grid item xs={12} md={12} align="left">
                <Typography
                  style={{
                    marginTop: theme.spacing(2),
                    marginLeft: theme.spacing(4),
                  }}
                >
                  Nuestros contratos se firman digitalmente al seleccionar la
                  siguiente casilla. ¿Acepta la firma del contrato?
                </Typography>
                <FormControlLabel
                  style={{ marginLeft: theme.spacing(4) }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={aceptaContrato}
                      onChange={() => {
                        setAceptaContrato(!aceptaContrato);
                        errores[0] = false;
                        setErrores([...errores]);
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{ color: errores[0] ? "#e53935" : null }}
                      variant="body1"
                    >
                      Firmar contrato
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Divider
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(2),
              }}
            />

            <Typography
              variant="h6"
              style={{
                marginLeft: theme.spacing(4),
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                textAlign: "center",
              }}
            >
              A continuación encontrarás el comodato:
            </Typography>
            <div align="center">
              <iframe
                title="Contrato"
                width="700"
                height="600"
                src={`${archivoComodato}`}
              ></iframe>
            </div>
            <Grid container>
              <Grid item xs={12} md={12} align="left">
                <Typography
                  style={{
                    marginTop: theme.spacing(2),
                    marginLeft: theme.spacing(4),
                  }}
                >
                  Igualmente, el comodato se firma digitalmente al seleccionar
                  la siguiente casilla. ¿Acepta la firma del comodato?
                </Typography>
                <FormControlLabel
                  style={{ marginLeft: theme.spacing(4) }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={aceptaComodato}
                      onChange={() => {
                        setAceptaComodato(!aceptaComodato);
                        errores[1] = false;
                        setErrores([...errores]);
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{ color: errores[1] ? "#e53935" : null }}
                      variant="body1"
                    >
                      Firmar comodato
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(2),
            }}
          />

          {/* <Typography variant='h6' style={{ marginLeft: theme.spacing(4), marginTop: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: 'center' }}>Finalmente, ten presente nuestros protocolos de bioseguridad:</Typography>
                    <div align="center">
                        <iframe
                            title="Contrato"
                            width="700"
                            height="600"
                            src={`${Bioseguridad}`}>
                        </iframe>
                    </div> */}
          {/* <Divider
            style={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          /> */}
          <CardActions
            style={{
              justifyContent: "flex-end",
              paddingRight: theme.spacing(2),
              paddingBottom: theme.spacing(2),
            }}
          >
            <Button
              onClick={() => {
                setOpenRechazar(true);
              }}
              variant="contained"
              color="secondary"
            >
              Rechazar contrato
            </Button>
            <Button
              onClick={() => {
                validar();
              }}
              variant="contained"
              color="primary"
            >
              Aceptar contrato
            </Button>
          </CardActions>
          <DialogoRechazarContrato
            theme={theme}
            paths={paths}
            estadoContratacion={estadoContratacion}
            openRechazar={[openRechazar, setOpenRechazar]}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setSnack={setSnack}
            API_DEFAULT={API_DEFAULT}
            accessToken={[accessToken, setAccessToken]}
            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
            setShowBackdrop={setShowBackdrop}
          />
        </Card>
      )}
    />
  );
}
export default ContratoAbierto;
