import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
    TextField,
    Collapse,
    Button,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete'

import Paso1 from '../../../../styles/images/Paso1.jpg';
import Paso2 from '../../../../styles/images/Paso2.jpg';
import Paso3 from '../../../../styles/images/Paso3.jpg';


const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 345,
        margin: '8px'
    },
    media: {
        height: 140,
    },
    logo: {
        width: "auto",
        height: 140,
    }
}));

function RecolectorTelefonicoCasa(props) {
    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        path,
        setMessage,
        setSnack,
        setSeverity,
        setShowBackdrop,
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [openEncuestadorTel, setOpenEncuestadorTel] = props.open;
    const classes = useStyles();
    const [archivo, setArchivo] = useState(null);
    const hiddenInputs = [useRef(null)];
    const [errores, setErrores] = useState(Array(23).fill(false));
    const [helperText, setHelperText] = useState(Array(21).fill('Seleccione una opción'));
    const [experienciaServicios2, setExperienciaServicios2] = useState(null);
    const [tiempoExperiencia2, setTiempoExperiencia2] = useState(null);
    const [soporteExperiencia2, setSoporteExperiencia2] = useState(null);
    const [experienciaCallCenter, setExperienciaCallCenter] = useState(null);
    const [soporteExpeCall, setSoporteExpeCall] = useState(null);
    const [computador, setComputador] = useState(null);
    const [tiempoComputador, setTiempoComputador] = useState(null);
    const [sistemaOperativo, setSistemaOperativo] = useState(null);
    const [otroSistemaOp, setOtroSistemaOp] = useState('');
    const [procesador, setProcesador] = useState('');
    const [memoriaRam, setMemoriaRam] = useState(null);
    const [puerto, setPuerto] = useState(null);
    const [navegador, setNavegador] = useState(null);
    const [internet, setInternet] = useState(null);
    const [operadorInternet, setOperadorInternet] = useState('');
    const [megas, setMegas] = useState('');
    const [cableModem, setCableModem] = useState(null);
    const [audifonos, setAudifonos] = useState(null);
    const [rut, setRut] = useState(null);
    const [tramiteRut, setTramiteRut] = useState(null);
    const [instrucciones, setinstrucciones] = useState(false);


    const validar = () => {
        let errorDatos = false;
        if (experienciaServicios2 === null) {
            errorDatos = true;
            errores[0] = true;
        }
        if (experienciaServicios2 === '1' && tiempoExperiencia2 === null) {
            errorDatos = true;
            errores[1] = true;
        }
        if (experienciaServicios2 === '1' && soporteExperiencia2 === null) {
            errorDatos = true;
            errores[2] = true;
        }
        if (experienciaCallCenter === null) {
            errorDatos = true;
            errores[3] = true;
        }
        if (experienciaCallCenter === '1' && soporteExpeCall === null) {
            errorDatos = true;
            errores[4] = true;
        }
        if (computador === null) {
            errorDatos = true;
            errores[5] = true;
        }
        if (computador === '1' && tiempoComputador === null) {
            errorDatos = true;
            errores[6] = true;
        }
        if (computador === '1' && sistemaOperativo === null) {
            errorDatos = true;
            errores[7] = true;
        }
        if (computador === '1' && sistemaOperativo === '2' && otroSistemaOp === '') {
            errorDatos = true;
            errores[8] = true;
        }
        if (computador === '1' && procesador === '') {
            errorDatos = true;
            errores[9] = true;
        }
        if (computador === '1' && memoriaRam === null) {
            errorDatos = true;
            errores[10] = true;
        }
        if (computador === '1' && puerto === null) {
            errorDatos = true;
            errores[11] = true;
        }
        if (computador === '1' && navegador === null) {
            errorDatos = true;
            errores[12] = true;
        }
        if (internet === null) {
            errorDatos = true;
            errores[13] = true;
        }
        if (internet === '1' && operadorInternet === '') {
            errorDatos = true;
            errores[14] = true;
        }
        if (internet === '1' && megas === '') {
            errorDatos = true;
            errores[15] = true;
        }
        if (internet === '1' && cableModem === null) {
            errorDatos = true;
            errores[16] = true;
        }
        if (audifonos === null) {
            errorDatos = true;
            errores[17] = true;
        }
        if (rut === null) {
            errorDatos = true;
            errores[18] = true;
        }
        if (rut === '0' && tramiteRut === null) {
            errorDatos = true;
            errores[19] = true;
        }
        // if (minArchivos[0] === false) {
        //     errorDatosArchivos = true;
        //     errores[0] = true;
        // }
        if (errorDatos) {
            setErrores([...errores]);
            setMessage('Existen campos vacíos, o no ha cargado la imagen requerida.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            if (archivo != null) {
                putInformacionSpecs();
            } else {
                putInformacion();
            }
        }
    }

    function cargarArchivo(file) {
        if (file !== undefined) {
            if (file.type.split('/')[0] === 'image') {
                setArchivo(file);
            }
            else {
                setMessage('El tipo de archivo seleccionado no es soportado. Archivos soportados: imagen');
                setSeverity('warning');
                setTimeout(() => { setSnack(true) }, 0);
            }
        }
    }

    const limpiar = () => {
        setExperienciaServicios2(null);
        setTiempoExperiencia2(null);
        setSoporteExperiencia2(null);
        setExperienciaCallCenter(null);
        setSoporteExpeCall(null);
        setComputador(null);
        setTiempoComputador(null);
        setSistemaOperativo(null);
        setOtroSistemaOp('');
        setProcesador('');
        setMemoriaRam(null);
        setPuerto(null);
        setNavegador(null);
        setInternet(null);
        setOperadorInternet('');
        setMegas('');
        setCableModem(null);
        setAudifonos(null);
        setRut(null);
        setTramiteRut(null);

        setErrores(Array(24).fill(false));
        setHelperText(Array(21).fill('Seleccione una opción'));
    }

    function limpiarArchivos() {
        setArchivo(null);
    }

    async function putInformacionSpecs(access = accessToken) {
        setShowBackdrop(true);
        let formData = new FormData();
        formData.append('specs', archivo);
        formData.append('perfil_postulacion', 1);
        formData.append('cuerpo_formato', JSON.stringify({
            'experiencia_servicios': experienciaServicios2 === '1' ? true : false,
            'tiempo_experiencia_servicios': experienciaServicios2 === '0' ? 3 : (tiempoExperiencia2 === '0' ? 0 : (tiempoExperiencia2 === '1' ? 1 : 2)),
            'soporte_experiencia_servicios': experienciaServicios2 === '0' ? 2 : (soporteExperiencia2 === '1' ? 0 : 1),
            'experiencia_callcenter': experienciaCallCenter === '1' ? true : false,
            'soporte_experiencia_callcenter': experienciaCallCenter === '0' ? 2 : (soporteExpeCall === '1' ? 0 : 1),
            'tiene_computador': computador === '1' ? true : false,
            'disponibilidad_computador': computador === '0' ? 3 : (tiempoComputador === '0' ? 0 : (tiempoComputador === '1' ? 1 : 2)),
            'sistema_operativo': computador === '0' ? 4 : (sistemaOperativo === '0' ? 0 : (sistemaOperativo === '1' ? 1 : (sistemaOperativo === '2' ? 2 : 3))),
            'otro_sistema_operativo': computador === '1' && sistemaOperativo === '2' ? otroSistemaOp : '',
            'procesador_computador': computador === '0' ? '' : procesador,
            'ram_computador': computador === '0' ? 3 : (memoriaRam === '0' ? 0 : (memoriaRam === '1' ? 1 : 2)),
            'puerto_modem_computador': computador === '0' ? 2 : (puerto === '1' ? 0 : 1),
            'chrome': computador === '0' ? 2 : (navegador === '1' ? 0 : 1),
            'tiene_internet': internet === '1' ? true : false,
            'operador_internet': internet === '0' ? '' : operadorInternet,
            'megas_internet': internet === '0' ? '' : megas,
            'cable_internet': internet === '0' ? 2 : (cableModem === '1' ? 0 : 1),
            'auriculares_microfono': audifonos === '1' ? true : false,
            'tiene_rut': rut === '1' ? true : false,
            'tramite_rut': rut === '0' ? 2 : (tramiteRut === '1' ? 0 : 1)
        }))

        const res = await fetch(`${API_DEFAULT}/seleccion/crear_formato/`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${access}` },
            body: formData
        })
        setShowBackdrop(false);
        if (res.ok) {
            setMessage('Te has postulado correctamente.');
            alert('Te has postulado correctamente.')
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            window.location.href = path;
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putInformacionSpecs(newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    async function putInformacion(access = accessToken) {
        let res = await fetch(`${API_DEFAULT}/seleccion/crear_formato/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify(
                {
                    'perfil_postulacion': 1,
                    'cuerpo_formato': {
                        'experiencia_servicios': experienciaServicios2 === '1' ? true : false,
                        'tiempo_experiencia_servicios': experienciaServicios2 === '0' ? 3 : (tiempoExperiencia2 === '0' ? 0 : (tiempoExperiencia2 === '1' ? 1 : 2)),
                        'soporte_experiencia_servicios': experienciaServicios2 === '0' ? 2 : (soporteExperiencia2 === '1' ? 0 : 1),
                        'experiencia_callcenter': experienciaCallCenter === '1' ? true : false,
                        'soporte_experiencia_callcenter': experienciaCallCenter === '0' ? 2 : (soporteExpeCall === '1' ? 0 : 1),
                        'tiene_computador': computador === '1' ? true : false,
                        'disponibilidad_computador': computador === '0' ? 3 : (tiempoComputador === '0' ? 0 : (tiempoComputador === '1' ? 1 : 2)),
                        'sistema_operativo': computador === '0' ? 4 : (sistemaOperativo === '0' ? 0 : (sistemaOperativo === '1' ? 1 : (sistemaOperativo === '2' ? 2 : 3))),
                        'otro_sistema_operativo': computador === '1' && sistemaOperativo === '2' ? otroSistemaOp : '',
                        'procesador_computador': computador === '0' ? '' : procesador,
                        'ram_computador': computador === '0' ? 3 : (memoriaRam === '0' ? 0 : (memoriaRam === '1' ? 1 : 2)),
                        'puerto_modem_computador': computador === '0' ? 2 : (puerto === '1' ? 0 : 1),
                        'chrome': computador === '0' ? 2 : (navegador === '1' ? 0 : 1),
                        'tiene_internet': internet === '1' ? true : false,
                        'operador_internet': internet === '0' ? '' : operadorInternet,
                        'megas_internet': internet === '0' ? '' : megas,
                        'cable_internet': internet === '0' ? 2 : (cableModem === '1' ? 0 : 1),
                        'auriculares_microfono': audifonos === '1' ? true : false,
                        'tiene_rut': rut === '1' ? true : false,
                        'tramite_rut': rut === '0' ? 2 : (tramiteRut === '1' ? 0 : 1)
                    }
                })
        })
        if (res.ok) {
            setMessage('Te has postulado correctamente.');
            alert('Te has postulado correctamente.')
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            window.location.href = path;
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putInformacion(newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }
    return (
        <Dialog
            open={openEncuestadorTel}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                >
                    <Grid item xs={11} md={11} align='center'>
                        <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Recolector de información telefónico</Typography>
                    </Grid>
                    <Grid item xs={1} md={1}>
                        <IconButton onClick={() => setOpenEncuestadorTel(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <Divider />
            <DialogContent style={{ paddingLeft: 36, paddingRight: 36 }}>
                <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500, marginBottom: theme.spacing(2) }}>A continuación, por favor responda las siguientes preguntas:</Typography>
                <Typography variant='body2'><span style={{ color: '#e53935' }}>*</span> Estos campos son obligatorios.</Typography>
                <Typography variant='body2' style={{ marginBottom: theme.spacing(3) }}>* Para efectos de pago, se requerirá que tenga una cuenta de Daviplata activa y descargue la aplicación en su celular.</Typography>
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" error={errores[0]}>
                            <Typography variant='body1'> 1. ¿Cuenta con experiencia en servicio al cliente, ventas, análisis de información o recolección de datos?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <RadioGroup
                                value={experienciaServicios2}
                                onChange={(e) => { setExperienciaServicios2(e.target.value); setTiempoExperiencia2(null); setSoporteExperiencia2(null); errores[0] = false; setErrores([...errores]); helperText[0] = ''; setHelperText([...helperText]) }}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                            <FormHelperText>{helperText[0]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Collapse in={experienciaServicios2 === '1'} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[1]}>
                                <Typography variant='body1'> 1.1. ¿Cuánto tiempo?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                <RadioGroup
                                    value={tiempoExperiencia2}
                                    onChange={(e) => { setTiempoExperiencia2(e.target.value); errores[1] = false; setErrores([...errores]); helperText[1] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="0 - 6 meses" />
                                    <FormControlLabel value='1' control={<Radio />} label="6 - 12 meses" />
                                    <FormControlLabel value='2' control={<Radio />} label="Más de 12 meses" />
                                </RadioGroup>
                                <FormHelperText>{helperText[1]}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[2]}>
                                <Typography variant='body1'> 1.2. ¿Cuenta con soporte de esta experiencia?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                <RadioGroup
                                    value={soporteExperiencia2}
                                    onChange={(e) => { setSoporteExperiencia2(e.target.value); errores[2] = false; setErrores([...errores]); helperText[2] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperText>{helperText[2]}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" error={errores[3]}>
                            <Typography variant='body1'> 2. ¿Tiene experiencia en Call Center?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <RadioGroup
                                value={experienciaCallCenter}
                                onChange={(e) => { setExperienciaCallCenter(e.target.value); setSoporteExpeCall(null); errores[3] = false; setErrores([...errores]); helperText[3] = ''; setHelperText([...helperText]) }}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                            <FormHelperText>{helperText[3]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

                <Collapse in={experienciaCallCenter === '1'} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[4]}>
                                <Typography variant='body1'> 2.1. ¿Cuenta con soporte de esta experiencia?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                <RadioGroup
                                    value={soporteExpeCall}
                                    onChange={(e) => { setSoporteExpeCall(e.target.value); errores[4] = false; setErrores([...errores]); helperText[4] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperText>{helperText[4]}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" error={errores[5]}>
                            <Typography variant='body1'> 3. ¿Cuenta con computador (escritorio o PC) en su casa, que funcione correctamente?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <RadioGroup
                                value={computador}
                                onChange={(e) => { setComputador(e.target.value); setTiempoComputador(null); setSistemaOperativo(null); setProcesador(''); setMemoriaRam(null); setPuerto(null); errores[5] = false; setErrores([...errores]); helperText[5] = ''; setHelperText([...helperText]) }}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                            <FormHelperText>{helperText[5]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

                <Collapse in={computador === '1'} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[6]}>
                                <Typography variant='body1'> 3.1. Por favor, indique el tiempo disponible para uso de su computador: <span style={{ color: '#e53935' }}>* </span></Typography>
                                <RadioGroup
                                    value={tiempoComputador}
                                    onChange={(e) => { setTiempoComputador(e.target.value); errores[6] = false; setErrores([...errores]); helperText[6] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="Todo el día" />
                                    <FormControlLabel value='1' control={<Radio />} label="Medio día" />
                                    <FormControlLabel value='2' control={<Radio />} label="Algunas horas" />
                                </RadioGroup>
                                <FormHelperText>{helperText[6]}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[7]}>
                                <Typography variant='body1'> 3.2. ¿Cuál es sistema operativo que tiene su computador?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                <RadioGroup
                                    value={sistemaOperativo}
                                    onChange={(e) => { setSistemaOperativo(e.target.value); setOtroSistemaOp(''); errores[7] = false; setErrores([...errores]); helperText[7] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="Anterior a Windows 10" />
                                    <FormControlLabel value='1' control={<Radio />} label="Superior a Windows 10" />
                                    <FormControlLabel value='2' control={<Radio />} label="Otro" />
                                </RadioGroup>
                                <FormHelperText>{helperText[7]}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Collapse in={sistemaOperativo === '2'} mountOnEnter unmountOnExit>
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={4}
                                style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(-2), marginLeft: theme.spacing(1) }}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1'> Si selecciono otro, ¿cuál?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                    <TextField
                                        value={otroSistemaOp}
                                        onChange={(e) => { setOtroSistemaOp(e.target.value); errores[8] = false; setErrores([...errores]) }}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        autoFocus
                                        error={errores[8]}
                                        helperText={errores[8] ? 'Este campo no puede quedar vacío' : null}
                                    //style={{ marginBottom: [errores[7], errores[8]].includes(true) && !errores[8] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                </Grid>
                            </Grid>
                        </Collapse>

                        <Grid item md={11} xs={12}>
                            <Typography variant='body1'> 3.3. ¿Qué procesador tiene su computador?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <TextField
                                value={procesador}
                                onChange={(e) => { setProcesador(e.target.value); errores[9] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[9]}
                                helperText={errores[9] ? 'Este campo no puede quedar vacío' : null}
                            //style={{ marginBottom: [errores[10], errores[11]].includes(true) && !errores[89] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[10]}>
                                <Typography variant='body1'> 3.4. ¿Cuánta memoria RAM tiene su computador?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                <RadioGroup
                                    value={memoriaRam}
                                    onChange={(e) => { setMemoriaRam(e.target.value); errores[10] = false; setErrores([...errores]); helperText[10] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="Menos de 4 Gigas" />
                                    <FormControlLabel value='1' control={<Radio />} label="Más de 4 Gigas" />
                                </RadioGroup>
                                <FormHelperText>{helperText[10]}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[11]}>
                                <Typography variant='body1'> 3.5. ¿Su computador tiene puerto de conexión al modem de internet?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                <RadioGroup
                                    value={puerto}
                                    onChange={(e) => { setPuerto(e.target.value); errores[11] = false; setErrores([...errores]); helperText[11] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperText>{helperText[11]}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[12]}>
                                <Typography variant='body1'> 3.6. ¿Su computador navega por Google Chrome?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                <RadioGroup
                                    value={navegador}
                                    onChange={(e) => { setNavegador(e.target.value); errores[12] = false; setErrores([...errores]); helperText[12] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperText>{helperText[12]}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant='body1'> 3.7. Por favor, adjunte pantallazo de las características de su computador anteriormente mencionadas (Memoria RAM, almacenamiento interno, procesador y sistema operativo).  <span style={{ color: '#e53935' }}>*</span></Typography>
                        </Grid>
                        <Grid item xs={12} md={12} align='center'>
                            <Button
                                //variant='contained'
                                color='primary'
                                onClick={() => { setinstrucciones(!instrucciones) }}
                            >
                                {instrucciones ? 'Contraer' : 'Ver instrucciones'}
                            </Button>
                        </Grid>
                        <Collapse in={instrucciones} mountOnEnter unmountOnExit>

                            <Card className={classes.root} >

                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        Primer paso
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Desplazar el ratón a la caja Escribe para buscar y teclear Panel de control. Una vez aquí debes darle a Sistema y seguridad.
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    className={classes.logo}
                                    image={Paso1}
                                    title="Postulaciones"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        Segundo paso
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Se  abrirá una nueva ventana con multitud de opciones, pero para conocer las especificaciones en Windows 10 debe pinchar en Sistema.
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    className={classes.logo}
                                    image={Paso2}
                                    title="Postulaciones"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        Tercer paso
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Como puede ver en la siguiente imagen, aparecerán los datos más básicos del ordenador, las especificaciones de su equipo con Windows 10, como el procesador, la memoria RAM o el sistema operativo actual.
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    className={classes.logo}
                                    image={Paso3}
                                    title="Postulaciones"
                                />
                            </Card>
                        </Collapse>


                        <Grid item md={4} xs={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={archivo !== null ? <DeleteIcon /> : <CloudUploadIcon />}
                                color={archivo !== null ? 'secondary' : 'inherit'}
                                onClick={() => { archivo !== null ? limpiarArchivos(0) : hiddenInputs[0].current.click() }}
                            >
                                <Typography variant='button'>{archivo !== null ? 'Limpiar' : 'Cargar'}</Typography>
                            </Button>
                            <input
                                type="file"
                                ref={hiddenInputs[0]}
                                style={{ display: 'none' }}
                                onChange={(e) => { cargarArchivo(e.target.files[0]); e.target.value = null }}
                            />
                        </Grid>

                    </Grid>
                </Collapse>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" error={errores[13]}>
                            <Typography variant='body1'> 4. ¿Actualmente cuenta con internet en su hogar?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <RadioGroup
                                value={internet}
                                onChange={(e) => { setInternet(e.target.value); setOperadorInternet(''); setMegas(''); setCableModem(null); errores[13] = false; setErrores([...errores]); helperText[13] = ''; setHelperText([...helperText]) }}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                            <FormHelperText>{helperText[13]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

                <Collapse in={internet === '1'} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={11} xs={12}>
                            <Typography variant='body1'> 4.1. ¿Cuál es su operador de internet?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <TextField
                                value={operadorInternet}
                                onChange={(e) => { setOperadorInternet(e.target.value); errores[14] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[14]}
                                helperText={errores[14] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                        <Grid item md={11} xs={12}>
                            <Typography variant='body1'> 4.2. ¿Cuántas megas tiene su internet?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <TextField
                                value={megas}
                                onChange={(e) => { setMegas(e.target.value); errores[15] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[15]}
                                helperText={errores[15] ? 'Este campo no puede quedar vacío' : null}
                            //style={{ marginBottom: [errores[14], errores[11]].includes(true) && !errores[89] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[16]}>
                                <Typography variant='body1'> 4.3. ¿Tiene cable de conexión a internet del computador al modem?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                <RadioGroup
                                    value={cableModem}
                                    onChange={(e) => { setCableModem(e.target.value); errores[16] = false; setErrores([...errores]); helperText[16] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperText>{helperText[16]}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>

                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" error={errores[17]}>
                            <Typography variant='body1'> 5. ¿Tiene auriculares con micrófono o diadema para realizar las llamadas?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <RadioGroup
                                value={audifonos}
                                onChange={(e) => { setAudifonos(e.target.value); errores[17] = false; setErrores([...errores]); helperText[17] = ''; setHelperText([...helperText]) }}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                            <FormHelperText>{helperText[17]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" error={errores[18]}>
                            <Typography variant='body1'> 6. ¿Tiene RUT?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <RadioGroup
                                value={rut}
                                onChange={(e) => { setRut(e.target.value); setTramiteRut(null); errores[18] = false; setErrores([...errores]); helperText[18] = ''; setHelperText([...helperText]) }}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                            <FormHelperText>{helperText[18]}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Collapse in={rut === '0'} mountOnEnter unmountOnExit>
                        <Grid
                            container
                            direction='column'
                            alignItems='flex-start'
                            spacing={4}
                            style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                        >
                            <Grid item md={12} xs={12}>
                                <FormControl component="fieldset" error={errores[19]}>
                                    <Typography variant='body1'> 6.1. ¿Está dispuesto a tramitarlo?: <span style={{ color: '#e53935' }}>* </span></Typography>
                                    <RadioGroup
                                        value={tramiteRut}
                                        onChange={(e) => { setTramiteRut(e.target.value); errores[19] = false; setErrores([...errores]); helperText[19] = ''; setHelperText([...helperText]) }}
                                    >
                                        <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                        <FormControlLabel value='0' control={<Radio />} label="No" />
                                    </RadioGroup>
                                    <FormHelperText>{helperText[19]}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>

            </DialogContent>
            <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
            <DialogActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                <Button

                    onClick={() => {
                        limpiar()
                    }}
                    variant='contained'
                    color='secondary'
                >
                    Limpiar
                </Button>
                <Button

                    onClick={() => {
                        validar()
                    }}
                    variant='contained'
                    color='primary'
                >
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>

    )
};

export default RecolectorTelefonicoCasa;