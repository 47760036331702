import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography
} from '@material-ui/core';

import DatosPersonales from './components/DatosPersonales';
import EstudiosRealizados from './components/EstudiosRealizados';
import InformacionPersonal from './components/InformacionPersonal';
import Disclaimer from './components/Disclaimer'

function FormatoRegistro(props) {
    const { theme,
        setSnack,
        setMessage,
        setSeverity,
        path,
        API_DEFAULT } = props;
    const [errorAcepta, setErrorAcepta] = useState(Array(1).fill(false));
    const [datosCompletos, setDatosCompletos] = useState(Array(6).fill(false));
    const experiencia = useState(0);
    const [acepta, setAcepta] = useState(false);
    const [index, setIndex] = useState(0);
    const [datosPersonales, setDatosPersonales] = useState({});
    const [datosEstudiosRealizados, setEstudiosRealizados] = useState({});
    const datosExperienciaLaboral = useState({});
    const datosTrabajoCnc = useState({});
    const [informacionPersonal, setInformacionPersonal] = useState({});
    const [documento, setDocumento] = useState('');

    const disclaimer = (acepta) => {
        let errorDisclaimer = false;
        if (!acepta) {
            errorDisclaimer = true;
            errorAcepta[0] = true;
        }
        if (errorDisclaimer) {
            setErrorAcepta([...errorAcepta]);
            setMessage('Debe aceptar las condiciones.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            putInformacion();
        }
    }

    async function putInformacion() {
        let datosAll = Object.assign({}, datosPersonales, datosEstudiosRealizados, datosTrabajoCnc, informacionPersonal)
        datosAll = Object.assign({}, datosAll, { "servicio_cnc": false })

        let res = await fetch(`${API_DEFAULT}/seleccion/crear_persona_seleccion/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(datosAll)
        })
        // const text = await res.text();
        if (res.ok && experiencia === 1) {
            res = await fetch(`${API_DEFAULT}/seleccion/crear_experiencia_laboral/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    datosExperienciaLaboral.map(experiencia => ({ ...experiencia, 'persona_seleccion': datosAll.documento })
                    )
                )
            }).then(window.localStorage.setItem('user', datosAll.documento))
        }
        if (res.ok) {
            setMessage('Se ha registrado exitosamente.');
            alert('Hemos recibido su registro exitosamente. A su correo le será enviado el nombre de usuario y contraseña para continuar con el proceso de aplicación, y subir los documentos correspondientes. Si no encuentra el correo en su bandeja de entrada, por favor revisar la bandeja de spam.')
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            window.location.href = path;
        }
        else {
            if (res.status === 400) {
                let error_msg = await res.json();
                if (error_msg.error !== undefined) {
                    setMessage(error_msg.error);
                }
                else {
                    setMessage('Ocurrió un error, intente de nuevo.');
                }
            }
            else {
                setMessage('Ocurrió un error, intente de nuevo.');
            }
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }
    return (
        <div className='Seleccion'>
            <Card>
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                    title={
                        <Typography variant='h5' align='center' style={{ fontWeight: 500 }}>Formato de registro prestación de servicios</Typography>
                    }
                />
                <CardContent>
                    <DatosPersonales
                        theme={theme}
                        datosCompletos={[datosCompletos, setDatosCompletos]}
                        setDatosPersonales={setDatosPersonales}
                        documento={[documento, setDocumento]}
                        setSnack={setSnack}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                    />
                    <EstudiosRealizados
                        theme={theme}
                        datosCompletos={[datosCompletos, setDatosCompletos]}
                        setEstudiosRealizados={setEstudiosRealizados}
                        setSnack={setSnack}
                        setMessage={setMessage}
                        setSeverity={setSeverity}

                    />
                    {/* <ExperienciaLaboral
                        theme={theme}
                        tieneExperiencia={[experiencia, setExperiencia]}
                        datosCompletos={[datosCompletos, setDatosCompletos]}
                        index={[index, setIndex]}
                        setExperienciaLaboral={setExperienciaLaboral}
                        setTrabajoCnc={setTrabajoCnc}
                        setSnack={setSnack}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                    /> */}

                    <InformacionPersonal
                        theme={theme}
                        datosCompletos={[datosCompletos, setDatosCompletos]}
                        index={[index, setIndex]}
                        setInformacionPersonal={setInformacionPersonal}
                        // tieneExperiencia={[experiencia, setExperiencia]}
                        setSnack={setSnack}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                    />
                    <Disclaimer
                        theme={theme}
                        datosCompletos={datosCompletos}
                        errores={[errorAcepta, setErrorAcepta]}
                        disclaimer={disclaimer}
                        acepta={[acepta, setAcepta]}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

export default FormatoRegistro;