import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Typography,
    Button,
    Grid,
    TextField,
    Card,
    CardContent,
    InputAdornment,
    IconButton,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import { useHistory } from "react-router-dom"
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useLocation } from "react-router-dom"
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#B71C1C',
    }
}));

function RestorePassword(props) {
    const { theme, setShowBackdrop, API_DEFAULT, setMessage, setSeverity, setSnack, paths } = props;
    const inputRef = useRef();
    const classes = useStyles();
    const sampleLocation = useLocation();
    let history = useHistory();
    const [username, setUsername] = useState('');
    const [access, setAccess] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState(new Array(6).fill(true));
    const [isClick, setIsClick] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);

    const condiciones = [
        "Al menos 1 carácter alfabético en minúscula",
        "Al menos 1 carácter alfabético en mayúscula",
        "Al menos 1 carácter numérico",
        "Al menos un carácter especial",
        "Ocho caracteres o más",
        "La contraseña y la confirmación deben coincidir"
    ];

    function validatePasswords(text) {

        errors[0] = text.match('[a-z]') == null; //Tiene minúsculas
        errors[1] = text.match('[A-Z]') == null; //Tiene mayúsculas
        errors[2] = text.match('[0-9]') == null; //Tiene caracteres numéricos
        errors[3] = text.match('[#?_!@$%^&*-]') == null; //Tiene caracteres especiales
        errors[4] = text.length < 8; //Tiene 8 caracteres o más
        errors[5] = text !== passwordConfirmation; //La confirmación coincide
        setErrors([...errors]);
    }

    function validateConfirmation(text) {
        errors[5] = password !== text; //La confirmación coincide
        setErrors([...errors]);
    }

    function validate() {
        let error = errors.reduce((previous, current) => previous || current);
        if (error) {
            setMessage('Existen errores en las contraseñas ingresadas.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        } else {
            sendPassword();
        }
    }

    const sendPassword = async () => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/usuarios/restaurar_contrasenia/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'password': password
            })
        });

        if (res.ok) {
            setShowBackdrop(false);
            setOpenDialog(true);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    setShowBackdrop(false);
                    setMessage('El link utilizado ha expirado.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                    history.push(paths[9].path);
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const handleClose = () => {
        setOpenDialog(false);
        setAccess('');
        setUsername('');
        history.push(paths[0].path);
    }

    useEffect(() => {
        let search = sampleLocation.search;
        if (search !== '') {
            search = search.slice(1).split('&');
            setUsername(search[0]);
            setAccess(search[1]);
        }
    }, [sampleLocation]);

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                        style={{ marginBottom: theme.spacing(4) }}
                    >
                        <Grid item>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant='h5'>Nueva Contraseña para {username}</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                    >
                        <Grid item xs style={{ marginBottom: theme.spacing(2) }}>
                            <Typography variant='body'>
                                La contraseña debe contener:
                                <List dense={true}>
                                    {condiciones.map((c, index) => (
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckBoxIcon style={{ color: errors[index] ? '#C2C2C2' : '#26C250' }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={c}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Typography>
                        </Grid>
                        <Divider style={{ marginBottom: theme.spacing(2) }} />
                        <Grid item xs style={{ marginBottom: theme.spacing(2) }}>
                            <TextField
                                inputRef={inputRef}
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                size='small'
                                variant='outlined'
                                label='Nueva contraseña'
                                value={password}
                                onSelect={() => { if (isClick) { inputRef.current.selectionStart = cursorPosition; setIsClick(false) } }}
                                onChange={(e) => { setPassword(e.target.value); validatePasswords(e.target.value); }}

                                InputProps={{
                                    endAdornment: (
                                        < InputAdornment position='end' >
                                            <IconButton
                                                size='small'
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={(event) => { event.preventDefault(); setCursorPosition(inputRef.current.selectionStart); setIsClick(document.activeElement === inputRef.current) }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs style={{ marginBottom: theme.spacing(4) }}>
                            <TextField
                                inputRef={inputRef}
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                size='small'
                                variant='outlined'
                                label='Confirmar nueva contraseña'
                                value={passwordConfirmation}
                                onSelect={() => { if (isClick) { inputRef.current.selectionStart = cursorPosition; setIsClick(false) } }}
                                onChange={(e) => { setPasswordConfirmation(e.target.value); validateConfirmation(e.target.value); }}
                                error={errors[5]}
                                InputProps={{
                                    endAdornment: (
                                        < InputAdornment position='end' >
                                            <IconButton
                                                size='small'
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={(event) => { event.preventDefault(); setCursorPosition(inputRef.current.selectionStart); setIsClick(document.activeElement === inputRef.current) }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Button fullWidth={true} variant='contained' color='primary' onClick={() => { validate() }}>Cambiar contraseña</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Dialog
                open={openDialog}
                onClose={handleClose}
            >
                <DialogTitle>Constraseña actualizada</DialogTitle>
                <DialogContent>
                    Se ha actualizado su contraseña correctamente.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default RestorePassword;