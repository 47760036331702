import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    Grid,
    Button,
    CardActions,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useHistory  } from 'react-router-dom';

function FormatosResponsabilidad(props) {
    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        setMessage,
        setSnack,
        setSeverity,
        setShowBackdrop,
        paths,
        formatos,
        fetchFormatosResponsabilidad,
        cedula
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [aceptaFormato, setAceptaFormato] = useState({});
    const [hayFormatosPendientes, setHayFormatosPendientes] = useState(false);
    const [hayFormatosFirmados, setHayFormatosFirmados] = useState(false);
    const history = useHistory();

    const firmarFormatos = async () => {
        setShowBackdrop(true);
        let responses = [];

        await formatos.filter((formato) => formato.firma === false).map(async (formato) => {
            if (aceptaFormato[formato.uuid]) {
                let res = await firmarFormato(formato.uuid);
                responses.push(res.status);
            }
        });

        if (responses.every((val) => val === 200)) {
            setMessage('Formatos Firmados correctamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            setShowBackdrop(false);
        } else {
            setMessage('Ocurrió un error, intente de nuevo.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
            setShowBackdrop(false);
        }

        fetchFormatosResponsabilidad(cedula);

    };

    async function firmarFormato(uuid, access = accessToken) {

        const res = await fetch(`${API_DEFAULT}/productividad/formato_responsabilidad_personal/${uuid}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'firma': aceptaFormato[uuid]
            })
        });

        await res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    firmarFormato(uuid, newAccess);
                }
            }
            else {
                return res;
            }
        });

        return res;
    }

    const redirectToInicio = () => {
        history.push(paths[0].path); 
    };

    useEffect(() => {
        let firmados = formatos.filter((f) => f.firma === true).length;
        setHayFormatosFirmados(firmados > 0);
        setHayFormatosPendientes((formatos.length - firmados) > 0);
    }, [formatos]);

    return (
        <React.Fragment>
            {hayFormatosPendientes &&
                <Card>
                    <CardHeader
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                        title={
                            <Typography variant='h6' style={{ fontWeight: 500, textAlign: 'center' }}>Formatos de Responsabilidad</Typography>
                        }
                    />
                    <CardContent >
                        <Typography variant='h6' style={{ marginLeft: theme.spacing(4), marginTop: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: 'center' }}>A continuación encontrarás los formatos de responsabilidad pendientes por firmar:</Typography>
                        {
                            formatos.filter(f => f.firma === false).map((formato) => (
                                <div align="center">
                                    <iframe
                                        title="Formato"
                                        width="900"
                                        height="600"
                                        src={`${formato.formato}`}>
                                    </iframe>
                                    <Grid container>
                                        <Grid item xs={12} md={12} align='left'>
                                            <Typography style={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(4) }}>Nuestros formatos se firman digitalmente al seleccionar la siguiente casilla. ¿Acepta la firma del formato?</Typography>
                                            <FormControlLabel
                                                style={{ marginLeft: theme.spacing(4) }}
                                                control={<Checkbox color='primary' checked={aceptaFormato[formato.uuid]} onChange={() => { let uuid = formato.uuid; let estado = !aceptaFormato[uuid]; setAceptaFormato(prev => ({ ...prev, [uuid]: estado })); }} />}
                                                label={<Typography variant='body1'>Firmar formato</Typography>}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))
                        }
                    </CardContent>
                    <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
                    <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                        <Button
                            onClick={() => { redirectToInicio() }}
                            variant='contained'
                            color='secondary'
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={() => { firmarFormatos() }}
                            variant='contained'
                            color='primary'
                        >
                            Enviar firmas
                        </Button>
                    </CardActions>
                </Card>
            }
            {hayFormatosFirmados &&
                <Card>
                    <CardHeader
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                        title={
                            <Typography variant='h6' style={{ fontWeight: 500, textAlign: 'center' }}>Formatos de Responsabilidad</Typography>
                        }
                    />
                    <CardContent >
                        <Typography variant='h6' style={{ marginLeft: theme.spacing(4), marginTop: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: 'center' }}>A continuación encontrarás los formatos de responsabilidad firmados:</Typography>
                        {
                            formatos.filter(f => f.firma === true).map((formato) => (
                                <div align="center">
                                    <iframe
                                        title="Contrato"
                                        width="900"
                                        height="600"
                                        src={`${formato.formato}`}>
                                    </iframe>

                                </div>
                            ))
                        }
                        <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    </CardContent>
                </Card>
            }

        </React.Fragment >
    );
}

export default FormatosResponsabilidad;

