import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Popover,
    Collapse,
    IconButton
} from '@material-ui/core';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../styles/calendar-font.css';
import '../../../styles/hide_popup.css';
import moment from 'moment';

import { format } from 'date-fns';
import EsLocale from 'date-fns/locale/es';

import actividades from '../../../data/actividades';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

// import CurrencyTextField from '@unicef/material-ui-currency-textfield';
require('moment/locale/es');
const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
    tooltip: {
        margin: 0,
    },
    btn: {
        '&:disabled': {
            backgroundColor: '#e0e0e0',
            color: '#a6a6a6'
        },
        '&:disabled:hover': {
            backgroundColor: '#e0e0e0',
            border: '1px solid #ccc',
            color: '#a6a6a6'
        }
    }
}));

const randDarkColor = () => {
    var lum = -0.25;
    var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    var rgb = "#",
        c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }
    return rgb;
}

function hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function Calendario(props) {
    const { getAccessTokenWithRefresh, cedula } = props;
    const classes = useStyles();
    const API_DEFAULT = props.API_DEFAULT;
    const theme = props.theme;
    const [accessToken, setAccessToken] = props.accessToken;
    const username = props.username;
    const setShowBackdrop = props.setShowBackdrop;
  
    //const [encuestador, setEncuestador] = useState([]);

    const [mes, setMes] = useState((new Date()).getMonth() + 1);
    const [anio, setAnio] = useState((new Date()).getFullYear());

    const [eventos, setEventos] = useState([]);

    const [colorEstudios, setColorEstudios] = useState({});

    const [heightCalendar, setHeightCalendar] = useState(weekHeight((new Date()).getFullYear(), (new Date()).getMonth() + 1));
    const [fechaPopup, setFechaPopup] = useState(null);
    const [eventosPopup, setEventosPopup] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [openPopoverPopup, setOpenPopoverPopup] = useState(false);
    const [openPopoverEvento, setOpenPopoverEvento] = useState(false);
    const [openPopoverActividad, setOpenPopoverActividad] = useState(false);
    const [idEvento, setIdEvento] = useState(0);
    const [eventoActual, setEventoActual] = useState(null);
    const [eventoActual2, setEventoActual2] = useState(null);

    const [encuestadorActual, setEncuestadorActual] = useState(null);

    class CustomToolbar extends Toolbar {
        render() {
            return (
                <div className='rbc-toolbar'>
                    <span className="rbc-btn-group">
                        <button type="button" onClick={() => this.navigate('TODAY')}>Hoy</button>
                        <button type="button" onClick={() => this.navigate('PREV')}>&lt;&lt;</button>
                        <button type="button" className={classes.btn} disabled={new Date().getMonth() + 1 === mes} onClick={() => this.navigate('NEXT')}>&gt;&gt;</button>
                    </span>
                    <span className="rbc-toolbar-label" style={{fontWeight: 500, fontSize: 18}}>{this.props.label}</span>
                </div>
            );
        }

        navigate = action => {
            this.props.onNavigate(action)
        }
    }

    function weekHeight(year, month_number) {
        var firstOfMonth = new Date(year, month_number - 1, 1);
        var day = firstOfMonth.getDay() || 6;
        day = day === 1 ? 0 : day;
        if (day) { day-- }
        var diff = 7 - day;
        var lastOfMonth = new Date(year, month_number, 0);
        var lastDate = lastOfMonth.getDate();
        if (lastOfMonth.getDay() === 1) {
            diff--;
        }
        var result = Math.ceil((lastDate - diff) / 7);
        return result + 1 === 4 ? 581.5 : result + 1 === 5 ? 710 : 838.48;
    };

    const getColor = (e) => {
        const colorHex = e.creador !== undefined && (actividades.filter(a => a.codigo === e.actividad).length > 0) ? (e.id.length === undefined && e.creador === username) || (e.id.length !== undefined && !e.creador.some(v => v !== username)) ? actividades.filter(a => a.codigo === e.actividad)[0].color : actividades.filter(a => a.codigo === e.actividad)[0].color2 : '#424242'
        const colorRgb = hexToRgb(colorHex);
        const luminanceColor = luminance(colorRgb.r, colorRgb.g, colorRgb.b);
        const luminanceColorW = luminance(255, 255, 255);
        const luminanceColorB = luminance(0, 0, 0);
        const ratioW = luminanceColor > luminanceColorW ? ((luminanceColorW + 0.05) / (luminanceColor + 0.05)) : ((luminanceColor + 0.05) / (luminanceColorW + 0.05));
        const ratioB = luminanceColor > luminanceColorB ? ((luminanceColorB + 0.05) / (luminanceColor + 0.05)) : ((luminanceColor + 0.05) / (luminanceColorB + 0.05));
        return [colorHex, ratioW < ratioB ? 'white' : 'black']
    }

    const closePopoverEvento = () => {
        setOpenPopoverEvento(false);
        setTimeout(() => {
            setEventoActual2(null);
            setEncuestadorActual(null)
        }, 150);
    }

    const currency = function(number){
        return new Intl.NumberFormat('es-co', {style: 'currency',currency: 'COP', minimumFractionDigits:0}).format(number);
    };

    const fetchInfoEventos = async (access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/informacion_reporte/tipo_campo=4&cedula=${cedula}&mes=${mes}&anio=${anio}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchInfoEventos(newAccess);
                }
            }
            else {
                let aux = [];
                let id2 = 1;
                const group = res.reduce((r, { estudio, start, end, cedula, nombre, title, tipo_actividad, id, creador, valor, descripcion, horas}) => {
                    cedula = cedula + ': ' + nombre
                    let e = {
                        id: id2,
                        title: estudio,
                        end: end,
                        start: start,
                        actividades: [],
                        encuestadores: []
                    }
                    if (!r.map(r => [r.title, r.start]).some(v => v[0] === estudio && v[1] === start)) {
                        r[r.length] = e;
                        aux[[estudio, start]] = r.length - 1;
                        id2 = id2 + 1
                    }
                    if (r[aux[[estudio, start]]].actividades[cedula] === undefined) {
                        r[aux[[estudio, start]]].encuestadores.push(cedula)
                        r[aux[[estudio, start]]].actividades[cedula] = []
                    }
                    if (colorEstudios[estudio] === undefined) {
                        colorEstudios[estudio] = randDarkColor();
                        setColorEstudios({ ...colorEstudios });
                    }
                    if(descripcion['tarifa'] !== undefined){
                        valor = descripcion['tarifa'];
                    }

                    r[aux[[estudio, start]]].actividades[cedula].push({
                        title: title,
                        actividad: tipo_actividad === undefined ? 'E' : tipo_actividad,
                        creador: creador,
                        valor: valor,
                        id: id
                    });
                    r[aux[[estudio, start]]].color = colorEstudios[estudio];
                    r[aux[[estudio, start]]].color2 = 'white';
                    return r;
                }, []);

                setEventos(group);
                setShowBackdrop(false);
            }
        });
        
    }

    useEffect(() => {
        fetchInfoEventos();
    }, [cedula, mes, anio]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card >
            <CardHeader
                title={
                    <Typography variant='h6'>Tu calendario de actividades</Typography>
                }
                action={
                    <IconButton 
                        style={{ marginRight: theme.spacing(2) }} 
                        href="https://datastudio.google.com/u/0/reporting/8760d78a-bc35-444d-998c-0054b6242a43/page/zUXcC"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LocalAtmIcon color='primary' />
                        <Typography > Valores por encuesta</Typography>
                    </IconButton>
                }
            />
            <Divider />
            <CardContent>
                <Calendar
                    components={{ toolbar: CustomToolbar }}
                    popup
                    selectable
                    style={{ height: heightCalendar }}
                    localizer={localizer}
                    onView='month'
                    views={['month']}
                    messages={{
                        showMore: (target) => <Typography variant='subtitle2' onClick={(e) => setAnchorEl3(e.currentTarget)}> ...{target} más</Typography>,
                        next: '>>',
                        previous: '<<',
                        today: 'Hoy'
                    }}
                    onShowMore={(e, d) => { setEventosPopup(e); setFechaPopup(d); setOpenPopoverPopup(true); }}
                    onRangeChange={({ start }) => {
                        var date = new Date(start);
                        date.setDate(date.getDate() + 8);
                        setMes((new Date(date)).getMonth() + 1);
                        setAnio((new Date(date)).getFullYear());
                        setHeightCalendar(weekHeight((new Date(date)).getFullYear(), (new Date(date)).getMonth() + 1));
                        setEventos([]);
                    }}
                    events={eventos}
                    startAccessor='start'
                    endAccessor='end'
                    onSelectEvent={(event, target) => {
                        setEventoActual2(eventos.filter(e => e.id === event.id)[0]);
                        setOpenPopoverEvento(true);
                        setAnchorEl2(target.currentTarget);
                    }}
                    dayPropGetter={(date) => { if (date > new Date("2023/06/12 00:00:00") || date.getMonth() + 1 !== mes) return { style: { background: '#E6E6E6' } } }}
                    eventPropGetter={(e) => {
                        return {
                            style: { backgroundColor: e.color}
                            // style: { backgroundColor: eventoActual2 !== null ? eventoActual2 === e ? e.color : e.color + '66' : e.color, color: e.color2 }
                        }
                    }}
                />
                {eventosPopup !== null && fechaPopup !== null ?
                <Popover
                    open={openPopoverPopup}
                    onClose={() => { setOpenPopoverPopup(false); setTimeout(() => { setEventosPopup(null); setFechaPopup(null) }, 150); }}
                    anchorEl={anchorEl3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div style={{ width: 250, margin: theme.spacing(1) }}>
                        <span>{format(fechaPopup, 'EEEE dd, MMM', { locale: EsLocale })}</span>
                        <Divider style={{ marginBottom: theme.spacing(0.5) }} />
                        {eventosPopup.map(e =>
                            <div style={{ marginBottom: 1 }}>
                                <div
                                    style={{ borderRadius: 5, backgroundColor: eventoActual2 !== null ? eventoActual2 === e ? e.color : e.color + '66' : e.color, color: e.color2, padding: '2px 5px', cursor: 'pointer' }}
                                    onClick={(evento) => {
                                        setEventoActual2(eventos.filter(ev => ev.id === e.id)[0]);
                                        setOpenPopoverEvento(true);
                                        setAnchorEl2(evento.currentTarget);
                                    }}
                                >
                                    {e.title}
                                </div>
                            </div>
                        )}
                    </div>
                </Popover>
                :
                null
            }
            {
                eventoActual2 !== null ?
                    <Popover
                        open={openPopoverEvento && eventoActual2 !== null}
                        onClose={() => { closePopoverEvento() }}
                        anchorEl={anchorEl2}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ width: 220, maxHeight: 300, overflowY: 'auto', margin: theme.spacing(1) }}>
                            {eventoActual2.encuestadores.map(e => (
                                <React.Fragment>
                                    <div style={{ marginBottom: 2 }}>
                                        <div onClick={() => { encuestadorActual === e ? setEncuestadorActual(null) : setEncuestadorActual(e) }} style={{ height: 26.4, background: encuestadorActual === e ? eventoActual2.color + 'b3' : null, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                                            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', borderRadius: 5, background: encuestadorActual !== null ? encuestadorActual === e ? eventoActual2.color : eventoActual2.color + '66' : eventoActual2.color, color: eventoActual2.color2, cursor: 'pointer', padding: '2px 5px' }}>
                                                {e}
                                            </div>
                                        </div>
                                        <Collapse in={encuestadorActual === e}>
                                            <div style={{ background: eventoActual2.color + 'b3', paddingTop: 3, paddingBottom: 3, marginBottom: 1, borderBottomRightRadius: 5, borderBottomLeftRadius: 5 }}>
                                                <div style={{ marginLeft: theme.spacing(1.5), marginRight: theme.spacing(1) }}>
                                                    {eventoActual2.actividades[e].map(a => (
                                                        <div style={{ width: '100%', marginBottom: 1 }}>
                                                            <div
                                                                style={{ borderRadius: 5, background: getColor(a)[0], color: getColor(a)[1], padding: '2px 5px', cursor: a.title.includes('encuesta') && !a.title.includes('ECP') && !a.title.includes('ECP2') ? null : 'pointer' }}
                                                                onClick={(event) => { setAnchorEl(event.currentTarget); setOpenPopoverActividad(true); setIdEvento(a.id); setEventoActual(a) }}
                                                            >
                                                                {a.title}
                                                                <br></br>
                                                                {a.valor !== undefined?
                                                                    <span>Valor: {currency(a.valor)}</span>
                                                                : 
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </Popover>
                    :
                    null
            }
            <Popover
                open={openPopoverActividad && idEvento !== undefined && eventoActual.creador !== undefined}
                onClose={() => { setOpenPopoverActividad(false) }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {
                    eventoActual !== null && eventoActual !== undefined && idEvento !== undefined && idEvento !== 0 ?
                        (idEvento.length === undefined && eventoActual.creador !== username) || (idEvento.length !== undefined && eventoActual.creador.some(v => v !== username)) ?
                            (idEvento.length === undefined && eventoActual.creador !== username) ?
                                <Typography style={{ margin: theme.spacing(1) }} variant='body2'><span style={{ fontWeight: 500, fontSize: 'inherit' }}>Agregado por:</span> {eventoActual.creador}</Typography>
                                :
                                <div style={{ margin: theme.spacing(1) }} >
                                    <Typography style={{ marginBottom: theme.spacing(0.2) }} variant='body2'><span style={{ fontWeight: 500, fontSize: 'inherit' }}>Existen horas agregadas por:</span></Typography>
                                    {eventoActual.creador.map(c => (
                                        <Typography variant='body2'>&#8226; {c}</Typography>
                                    ))}
                                </div>
                            :
                            null
                        :
                        null
                }
            </Popover>



            </CardContent>
        </Card>

    );
}

export default Calendario;