import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    Grid,
    Button,
    CardActions,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

function FormatoResponsabilidadNoAuth(props) {
    const {
        theme,
        API_DEFAULT,
        setShowBackdrop,
        setMessage,
        setSnack,
        setSeverity,
        UUID,
        pathInicio
    } = props;
    const [formato, setFormato] = useState(null);
    const [aceptaFormato, setAceptaFormato] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();

    const fetchFormatosResponsabilidad = async () => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/formato_responsabilidad_personal/${UUID}/`, {
            headers: { 'Content-Type': 'application/json' }
        });
        if (res.ok) {
            res.json().then(async res => {
                setShowBackdrop(false);
                setFormato(res);
            });
        } else {
            setMessage('Ocurrió un error, intente de nuevo.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
            setShowBackdrop(false);
        }
    }

    const firmarFormato = async () => {

        const res = await fetch(`${API_DEFAULT}/productividad/formato_responsabilidad_personal/${UUID}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'firma': aceptaFormato
            })
        });

        if (res.ok) {
            setMessage('Formatos Firmados correctamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            setShowBackdrop(false);
            redirectToInicio();
        } else {
            setMessage('Ocurrió un error, intente de nuevo.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
            setShowBackdrop(false);
        }
    }

    const validar = () => {

        if (aceptaFormato === false) {
            setError(true);
            setMessage('Debes aceptar la firma del formato.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        } else {
            firmarFormato();
        }
    };

    const redirectToInicio = () => {
        setFormato(null);
        setAceptaFormato(false);
        history.push(pathInicio);
    };

    useEffect(() => {
        if (UUID !== null) {
            fetchFormatosResponsabilidad();
        }
    }, [UUID]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <React.Fragment>
            {formato !== null &&
                <Card>
                    <CardHeader
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                        title={
                            <Typography variant='h6' style={{ fontWeight: 500, textAlign: 'center' }}>Formato de Responsabilidad</Typography>
                        }
                    />
                    <CardContent >
                        <Typography variant='h6' style={{ marginLeft: theme.spacing(4), marginTop: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: 'center' }}>A continuación encontrarás el formato de responsabilidad {!formato.firma ? 'pendiente por firmar:' : ':'}</Typography>
                        <div align="center">
                            {formato.formato !== null ?
                                <iframe
                                    title="Formato"
                                    width="900"
                                    height="600"
                                    src={`${formato.formato}`}>
                                </iframe>
                                :
                                <Typography style={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(4) }}>No hay formatos disponibles para firmar.</Typography>
                            }

                            {!formato.firma && formato.formato !== null &&
                                <Grid container>
                                    <Grid item xs={12} md={12} align='left'>
                                        <Typography style={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(4) }}>Nuestros formatos se firman digitalmente al seleccionar la siguiente casilla. ¿Acepta la firma del formato?</Typography>
                                        <FormControlLabel
                                            style={{ marginLeft: theme.spacing(4) }}
                                            control={<Checkbox color='primary' checked={aceptaFormato} onChange={() => { setAceptaFormato(!aceptaFormato); setError(false); }} />}
                                            label={<Typography style={{ color: error ? '#e53935' : null }} variant='body1'>Firmar formato</Typography>}
                                        />
                                    </Grid>
                                </Grid>
                            }

                        </div>
                    </CardContent>
                    <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
                    <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                        <Button
                            onClick={() => { redirectToInicio() }}
                            variant='contained'
                            color='secondary'
                        >
                            Cancelar
                        </Button>
                        {!formato.firma && formato.formato !== null &&
                            <Button
                                onClick={() => { validar() }}
                                variant='contained'
                                color='primary'
                            >
                                Enviar firmas
                            </Button>
                        }
                    </CardActions>
                </Card>
            }

        </React.Fragment>
    );
}

export default FormatoResponsabilidadNoAuth;