import React, { useState } from 'react';
import {
    Typography,
    Grid,
    Collapse,
    Button,
    Divider,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';


import versiones_android from '../../../../data/versiones_android'

function SupervisorTelefonicoPresencial(props) {
    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        path,
        setShowBackdrop,
        setMessage,
        setSnack,
        setSeverity
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [openSupervisor, setOpenSupervisor] = props.open;
    const [errores, setErrores] = useState(Array(11).fill(false));
    const [helperText, setHelperText] = useState(Array(7).fill('Seleccione una opción'));
    const [manejoPersonal, setManejoPersonal] = useState(null);
    const [tiempo, setTiempo] = useState(null);
    const [personalACargo, setPersonalACargo] = useState(null);
    const [soporte, setSoporte] = useState(null);
    const [marcaCelular, setMarcaCelular] = useState('');
    const [versionAndroid, setVersionAndroid] = useState(null);
    const [memoriaRAM, setMemoriaRAM] = useState(null);
    const [memoriaInterna, setMemoriaInterna] = useState(null);

    const validar = () => {
        let errorDatos = false;
        if (manejoPersonal === null) {
            errorDatos = true
            errores[0] = true
        }
        if (manejoPersonal === '1' && tiempo === null) {
            errorDatos = true
            errores[1] = true
        }
        if (manejoPersonal === '1' && personalACargo === null) {
            errorDatos = true
            errores[2] = true
        }
        if (manejoPersonal === '1' && soporte === null) {
            errorDatos = true
            errores[3] = true
        }
        if (marcaCelular === '') {
            errorDatos = true;
            errores[4] = true;
        }
        if (versionAndroid === null) {
            errorDatos = true;
            errores[5] = true;
        }
        if (memoriaRAM === null) {
            errorDatos = true;
            errores[6] = true;
        }
        if (memoriaInterna === null) {
            errorDatos = true;
            errores[7] = true;
        }
        if (errorDatos) {
            setErrores([...errores]);
            setMessage('Existen campos vacios.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0)
        }
        else {
            putInformacion()
        }
    }

    async function putInformacion(access = accessToken) {
        let res = await fetch(`${API_DEFAULT}/seleccion/crear_formato/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify(
                {
                    'perfil_postulacion': 3,
                    'cuerpo_formato': {
                        'experiencia_manejo_personal': manejoPersonal === '1' ? true : false,
                        'tiempo_experiencia': manejoPersonal === '0' ? 3 : (tiempo === '0' ? 0 : (tiempo === '1' ? 1 : 2)),
                        'personas_a_cargo': manejoPersonal === '0' ? 3 : (personalACargo === '0' ? 0 : (personalACargo === '1' ? 1 : 2)),
                        'soporte_experiencia': manejoPersonal === '0' ? 2 : (soporte === '1' ? 0 : 1),
                        'marca_celular': marcaCelular,
                        'version_android': versionAndroid === 'No tengo Android' ? 20 : versiones_android.filter(v => v.codename === versionAndroid.split(': ')[0] && v.version === versionAndroid.split(': ')[1])[0].cod,
                        'memoria_ram_celular': parseInt(memoriaRAM),
                        'memoria_interna_celular': parseInt(memoriaInterna)
                    }
                })
        })
        if (res.ok) {
            setMessage('Te has postulado correctamente.');
            alert('Te has postulado correctamente.')
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            window.location.href = path;
        }

        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putInformacion(newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }


    const limpiar = () => {
        setManejoPersonal(null);
        setTiempo(null);
        setPersonalACargo(null);
        setSoporte(null);
        setMarcaCelular('');
        setVersionAndroid(null);
        setMemoriaRAM(null);
        setMemoriaInterna(null);

        setErrores(Array(11).fill(false));
        setHelperText(Array(6).fill('Seleccione una opción'));
    }

    return (
        <Dialog
            open={openSupervisor}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                >
                    <Grid item xs={11} md={11} align='center'>
                        <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Supervisor Campo Telefónico Presencial</Typography>
                    </Grid>
                    <Grid item xs={1} md={1}>
                        <IconButton onClick={() => setOpenSupervisor(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <Divider />
            <DialogContent style={{ paddingLeft: 36, paddingRight: 36 }}>
                <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500, marginBottom: theme.spacing(2) }}>A continuación, por favor responda las siguientes preguntas:</Typography>
                <Typography variant='body2' style={{ marginBottom: theme.spacing(3) }}><span style={{ color: '#e53935' }}>*</span> Estos campos son obligatorios.</Typography>
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" error={errores[0]}>
                            <Typography variant='body1'> 1. ¿Tiene experiencia en el manejo de personal en campo presencial (manejo de equipos externos/calle)?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <RadioGroup
                                value={manejoPersonal}
                                onChange={(e) => { setManejoPersonal(e.target.value); setTiempo(null); setPersonalACargo(null); setSoporte(null); errores[0] = false; setErrores([...errores]); helperText[0] = ''; setHelperText([...helperText]) }}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                            <FormHelperText>{helperText[0]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

                <Collapse in={manejoPersonal === '1'} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[1]}>
                                <Typography variant='body1'> 1.1. ¿Cuánto tiempo?:</Typography>
                                <RadioGroup
                                    value={tiempo}
                                    onChange={(e) => { setTiempo(e.target.value); errores[1] = false; setErrores([...errores]); helperText[1] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="0 - 6 meses" />
                                    <FormControlLabel value='1' control={<Radio />} label="6 - 12 meses" />
                                    <FormControlLabel value='2' control={<Radio />} label="Más de 12 meses" />
                                </RadioGroup>
                                <FormHelperText>{helperText[1]}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[2]}>
                                <Typography variant='body1'> 1.2. ¿Cuántas personas ha tenido a cargo?:</Typography>
                                <RadioGroup
                                    value={personalACargo}
                                    onChange={(e) => { setPersonalACargo(e.target.value); errores[2] = false; setErrores([...errores]); helperText[2] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="De 1 a 5" />
                                    <FormControlLabel value='1' control={<Radio />} label="De 6 a 10" />
                                    <FormControlLabel value='2' control={<Radio />} label="De 11 en adelante" />
                                </RadioGroup>
                                <FormHelperText>{helperText[2]}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" error={errores[3]}>
                                <Typography variant='body1'> 1.3. ¿Cuenta con soporte de esta experiencia?:</Typography>
                                <RadioGroup
                                    value={soporte}
                                    onChange={(e) => { setSoporte(e.target.value); errores[3] = false; setErrores([...errores]); helperText[3] = ''; setHelperText([...helperText]) }}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                                <FormHelperText>{helperText[3]}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>
                <Divider style={{ marginBottom: 16 }} />

                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(3), marginTop: theme.spacing(1) }}
                >
                    <Grid item md={6} xs={12}>
                        <Typography variant='body1'> 2. ¿Cuál es la marca y modelo de su celular?: <span style={{ color: '#e53935' }}>* </span></Typography>
                        <TextField
                            value={marcaCelular}
                            onChange={(e) => { setMarcaCelular(e.target.value); errores[4] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[4]}
                            helperText={errores[4] ? 'Este campo no puede quedar vacío' : null}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(3), marginTop: theme.spacing(1) }}
                >
                    <Grid item md={6} xs={12}>
                        <Typography variant='body1'> 3. ¿Cuál es la versión de Android de su celular?: <span style={{ color: '#e53935' }}>* </span></Typography>
                        <Autocomplete
                            value={versionAndroid}
                            onChange={(e, v) => { setVersionAndroid(v); errores[5] = false; setErrores([...errores]) }}
                            options={
                                versiones_android.map(option => option.cod === 20 ? option.codename : option.codename + ': ' + option.version).reverse()
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[5]}
                                    helperText={errores[5] ? 'Este campo no puede quedar vacío' : null}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" error={errores[6]}>
                            <Typography variant='body1'> 4. ¿Con cuánta memoria RAM cuenta su celular?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <RadioGroup
                                value={memoriaRAM}
                                onChange={(e) => { setMemoriaRAM(e.target.value); errores[6] = false; setErrores([...errores]); helperText[4] = ''; setHelperText([...helperText]) }}
                            >
                                <FormControlLabel value='0' control={<Radio />} label="Inferior a 2GB" />
                                <FormControlLabel value='1' control={<Radio />} label="Entre 2GB y 4GB" />
                                <FormControlLabel value='2' control={<Radio />} label="Superior a 4GB" />
                            </RadioGroup>
                            <FormHelperText>{helperText[4]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" error={errores[7]}>
                            <Typography variant='body1'> 5. ¿Con cuánta memoria de almacenamiento cuenta su celular?: <span style={{ color: '#e53935' }}>* </span></Typography>
                            <RadioGroup
                                value={memoriaInterna}
                                onChange={(e) => { setMemoriaInterna(e.target.value); errores[7] = false; setErrores([...errores]); helperText[5] = ''; setHelperText([...helperText]) }}
                            >
                                <FormControlLabel value='0' control={<Radio />} label="Inferior a 8GB" />
                                <FormControlLabel value='1' control={<Radio />} label="Entre 8GB y 16GB" />
                                <FormControlLabel value='2' control={<Radio />} label="Superior a 16GB" />
                            </RadioGroup>
                            <FormHelperText>{helperText[5]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>

            <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
            <DialogActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                <Button

                    onClick={() => {
                        limpiar()
                    }}
                    variant='contained'
                    color='secondary'
                >
                    Limpiar
                </Button>
                <Button

                    onClick={() => {
                        validar()
                    }}
                    variant='contained'
                    color='primary'
                >
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>

    )
};

export default SupervisorTelefonicoPresencial;