import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Snackbar,
  Link,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import useBreakpoints from "./styles/useBreakpoints";

import paths from "./data/paths";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";

// import Logo from './styles/images/logo.png';

import Home from "./components/Home";
import Copyright from "./components/Copyright";
import FormatoResponsabilidadNoAuth from "./components/FormatoResponsabilidadNoauth";
import FormatoRegistro from "./components/Registro/FormatoRegistro";
import FormatoRegistroObraLabor from "./components/Registro Obra/FormatoRegistro";
import GeneralSeleccion from "./components/Seleccion/GeneralSeleccion";
import TemporaryDrawer from "./components/TemporaryDrawer";
import RestorePassword from "./components/Autenticacion/RestorePassword";
import RecoveryPassword from "./components/Autenticacion/RecoveryPassword";

const API_DEFAULT = "https://back-sistema-cnc-service-q2nhgfzuoq-uc.a.run.app";
// const API_DEFAULT = "http://127.0.0.1:8000";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    fontWeight: 500,
  },
  a: {
    height: 37.39,
    [theme.breakpoints.up("sm")]: {
      height: 45.89,
    },
  },
  logo: {
    width: 110,
    [theme.breakpoints.up("sm")]: {
      width: 135,
    },
    marginRight: theme.spacing(4),
  },
}));

function Seleccion(props) {
  const { theme } = props;
  const classes = useStyles();
  const point = useBreakpoints();

  const [auth, setAuth] = useState(localStorage.getItem("auth"));
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access")
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refresh")
  );

  const [username, setUsername] = useState(localStorage.getItem("username"));
  const [userImage, setUserImage] = useState(null);
  const [sessionErrors, setSessionErrors] = useState(Array(2).fill(false));

  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [validRole, setValidRole] = useState(false);
  const [UUID, setUUID] = useState("");
  const sampleLocation = useLocation();

  var pathname = window.location.pathname;

  const validateSession = (username, password) => {
    let errorSesion = false;
    if (username === "") {
      errorSesion = true;
      sessionErrors[0] = true;
    }
    if (password === "") {
      errorSesion = true;
      sessionErrors[1] = true;
    }
    if (errorSesion) {
      setSessionErrors([...sessionErrors]);
      setMessage("Existen campos sin diligenciar o con algún error.");
      setSeverity("error");
      setTimeout(() => {
        setSnack(true);
      }, 0);
    } else {
      logIn(username, password);
    }
  };

  const logIn = async (username, password) => {
    setShowBackdrop(true);
    const res = await fetch(`${API_DEFAULT}/usuarios/auth/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });
    res.json().then((d) => {
      if (d["access"]) {
        getRole(d["access"], d["refresh"]);
      } else {
        setShowBackdrop(false);
        setMessage("Los datos de usuario y contraseña son incorrectos.");
        setSeverity("error");
        setTimeout(() => {
          setSnack(true);
        }, 0);
      }
    });
  };

  const getRole = async (access = accessToken, refresh = refreshToken) => {
    setShowBackdrop(true);
    const res = await fetch(`${API_DEFAULT}/usuarios/dar_rol/`, {
      headers: { Authorization: `Bearer ${access}` },
    });

    res.json().then(async (res) => {
      if (res["code"] === "token_not_valid") {
        let newAccess = await getAccessTokenWithRefresh();
        if (newAccess) {
          getRole(newAccess, refresh);
        }
      } else if (res["roles"] === undefined) {
        signOff();
      } else if (res["roles"].includes("Persona seleccion") || res["roles"].includes("Persona seleccion - Laboral")) {
        localStorage.setItem("username", res["username"]);
        localStorage.setItem("access", access);
        localStorage.setItem("refresh", refresh);
        localStorage.setItem("auth", true);
        localStorage.setItem("roles", res["roles"]);
        setUsername(res["username"]);
        setUserImage(res["imagen"] === null ? "" : res["imagen"]);
        setAccessToken(access);
        setRefreshToken(refresh);
        setAuth(true);
        setValidRole(true);
        setShowBackdrop(false);
      } else {
        signOff();
        setShowBackdrop(false);
        setMessage("El usuario no está habilitado para usar esta aplicación.");
        setSeverity("warning");
        setTimeout(() => {
          setSnack(true);
        }, 0);
      }
    });
  };

  const getAccessTokenWithRefresh = async () => {
    let access = undefined;
    const res = await fetch(`${API_DEFAULT}/usuarios/refresh_token/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        refresh: refreshToken,
      }),
    });

    if (!res.ok) {
      signOff();
      setShowBackdrop(false);
      setMessage("La sesión expiró, por favor ingrese nuevamente.");
      setSeverity("info");
      setTimeout(() => {
        setSnack(true);
      }, 0);
    }

    await res.json().then((res) => {
      if (res["code"] === "token_not_valid") {
        signOff();
        setShowBackdrop(false);
        setMessage("La sesión expiró, por favor ingrese nuevamente.");
        setSeverity("info");
        setTimeout(() => {
          setSnack(true);
        }, 0);
      } else {
        access = res["access"];
      }
    });

    return access;
  };

  const signOff = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("username");
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    setAuth(false);
    setUsername("");
    setAccessToken("");
    setRefreshToken("");
    setValidRole(false);
  };

  useEffect(() => {
    let search = sampleLocation.search;
    if (search !== "") {
      setUUID(search.slice(1));
    }
  }, [sampleLocation]);

  useEffect(() => {
    if (auth) {
      getRole(accessToken, refreshToken);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="Selección">
      <header className="Selección-header">
        <AppBar position="static">
          <Toolbar>
            <a
              className={classes.a}
              href={"https://www.centronacionaldeconsultoria.com/"}
            >
              {/* <img src={Logo} alt='logo' className={classes.logo} /> */}
            </a>

            <Typography
              variant="h5"
              className={classes.title}
              style={{ marginRight: theme.spacing(2) }}
            >
              <Link
                style={{ color: "black", textDecoration: "none" }}
                href="inicio"
              >
                Aplicativo Selección
              </Link>
            </Typography>
            {!auth ? null : (
              <React.Fragment>
                <Typography
                  style={{
                    display: point === "sm" || point === "xs" ? "none" : null,
                    fontWeight: 500,
                    marginRight: theme.spacing(2),
                  }}
                  variant="body1"
                >
                  {username}
                </Typography>
                <IconButton
                  style={{ marginRight: theme.spacing(2) }}
                  color="inherit"
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    signOff();
                  }}
                >
                  <ExitToAppIcon />
                </IconButton>
              </React.Fragment>
            )}
          </Toolbar>
        </AppBar>
      </header>
      <div className="Selección-body" style={{ marginTop: theme.spacing(2) }}>
        <Redirect
          to={{
            pathname: auth
              ? paths
                  .slice(2)
                  .map((a) => a.path)
                  .includes(pathname)
                ? pathname
                : paths[2].path
              : paths
                  .slice(0, 2)
                  .concat(paths.slice(-2))
                  .map((a) => a.path)
                  .includes(pathname)
              ? pathname
              : paths[0].path,
            state: { from: props.location },
          }}
        />
        {auth ? (
          <TemporaryDrawer
            theme={theme}
            classes={classes}
            open={[openDrawer, setOpenDrawer]}
            username={username}
            paths={paths.slice(2)}
          />
        ) : null}
        <Switch>
          <Route
            exact
            path={paths[0].path}
            render={() => {
              if (!auth) {
                return (
                  <Container component="main" maxWidth="md">
                    <Home
                      theme={theme}
                      path={paths[1]}
                      setMessage={setMessage}
                      setSeverity={setSeverity}
                      setSnack={setSnack}
                      validateSession={validateSession}
                      errors={[sessionErrors, setSessionErrors]}
                    />
                  </Container>
                );
              }
            }}
          />
          <Route
            exact
            path={paths[1].path}
            render={() => {
              if (!auth) {
                return (
                  <Container component="main" maxWidth="lg">
                    <FormatoRegistro
                      theme={theme}
                      path={paths[0]}
                      setMessage={setMessage}
                      setSeverity={setSeverity}
                      setSnack={setSnack}
                      API_DEFAULT={API_DEFAULT}
                    />
                  </Container>
                );
              }
            }}
          />
          <Route
            exact
            path={paths[14].path}
            render={() => {
              if (!auth) {
                return (
                  <Container component="main" maxWidth="lg">
                    <FormatoRegistroObraLabor
                      theme={theme}
                      path={paths[0]}
                      setMessage={setMessage}
                      setSeverity={setSeverity}
                      setSnack={setSnack}
                      API_DEFAULT={API_DEFAULT}
                    />
                  </Container>
                );
              }
            }}
          />
          <Route
            exact
            path={paths[9].path}
            render={() => {
              if (!auth) {
                return (
                  <Container component="main" maxWidth="sm">
                    <RecoveryPassword
                      theme={theme}
                      path={paths[9]}
                      paths={paths}
                      setShowBackdrop={setShowBackdrop}
                      setMessage={setMessage}
                      setSeverity={setSeverity}
                      setSnack={setSnack}
                      API_DEFAULT={API_DEFAULT}
                    />
                  </Container>
                );
              }
            }}
          />
          <Route
            path={paths[10].path}
            render={() => {
              if (!auth) {
                return (
                  <Container component="main" maxWidth="sm">
                    <RestorePassword
                      theme={theme}
                      path={paths[10]}
                      paths={paths}
                      setShowBackdrop={setShowBackdrop}
                      logIn={logIn}
                      setMessage={setMessage}
                      setSeverity={setSeverity}
                      setSnack={setSnack}
                      API_DEFAULT={API_DEFAULT}
                    />
                  </Container>
                );
              }
            }}
          />
          <Route
            path={paths[13].path}
            render={() => {
              if (!auth && UUID !== "") {
                return (
                  <FormatoResponsabilidadNoAuth
                    theme={theme}
                    API_DEFAULT={API_DEFAULT}
                    setMessage={setMessage}
                    setSeverity={setSeverity}
                    setSnack={setSnack}
                    setShowBackdrop={setShowBackdrop}
                    pathInicio={paths[0].path}
                    UUID={UUID}
                  />
                );
              }
            }}
          />
          <Route
            path={paths[2].path}
            render={() => {
              if (auth) {
                return (
                  <GeneralSeleccion
                    theme={theme}
                    API_DEFAULT={API_DEFAULT}
                    validRole={validRole}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    paths={paths.slice(2)}
                    setMessage={setMessage}
                    setSeverity={setSeverity}
                    setSnack={setSnack}
                    setShowBackdrop={setShowBackdrop}
                    username={[username, setUsername]}
                    userImage={[userImage, setUserImage]}
                    getRole={getRole}
                  />
                );
              } else {
                return <Redirect to={paths[0].path} />;
              }
            }}
          />
        </Switch>
      </div>
      <div className="Notifications">
        <Backdrop
          style={{ zIndex: 1301 }}
          className={classes.backdrop}
          open={showBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={snack}
          autoHideDuration={6000}
          onClose={() => setSnack(false)}
        >
          <Alert
            onClose={() => setSnack(false)}
            severity={severity}
            variant="filled"
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
      <div className="Copyrighyt">
        <Copyright />
      </div>
    </div>
  );
}

export default Seleccion;
