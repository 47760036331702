import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
} from '@material-ui/core';
import { Route } from "react-router-dom";
import CaracteristicasContrato from './CaracteristicasContrato';
import Bioseguridad from '../../../data/Bioseguridad.pdf'
import estadosContratacion from '../../../data/estadosContratacion';


function ContratoAceptado(props) {
    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        setShowBackdrop,
        paths,
        cedula,
        nombre,
        ciudadResidencia,
        estadoContratacion
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [archivoContrato, setArchivoContrato] = useState('');
    const [archivoComodato, setArchivoComodato] = useState('');


    async function fetchContrato(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/contratos/persona_seleccion=${cedula}&perfil=${estadosContratacion.filter(e => e.estado === estadoContratacion)[0].cargo_contratado}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchContrato(newAccess);
                }
            }
            else {
                setShowBackdrop(false);
                setArchivoContrato(res.contrato);
                setArchivoComodato(res.comodato);
            }
        });
    }

    useEffect(() => { if (estadoContratacion.includes('Contratado')) { fetchContrato() } }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Route
            exact
            path={paths[4].path}
            render={() => (
                <Card>
                    <CardHeader
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                        title={
                            <Typography variant='h6' style={{ fontWeight: 500, textAlign: 'center' }}>Contratación </Typography>
                        }
                    />

                    <CardContent >
                        <CaracteristicasContrato
                            theme={theme}
                            nombre={nombre}
                            ciudadResidencia={ciudadResidencia}
                        />
                        <Typography variant='h6' style={{ marginLeft: theme.spacing(4), marginTop: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: 'center' }}>Tu contrato:</Typography>
                        <div align="center">
                            <iframe
                                title="Contrato"
                                width="700"
                                height="600"
                                src={`${archivoContrato}`}>
                            </iframe>
                        </div>

                        <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />

                        <Typography variant='h6' style={{ marginLeft: theme.spacing(4), marginTop: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: 'center' }}> El comodato:</Typography>
                        <div align="center">
                            <iframe
                                title="Contrato"
                                width="700"
                                height="600"
                                src={`${archivoComodato}`}>
                            </iframe>
                        </div>
                    </CardContent>

                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Typography variant='h6' style={{ marginLeft: theme.spacing(4), marginTop: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: 'center' }}>Finalmente, ten presente nuestros protocolos de bioseguridad:</Typography>
                    <div align="center">
                        <iframe
                            title="Contrato"
                            width="700"
                            height="600"
                            src={`${Bioseguridad}`}>
                        </iframe>
                    </div>

                </Card>

            )}
        />

    );

}
export default ContratoAceptado;