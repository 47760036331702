const fondospension = [
    {pension: "COLFONDOS", cod: 0},
    // {pension: "COLMENA", cod: 1},
    // {pension: "COLPATRIA", cod: 2},
    {pension: "COLPENSIONES", cod: 3},
    // {pension: "HORIZONTES", cod: 4},
    // {pension: "ING FONDO DE PENSIONES", cod: 5},
    // {pension: "INVERTIR", cod: 6},
    // {pension: "ISS", cod: 7},
    // {pension: "NINGUNA ", cod: 8},
    // {pension: "OLD MUTUAL - SKANDIA", cod: 9},
    // {pension: "PENSIONAR", cod: 10},
    {pension: "PORVENIR", cod: 11},
    {pension: "PROTECCION", cod: 12},
    // {pension: "SSS CAPRECOM", cod: 13},
    // {pension: "SSS FONDO DE PREVISION SOCIAL DEL CONGRESO", cod: 14},
    {pension: "SSS PENSIONES DE ANTIOQUIA", cod: 15},
    // {pension: "FIDUPREVISORA", cod: 16},
    {pension: "CAXDAC", cod: 17},
    {pension: "FONPRECON", cod: 18},
    // {pension: "PENSIONES DE ANTIOQUIA", cod: 19},
    {pension: "SKANDIA", cod: 20},
    // {pension: "SKANDIA ALTERNATIVA", cod: 21},
];

export default fondospension;
